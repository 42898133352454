/* eslint-disable react/jsx-no-duplicate-props */
import React, { useRef } from "react";
import {
  Typography,
  Paper,
  Box,
  Divider,
  MenuItem,
  InputAdornment,
  IconButton,
  Popover,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Search as SearchIcon, Clear as ClearIcon } from "@material-ui/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import { makeStyles } from "@material-ui/styles";
import InputTextField from "src/components/Input/InputTextField";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 425,
  },
  list: {
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "16px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E4E7EC",
      borderRadius: "16px",
    },
  },
}));

export default function CompanySelectPanelComponent({
  value,
  anchorRef,
  open,
  handleClose,
  isFetching,
  companies,
  handleSelectCompany,
  handleSearch,
  handleFetchMoreItems,
  hasNextPage,
  isError,
  totalRecords,
  handleReload,
  handleClearClick,
}) {
  const classes = useStyle();
  const textRef = useRef();

  return (
    <Popover
      open={open}
      style={{ zIndex: 99 }}
      onClose={handleClose}
      anchorEl={anchorRef.current}
      role={undefined}
      disablePortal
      placement="bottom-start"
    >
      <Paper className={classes.paper}>
        <Box>
          <InputTextField
            variant="filled"
            size="small"
            fullWidth
            inputRef={textRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ paddingRight: 6 }}>
                  <IconButton
                    onClick={() => {
                      textRef.current.value = "";
                      handleSearch("");
                    }}
                    size="small"
                  >
                    <ClearIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleSearch(textRef.current.value)}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                backgroundColor: "#fff",
                paddingRight: 0,
              },
            }}
            inputProps={{
              onKeyDown: (e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleSearch(textRef.current.value);
                }
              },
              style: {
                padding: "8px",
              },
            }}
            placeholder="Search"
          />
          <Divider />
          <Box>
            {isError ? (
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                pt={2}
                pb={3}
              >
                <Typography
                  variant="body2"
                  color="primary"
                  style={{ fontWeight: "bold", paddingBottom: "8px" }}
                >
                  No data received
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: "#9CA6A9", paddingBottom: "16px" }}
                >
                  Something went wrong.
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleReload}
                  disableElevation
                >
                  Refresh
                </Button>
              </Box>
            ) : (
              <Box overflow="auto">
                <InfiniteScroll
                  height="25vh"
                  className={classes.list}
                  dataLength={totalRecords}
                  next={() => {
                    if (hasNextPage) handleFetchMoreItems();
                  }}
                  hasMore={companies.length < totalRecords}
                  loader={
                    hasNextPage ? (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        height={100}
                      >
                        <CircularProgress />
                      </Box>
                    ) : null
                  }
                  endMessage={
                    isFetching ? (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        height={100}
                      >
                        <CircularProgress />
                      </Box>
                    ) : !companies.length ? (
                      <Box p={2} style={{ textAlign: "center" }}>
                        <Typography variant="body1">No record exist</Typography>
                      </Box>
                    ) : null
                  }
                >
                  {[...companies].map((company, index) => (
                    <MenuItem
                      key={index}
                      style={{
                        padding: 8,
                        backgroundColor:
                          value && value.id === company.id
                            ? "#ececec"
                            : "#ffffff",
                      }}
                      onClick={() => handleSelectCompany(company)}
                    >
                      <Typography display="inline" variant="body2">
                        {company.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </InfiniteScroll>
              </Box>
            )}
          </Box>
        </Box>
      </Paper>
    </Popover>
  );
}
