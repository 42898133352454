import React from "react";
import { Typography, ButtonBase, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import clsx from "clsx";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "4px",
    width: "100%",
    backgroundColor: "#FFFFFF",
    border: "2px solid #D0D5DD",
  },
  disabled: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "4px",
    width: "100%",
    color: "F3F3F3",
    backgroundColor: "#F3F3F3",
    border: "2px solid #D0D5DD",
  },
  inputText: {
    fontSize: "1rem",
    color: "#A4A6A8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  filled: {
    color: "rgba(0, 0, 0, 0.87)",
  },
}));

export default function ProductSelectComponent({
  open,
  handleClick,
  anchorRef,
  isBranchSelected,
  selectedProduct,
  placeholder,
}) {
  const classes = useStyle();

  const getdisplay = () => {
    let display = placeholder;
    if (selectedProduct.name) {
      display = selectedProduct.name;
    }
    return display;
  };

  return (
    <ButtonBase
      disableRipple
      ref={anchorRef}
      onClick={handleClick}
      disabled={!isBranchSelected}
      className={clsx(
        isBranchSelected ?
        classes.root :
        classes.disabled
      )}
    >
      <Box display="flex" style={{ maxWidth: "85%" }}>
        <Typography
          variant="body2"
          className={clsx(classes.inputText, classes.filled)}
        >
          {getdisplay()}
        </Typography>
      </Box>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {isBranchSelected &&
          (open ? (
            <ArrowDropUpIcon fontSize="small" />
          ) : (
            <ArrowDropDownIcon fontSize="small" />
          ))}
      </Box>
    </ButtonBase>
  );
}
