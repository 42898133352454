/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Box,
  Paper, Grid, TableCell,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/CloseRounded";
import Loading from "../../../../../components/Loading";
import DataTable from "../../../../../components/DataTable";

const CreateDisbursementInfoDialogComponent = ({
  handleClose,
  isFetching,
  data,
}) => {
  return (
    <Dialog
      open
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        handleClose();
      }}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="scroll-dialog-title">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body2">
            <b>Report Details</b>
          </Typography>
          <IconButton
            size="small"
            color="inherit"
            onClick={handleClose}
            style={{ padding: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers
        style={{ backgroundColor: "#f3f3f3", padding: 16 }}
      >
        <Paper>
          {isFetching ? (
            <Loading />
          ) : (
            <Box p={2}>
              <Box>
                <Grid container>
                  <Grid item md={3} style={{ width: "100%" }}>
                    <Typography variant="body1" color="textSecondary">
                      Filename
                    </Typography>
                  </Grid>
                  <Grid item md={9} style={{ width: "100%" }}>
                    <Typography variant="body1">
                      {data?.filename}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={1}>
                <Grid container>
                  <Grid item md={3} style={{ width: "100%" }}>
                    <Typography variant="body1" color="textSecondary">
                      Date
                    </Typography>
                  </Grid>
                  <Grid item md={9} style={{ width: "100%" }}>
                    <Typography variant="body1">
                      {data?.date}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={1}>
                <Grid container>
                  <Grid item md={3} style={{ width: "100%" }}>
                    <Typography variant="body1" color="textSecondary">
                      Report Status
                    </Typography>
                  </Grid>
                  <Grid item md={9} style={{ width: "100%" }}>
                    <Typography variant="body1">
                      {data?.status}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={1}>
                <Grid container>
                  <Grid item md={3} style={{ width: "100%" }}>
                    <Typography variant="body1" color="textSecondary">
                      Status
                    </Typography>
                  </Grid>
                  <Grid item md={9} style={{ width: "100%" }}>
                    <Typography variant="body1">
                      {data?.transactionValidStatus}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={1}>
                <DataTable
                  isLoading={isFetching}
                  rows={data?.transactions}
                  rowsPerPage={data?.transactions?.length}
                  total={data?.transactions?.length}
                  page={0}
                  renderHeader={() => (
                    <>
                      <TableCell>No.</TableCell>
                      <TableCell>Disbursement Id</TableCell>
                      <TableCell>Transferred Timestamp</TableCell>
                      <TableCell>Customer Mobile No.</TableCell>
                      <TableCell>Total Amount</TableCell>
                      <TableCell>Status</TableCell>
                    </>
                  )}
                  renderRow={(row, index) => {
                    return (
                      <>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{JSON.parse(row.body_info).disbursementId}</TableCell>
                        <TableCell>{row.completed_timestamp}</TableCell>
                        <TableCell>{row.transaction_destination}</TableCell>
                        <TableCell>
                          {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'MYR',
                          }).format(parseInt(row.amount, 10) / 100)}
                        </TableCell>
                        <TableCell>
                          <Box
                            color={{
                              1: "green",
                              2: "red",
                            }[row.is_valid] ?? "orange"}
                          >
                            {row.is_valid === "1" ? 'Pass' : 'Failed'}
                          </Box>
                        </TableCell>
                      </>
                    );
                  }}
                />
              </Box>
            </Box>
          )}
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default CreateDisbursementInfoDialogComponent;
