import React from "react";
import {
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Box,
  TextField,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Formik } from "formik";
import BranchSelect from "../../Select/branchSelect";

const useStyles = makeStyles((theme) => ({
  accessBox: {
    display: "flex",
    flexFlow: "column",
  },
  scrollContainer: {
    maxHeight: "70vh",
    overflowY: "auto",
    backgroundColor: "#f3f3f3",
  },
  root: {
    backgroundColor: "#ffffff",
  },
}));

export default function UnassignPanelComponent({
  isUpdating,
  selectedCompany,
  onSerialNumberAssignDialogCloseClick,
  handleBranchChange,
  selectedBranch,
  inputValue,
  handleCodeEnter,
  handleSubmit,
}) {
  const classes = useStyles();

  if (!selectedCompany) {
    return null;
  }
  return (
    <Formik>
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers className={classes.scrollContainer}>
            <Box className={classes.accessBox}>
                <Grid container spacing={3}>
                  <Grid item md={3} style={{ width: "100%" }}>
                    <Typography variant="body2" color="textSecondary">
                      Branch
                    </Typography>
                  </Grid>
                  <Grid item md={9}>
                    <BranchSelect
                      placeholder="Select Branch"
                      selectedCompany={selectedCompany}
                      handleBranchChange={handleBranchChange}
                      selectedBranch={selectedBranch}
                    />
                  </Grid>
                  <Grid item md={3} style={{ width: "100%" }}>
                    <Typography variant="body2" color="textSecondary">
                      Qr Code
                    </Typography>
                  </Grid>
                  <Grid item md={9}>
                    <TextField
                      value={inputValue}
                      onChange={handleCodeEnter}
                      style={{ width: "100%" }}
                      placeholder="Enter a value and press Enter"
                      className={classes.root}
                      multiline
                      required
                    />
                  </Grid>
                </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              type="button"
              disabled={isUpdating}
              onClick={onSerialNumberAssignDialogCloseClick}
              style={{ color: "black" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={isUpdating}
              onClick={handleSubmit}
            >
              Update
            </Button>
          </DialogActions>
        </form>
      )}
    </Formik>
  );
}
