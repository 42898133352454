import { createAsyncThunk } from "@reduxjs/toolkit";
import masterControlApi from '../../../configs/apis/master-control';

export const fetchMasterControl = createAsyncThunk(
    'masterControl/fetchMasterControl',
    async (_, { rejectWithValue }) => masterControlApi
        .fetchControlStatus()
        .then((response) => response.data)
        .catch((error) => rejectWithValue(error)),
);

export const updateControlStatus = createAsyncThunk(
    'masterControl/UpdateMasterControl',
    async (controls, { rejectWithValue }) => masterControlApi
        .updateControlStatus({ controls })
        .then((response) => response.data)
        .catch((error) => rejectWithValue(error)),
);
