import axios from "axios";

export default function getDisbursementList({ page, search, length }) {
  const start = page * length;
  const promise = new Promise((resolve, reject) =>
    axios
      .get(`/campaignV2/adminDisbursement/companyList`, {
        params: {
          start,
          length,
          search,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.message))
  );

  return promise;
}
