import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyUsers,
  postCreditTransfer,
  clearErrors,
} from "src/modules/companies/redux";
import CompanyUsersComponent from "./company-users.component";

export default function CompanyUsersDialogContainer({
  onCompanyUsersViewClick,
  selectedCompanyForUsers,
  onCompanyUsersCloseClick,
}) {
  const dispatch = useDispatch();

  const companyUsers = useSelector((state) => state.companyUsers.users);
  const totalUsers = useSelector((state) => state.companyUsers.totalUsers);
  const currentUsersPage = useSelector((state) => state.companyUsers.usersPage);
  const isCompanyUsersLoading = useSelector(
    (state) => state.companyUsers.isLoading
  );
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPageOption = [10, 25, 50];

  const [query, setQuery] = useState({
    searchColumn: { name: 'Username', id: 'username' },
    searchValue: "",
    status: { name: 'All Status', id: '' },
  });

  const completeDialogErrors = useSelector(
    (state) => state.companyUsers.errors
  );
  const [selectedCompanyUser, setSelectedCompanyUser] = useState(null);
  const [openCreditTransferConfirmation, setOpenCreditTransferConfirmation] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openCompleteDialog, setOpenCompleteDialog] = useState(false);

  useEffect(() => {
    setQuery({
      searchColumn: { name: 'Username', id: 'username' },
      searchValue: "",
      status: { name: 'All Status', id: '' },
    });
  }, [rowsPerPage]);

  useEffect(() => {
    dispatch(
      getCompanyUsers({
        companyId: selectedCompanyForUsers?.companyId,
        companyPlan: selectedCompanyForUsers?.companyPlan,
        page: 0,
        length: rowsPerPage,
        filter: query,
      })
    );
  }, [query]);

  const onCompanyUsersPageClick = async (page) => {
    if (currentUsersPage !== page) {
      dispatch(
        getCompanyUsers({
          companyId: selectedCompanyForUsers.companyId,
          companyPlan: selectedCompanyForUsers.companyPlan,
          page,
          length: rowsPerPage,
          filter: query
        })
      );
    }
  };

  const onClearFilter = async () => {
    setQuery({
      searchColumn: { name: 'Username', id: 'username' },
      searchValue: "",
      status: { name: 'All Status', id: '' },
    });
  };

  const handleItemPerPageChange = (value) => {
    setRowsPerPage(value);
  };

  const onSearchSubmit = (newFilter) => {
    setQuery(newFilter);
  };

  //  👇  OPEN DIALOG FUNCTION  👇  //
  const onCreditTransferClick = (user) => {
    setSelectedCompanyUser(user);
  };

  const onCreditTransferConfirmationClick = () => {
    setOpenCreditTransferConfirmation(true);
  };

  const onCompleteDialogOpen = () => {
    setOpenCompleteDialog(true);
  };

  //  👇  CLOSE DIALOG FUNCTION 👇  //
  const onCreditTransferCloseClick = () => {
    setSelectedCompanyUser(null);
  };

  const onCreditTransferConfirmationCloseClick = () => {
    setOpenCreditTransferConfirmation(false);
  };

  const onCompleteDialogClose = () => {
    setOpenCompleteDialog(false);
    onCompanyUsersViewClick({
      companyId: selectedCompanyForUsers.companyId,
      companyPlan: selectedCompanyForUsers.companyPlan,
      page: currentUsersPage,
      length: rowsPerPage,
    });
    setTimeout(() => {
      dispatch(clearErrors());
    }, 200);
  };

  //  👇  CREDIT TRANSFER CONFIRMATION SUBMIT FUNCTION  👇  //
  const onCreditTransferConfirmSubmitClick = async (values, formik) => {
    setIsSubmitting(true);

    await dispatch(
      postCreditTransfer({
        company: selectedCompanyForUsers,
        user: selectedCompanyUser,
        transaction: values,
      })
    );

    setIsSubmitting(false);
    formik.resetForm();
    onCreditTransferConfirmationCloseClick();
    onCreditTransferCloseClick();
    onCompleteDialogOpen();
  };

  return (
    <CompanyUsersComponent
      //  👇  Company Users Dialog  👇  //
      selectedCompany={selectedCompanyForUsers}
      companyUsers={companyUsers}
      totalUsers={totalUsers}
      currentUsersPage={currentUsersPage}
      onCompanyUsersPageClick={onCompanyUsersPageClick}
      isCompanyUsersLoading={isCompanyUsersLoading}
      onCompanyUsersCloseClick={onCompanyUsersCloseClick}
      rowsPerPage={rowsPerPage}
      rowsPerPageOption={rowsPerPageOption}
      handleItemPerPageChange={handleItemPerPageChange}
      query={query}
      onSearchSubmit={onSearchSubmit}
      onClearFilter={onClearFilter}
      //  👇  Credit Transfer Dialog  👇  //
      selectedCompanyUser={selectedCompanyUser}
      onCreditTransferClick={onCreditTransferClick}
      onCreditTransferCloseClick={onCreditTransferCloseClick}
      //  👇  Credit Transfer Confirmation Dialog  👇  //
      openCreditTransferConfirmation={openCreditTransferConfirmation}
      onCreditTransferConfirmationClick={onCreditTransferConfirmationClick}
      onCreditTransferConfirmSubmitClick={onCreditTransferConfirmSubmitClick}
      isSubmitting={isSubmitting}
      onCreditTransferConfirmationCloseClick={
        onCreditTransferConfirmationCloseClick
      }
      //  👇  Credit Transfer Complete Dialog  👇  //
      completeDialogErrors={completeDialogErrors}
      openCompleteDialog={openCompleteDialog}
      onCompleteDialogClose={onCompleteDialogClose}
    />
  );
}
