import axios from "axios";

export default function updateCompanyPlan(parameters) {
  return new Promise((resolve, reject) =>
    axios
      .post(`/account/system-admin/api/v1/company/${parameters.id}/swap-plan`, {
        plan_uuid: parameters.plan,
        need_access_right_update: parameters.need_access_right_update,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.message))
  );
}
