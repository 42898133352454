import React from 'react';
import { useSelector } from 'react-redux';
import GraphQRComponent from './graph-qr.component';

export default function GraphQRContainer() {
  const graphData = useSelector((state) => state.companyDashboard.graphQR.data);
  const branchList = useSelector((state) => state.companyDashboard.branchList.data);
  const isLoading = useSelector((state) => state.companyDashboard.graphQR.isLoading);
  const error = useSelector((state) => state.companyDashboard.graphQR.error);

  return (
    <GraphQRComponent
      branchList={branchList}
      data={graphData}
      isLoading={isLoading}
      error={error}
    />
  );
}
