/* eslint-disable consistent-return */
/* eslint-disable no-else-return */

import React, { useState } from 'react';
import SearchableSelectComponent from './searchableSelect.component';
import SearchableSelectPanelComponent from './searchableSelectPanel.component';

export default function SearchableSelectContainer({
  searchable,
  handleChange,
  value,
  disabled,
  style,
  handleItemSearch,
  isFetching,
  placeholder,
  panelStyle,
  panelBoxStyle,
  dropdownItem,
  emptyMessage,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  textStyle,
  returnType,
  isEmpty,
  type,
  handleFetchMoreItems,
  totalFilter,
  isClearable,
  handleClear,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedField, setSelectedField] = useState(value);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (handleItemSearch) {
      handleItemSearch('');
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleListKeyDown = (field) => {
    if (returnType === 'object' && selectedField !== null && selectedField?.id === field?.uuid) {
      handleChange(field);
    } else if (returnType === 'object' || field?.name === undefined) {
      handleChange(field);
    } else if (field?.alpha3) {
      handleChange(field);
    } else if (field?.name) {
      handleChange(field.name);
    } else {
      handleChange(field);
    }
    handleClose();
  };

  const selectedItem = (item) => {
    if (isEmpty || value === undefined) {
      return false;
    }

    if (item.uuid !== undefined || value?.id !== undefined) {
      if (item.uuid === value?.id) {
        setSelectedField(value);
        return true;
      } else {
        return false;
      }
    }

    if (returnType === 'currencyCode' || item.alpha3 !== 'undefined') {
      if (item.alpha3 === value) {
        setSelectedField(value);
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <>
      <SearchableSelectComponent
        handleClick={handleClick}
        handleClear={handleClear}
        value={value}
        isEmpty={isEmpty}
        style={style}
        disabled={disabled}
        placeholder={placeholder}
        id={id}
        open={open}
        textStyle={textStyle}
        isClearable={isClearable}
      />
      <SearchableSelectPanelComponent
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        style={panelStyle}
        popoverBoxStyle={panelBoxStyle}
        id={id}
        items={dropdownItem}
        handleSearch={handleItemSearch}
        isFetching={isFetching}
        searchable={searchable}
        emptyMessage={emptyMessage}
        handleListKeyDown={handleListKeyDown}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={PaperProps}
        type={type}
        handleFetchMoreItems={handleFetchMoreItems}
        totalFilter={totalFilter}
        selected={selectedItem}
      />
    </>
  );
}
