import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../../../../components/Loading';
import { parseHash } from '../../../../configs/redux/session/session.action';

export default function Auth() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isHashParsed = useSelector((state) => state.session.isHashParsed);
  const isAccessGranted = useSelector((state) => state.session.isAccessGranted);
  useEffect(() => {
    const { hash } = location;
    if (hash) {
      dispatch(parseHash(hash));
    } else {
      navigate('/login');
    }
  }, [dispatch, location]);
  useEffect(() => {
    if (isHashParsed) {
      if (!isAccessGranted) {
        navigate('/login');
      } else {
        navigate('/admin');
      }
    }
  }, [navigate, isHashParsed, isAccessGranted]);
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loading />
    </div>
  );
}
