import {
  createAsyncThunk,
} from '@reduxjs/toolkit';
import emailLogApi from '../../../configs/apis/email-log';

export const getEmailLog = createAsyncThunk(
  'emailLog/getEmailLog',
  async (payload, { rejectWithValue }) => emailLogApi
    .getEmailLog({
      page: payload.page,
      search: payload.searchValue,
      types: payload.filterType,
      status: payload.filterStatus,
      startDate: (payload.filterDateRange.length === 0) ? '' : payload.filterDateRange[0].toISOString(),
      endDate: (payload.filterDateRange.length === 0) ? '' : payload.filterDateRange[1].toISOString(),
      length: payload.itemPerPage,
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error)),
);

export const setSelectedAll = createAsyncThunk(
  'emailLog/setSelectedAll',
  async (payload, { rejectWithValue }) => emailLogApi
    .getEmailLog({
      page: 0,
      search: payload.searchValue,
      types: payload.filterType,
      status: payload.filterStatus,
      startDate: (payload.filterDateRange.length === 0) ? '' : payload.filterDateRange[0].toISOString(),
      endDate: (payload.filterDateRange.length === 0) ? '' : payload.filterDateRange[1].toISOString(),
      length: -1,
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error)),
);

export const setSelectedAllFailed = createAsyncThunk(
  'emailLog/setSelectedAllFailed',
  async (payload, { rejectWithValue }) => emailLogApi
    .getEmailLog({
      page: 0,
      search: payload.searchValue,
      types: payload.filterType,
      status: payload.filterStatus,
      startDate: (payload.filterDateRange.length === 0) ? '' : payload.filterDateRange[0].toISOString(),
      endDate: (payload.filterDateRange.length === 0) ? '' : payload.filterDateRange[1].toISOString(),
      length: -1,
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error)),
);

export const resendEmail = createAsyncThunk(
  'emailLog/resendEmail',
  async (payload, { rejectWithValue }) => emailLogApi
    .resendEmail({ id: payload.id })
    .then((_) => ({ page: payload.page, id: payload.id }))
    .catch((error) => rejectWithValue(error)),
);

export const getEmailTypeFilter = createAsyncThunk(
  'emailLog/getEmailTypeFilter', async (_, { rejectWithValue }) => emailLogApi
  .getEmailTypeFilter()
  .then((response) => response.data)
  .catch((error) => rejectWithValue(error)),
);
