import React from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
} from "@material-ui/core";
import {
  makeStyles,
} from "@material-ui/styles";
import clsx from "clsx";

const useStyle = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    padding: "35px 30px",
  },
  customDialogActionRoot: {
    justifyContent: "center",
  },
  customDialogPaperWithXs: {
    maxWidth: 360,
  },
}));

export default function ConfirmationDialogComponent({
  isOpen,
  handleClose,
  handleProceed,
  title,
}) {
  const classes = useStyle();
  return (
    <Dialog
      classes={{
        paper: clsx(classes.root),
        paperWidthXs: classes.customDialogPaperWithXs,
      }}
      open={isOpen}
      maxWidth="xs"
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogActions classes={{ root: classes.customDialogActionRoot }}>
        <Button onClick={handleProceed} color="primary" variant="contained">
          Confirm
        </Button>
        <Button onClick={handleClose} color="primary" variant="contained">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
