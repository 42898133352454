import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers';
import rootMiddleware from '../middlewares';

const store = configureStore({
  reducer: rootReducer,
  middleware: rootMiddleware,
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
