import axios from "axios";

export default function getCompanyDropdown({ start, length, search }) {
  const promise = new Promise((resolve, reject) =>
    axios
      .get(`/account/system-admin/api/v1/companies/dropdown`, {
        params: {
          start,
          length,
          search,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.message))
  );

  return promise;
}
