import { createAsyncThunk } from "@reduxjs/toolkit";
import engagementApi from "src/configs/apis/consumer-engagement";

export const getTransactionReports = createAsyncThunk(
  "transactionReports/getTransactionReports",
  async (payload, { rejectWithValue }) =>
    engagementApi
      .getTransactionReports({
        page: payload.page,
        length: payload.length,
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const getTransactionReport = createAsyncThunk(
  "transactionReports/getTransactionReport",
  async (payload, { rejectWithValue }) =>
    engagementApi
      .getTransactionReport({
        uuid: payload.uuid
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const getNotificationSettings = createAsyncThunk(
  "transactionReports/getNotificationSettings",
  async (payload, { rejectWithValue }) =>
    engagementApi
      .getNotificationSettings()
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const setNotificationSettings = createAsyncThunk(
  "transactionReports/setNotificationSettings",
  async (payload, { rejectWithValue }) =>
    engagementApi
      .setNotificationSettings(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);
