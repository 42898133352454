import React from 'react';
import { Helmet } from 'react-helmet';
import { Formik } from 'formik';
import {
  Box, Container, Card, Typography, Grid, Button,
} from '@mui/material';
import LineChartCard from '../../card/lineChartCard';
import ListingCountryTable from '../../card/listingCountryTable';
import MapScanCount from '../../card/mapScanCount';
import DateRangePicker from '../../card/dateRangePicker';
import PieChartScanValidity from '../../card/pieChartScanValidity';
import CompanySelect from '../../companySelect';
import CompanyScansTable from '../../card/companyScansTable';
import CompanyGenerateTable from '../../card/companyGenerateTable';
import CompanyAssignedTable from "../../card/companyAssignedTable";
import QrGeneratedAssignedGraphCard from "../../card/qrGeneratedAssignedGraphCard";

export default function MainPageComponent({
  handleDateRangeChange,
  dateRangeFilter,
  handleSearch,
  companyDropdown,
  handleCompanySearch,
}) {
  return (
    <>
      <Helmet>
        <title>Dashboard | Kood Asia Management Portal</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: '#e5e5ea',
          minHeight: '100vh',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Card
            sx={{
              mb: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                padding: 2,
                flex: '1 1 auto',
              }}
            >
              Dashboard
            </Typography>
            <Formik
              initialValues={{
                companies: [],
              }}
              enableReinitialize
              onSubmit={handleSearch}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CompanySelect
                    searchable
                    value={formik.values.companies}
                    placeholder="Search by Company"
                    searchPlaceholder="Search"
                    isClearable={false}
                    emptyMessage="No Company Found"
                    dropdownItem={companyDropdown}
                    isFetching={false}
                    isEmpty={formik.values.companies?.length === 0}
                    selectedCompany={formik.values.companies}
                    style={{ height: 35 }}
                    handleSelectAll={(selectAll) => {
                      if (selectAll) {
                        formik.setFieldValue('companies', companyDropdown);
                        const allCompanyIds = companyDropdown.map((company) => company.id);
                        handleCompanySearch(allCompanyIds);
                      } else {
                        formik.setFieldValue('companies', []);
                        const allCompanyIds = null;
                        handleCompanySearch(allCompanyIds);
                      }
                    }}
                    handleChange={(value) => {
                      let temp = [...formik.values.companies];
                      if (temp.length === 0) {
                        temp.push(value);
                      } else {
                        const isCompanyExist = temp.find((company) => company.id === value.id);
                        if (isCompanyExist === undefined) {
                          temp.push(value);
                        } else {
                          const filter = temp.filter((company) => company.id !== value.id);
                          temp = [...filter];
                        }
                      }
                      formik.setFieldValue('companies', [...temp]);
                      const companyIds = temp.map((company) => company.id);
                      handleCompanySearch(companyIds);
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    disabled={false}
                  />
                  <DateRangePicker handleDateRangeChange={handleDateRangeChange} />
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    size="small"
                    type="submit"
                    style={{
                      width: '75px', height: '40px', margin: 'auto 10px auto auto',
                    }}
                  >
                    Search
                  </Button>
                </form>
              )}
            </Formik>
          </Card>
          <Box
            sx={{
              backgroundColor: '#ffffff',
              height: '380px',
              width: '100%',
              marginTop: '20px',
              padding: '20px',
              borderRadius: '12px',
            }}
          >
            <MapScanCount />
          </Box>
          <Box
            sx={{
              backgroundColor: '#ffffff',
              width: '100%',
              marginTop: '20px',
              padding: '20px',
              borderRadius: '12px',
            }}
          >
            <LineChartCard />
          </Box>
          <Grid container spacing={2} style={{ marginTop: '20px' }}>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  backgroundColor: '#ffffff',
                  p: 2,
                  borderRadius: '12px',
                  height: '100%',
                  maxHeight: '600px',
                }}
              >
                <PieChartScanValidity />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  backgroundColor: '#ffffff',
                  height: '100%',
                  maxHeight: '600px',
                  overflow: 'auto',
                  p: 2,
                  borderRadius: '12px',
                }}
              >
                <ListingCountryTable />
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box
                sx={{
                  backgroundColor: '#ffffff',
                  p: 2,
                  borderRadius: '12px',
                  maxHeight: '600px',
                  height: '100%',
                }}
              >
                <CompanyScansTable />
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  backgroundColor: '#ffffff',
                  width: '100%',
                  marginTop: '20px',
                  padding: '20px',
                  borderRadius: '12px',
                }}
              >
                <QrGeneratedAssignedGraphCard />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  backgroundColor: '#ffffff',
                  p: 2,
                  borderRadius: '12px',
                }}
              >
                <CompanyGenerateTable />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  backgroundColor: '#ffffff',
                  p: 2,
                  borderRadius: '12px',
                }}
              >
                <CompanyAssignedTable />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
