import axios from "axios";

export default function getAccessRights(uuid) {
  const promise = new Promise((resolve, reject) =>
    axios
      .get(`/account/system-admin/api/v1/access-right/company/${uuid}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.message))
  );

  return promise;
}
