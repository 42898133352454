import { setLoading, addAlert } from "src/modules/notification/redux";
import {
  getAccessRights,
  getCompanies,
  getCompanyPlanInfo,
  getNodesWithWhiteLabel,
  updateAccessRights,
  updateCompanyPlan,
  putBranchWhiteLabel,
  removeBranchWhiteLabel,
  getBranchList,
  getProductList,
  updateAssign,
  updateUnassign, getNodeSnRange,
} from "../action/companies.action";

const getCompaniesMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case getCompanies.pending.type:
        dispatch(setLoading({ id: getCompanies.typePrefix, state: true }));
        break;
      case getCompanies.fulfilled.type:
        dispatch(setLoading({ id: getCompanies.typePrefix, state: false }));
        break;
      case getCompanies.rejected.type:
        dispatch(setLoading({ id: getCompanies.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: payload.code,
          })
        );
        break;
      default:
        break;
    }
  };

const getAccessRightsMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case getAccessRights.pending.type:
        dispatch(setLoading({ id: getAccessRights.typePrefix, state: true }));
        break;
      case getAccessRights.fulfilled.type:
        dispatch(setLoading({ id: getAccessRights.typePrefix, state: false }));
        break;
      case getAccessRights.rejected.type:
        dispatch(setLoading({ id: getAccessRights.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: payload.code,
          })
        );
        break;
      default:
        break;
    }
  };

const updateAccessRightsMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case updateAccessRights.pending.type:
        dispatch(
          setLoading({ id: updateAccessRights.typePrefix, state: true })
        );
        break;
      case updateAccessRights.fulfilled.type:
        dispatch(
          setLoading({ id: updateAccessRights.typePrefix, state: false })
        );
        break;
      case updateAccessRights.rejected.type:
        dispatch(
          setLoading({ id: updateAccessRights.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: payload.code,
          })
        );
        break;
      default:
        break;
    }
  };

const getCompanyPlanInfoMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case getCompanyPlanInfo.pending.type:
        dispatch(
          setLoading({ id: getCompanyPlanInfo.typePrefix, state: true })
        );
        break;
      case getCompanyPlanInfo.fulfilled.type:
        dispatch(
          setLoading({ id: getCompanyPlanInfo.typePrefix, state: false })
        );
        break;
      case getCompanyPlanInfo.rejected.type:
        dispatch(
          setLoading({ id: getCompanyPlanInfo.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: payload.code,
          })
        );
        break;
      default:
        break;
    }
  };

const updateCompanyPlanMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case updateCompanyPlan.pending.type:
        dispatch(setLoading({ id: updateCompanyPlan.typePrefix, state: true }));
        break;
      case updateCompanyPlan.fulfilled.type:
        dispatch(
          setLoading({ id: updateCompanyPlan.typePrefix, state: false })
        );
        break;
      case updateCompanyPlan.rejected.type:
        dispatch(
          setLoading({ id: updateCompanyPlan.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: payload.code,
          })
        );
        break;
      default:
        break;
    }
  };

const getNodesWithWhiteLabelMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case getNodesWithWhiteLabel.pending.type:
        dispatch(
          setLoading({ id: getNodesWithWhiteLabel.typePrefix, state: true })
        );
        break;
      case getNodesWithWhiteLabel.fulfilled.type:
        dispatch(
          setLoading({ id: getNodesWithWhiteLabel.typePrefix, state: false })
        );
        break;
      case getNodesWithWhiteLabel.rejected.type:
        dispatch(
          setLoading({ id: getNodesWithWhiteLabel.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: payload.code,
          })
        );
        break;
      default:
        break;
    }
  };

  const putBranchWhiteLabelMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case putBranchWhiteLabel.pending.type:
        dispatch(
          setLoading({ id: putBranchWhiteLabel.typePrefix, state: true })
        );
        break;
      case putBranchWhiteLabel.fulfilled.type:
        dispatch(
          setLoading({ id: putBranchWhiteLabel.typePrefix, state: false })
        );
        break;
      case putBranchWhiteLabel.rejected.type:
        dispatch(
          setLoading({ id: putBranchWhiteLabel.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: payload.data.white_label,
          })
        );
        break;
      default:
        break;
    }
  };

  const removeBranchWhiteLabelMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case removeBranchWhiteLabel.pending.type:
        dispatch(
          setLoading({ id: removeBranchWhiteLabel.typePrefix, state: true })
        );
        break;
      case removeBranchWhiteLabel.fulfilled.type:
        dispatch(
          setLoading({ id: removeBranchWhiteLabel.typePrefix, state: false })
        );
        break;
      case removeBranchWhiteLabel.rejected.type:
        dispatch(
          setLoading({ id: removeBranchWhiteLabel.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: payload.code,
          })
        );
        break;
      default:
        break;
    }
  };

  const getBranchListMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case getBranchList.pending.type:
        dispatch(
          setLoading({ id: getBranchList.typePrefix, state: true })
        );
        break;
      case getBranchList.fulfilled.type:
        dispatch(
          setLoading({ id: getBranchList.typePrefix, state: false })
        );
        break;
      case getBranchList.rejected.type:
        dispatch(
          setLoading({ id: getBranchList.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: payload.code,
          })
        );
        break;
      default:
        break;
    }
  };

  const getProductListMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case getProductList.pending.type:
        dispatch(
          setLoading({ id: getProductList.typePrefix, state: true })
        );
        break;
      case getProductList.fulfilled.type:
        dispatch(
          setLoading({ id: getProductList.typePrefix, state: false })
        );
        break;
      case getProductList.rejected.type:
        dispatch(
          setLoading({ id: getProductList.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: payload.code,
          })
        );
        break;
      default:
        break;
    }
  };

  const updateAssignMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case updateAssign.pending.type:
        dispatch(
          setLoading({ id: updateAssign.typePrefix, state: true })
        );
        break;
      case updateAssign.fulfilled.type:
        dispatch(
          setLoading({ id: updateAssign.typePrefix, state: false })
        );
        break;
      case updateAssign.rejected.type:
        dispatch(
          setLoading({ id: updateAssign.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: payload.code,
          })
        );
        break;
      default:
        break;
    }
  };

  const updateUnassignMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case updateUnassign.pending.type:
        dispatch(
          setLoading({ id: updateUnassign.typePrefix, state: true })
        );
        break;
      case updateUnassign.fulfilled.type:
        dispatch(
          setLoading({ id: updateUnassign.typePrefix, state: false })
        );
        break;
      case updateUnassign.rejected.type:
        dispatch(
          setLoading({ id: updateUnassign.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: payload.code,
          })
        );
        break;
      default:
        break;
    }
  };

const getNodeSnRangeMiddleware =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);

        const { payload, type } = action;

        switch (type) {
          case getNodeSnRange.pending.type:
            dispatch(
              setLoading({ id: getNodeSnRange.typePrefix, state: true })
            );
            break;
          case getNodeSnRange.fulfilled.type:
            dispatch(
              setLoading({ id: getNodeSnRange.typePrefix, state: false })
            );
            break;
          case getNodeSnRange.rejected.type:
            dispatch(
              setLoading({ id: getNodeSnRange.typePrefix, state: false })
            );
            dispatch(
              addAlert({
                severity: "error",
                message: payload.code,
              })
            );
            break;
          default:
            break;
        }
      };

export default [
  getCompaniesMiddleware,
  getAccessRightsMiddleware,
  updateAccessRightsMiddleware,
  getCompanyPlanInfoMiddleware,
  updateCompanyPlanMiddleware,
  getNodesWithWhiteLabelMiddleware,
  putBranchWhiteLabelMiddleware,
  removeBranchWhiteLabelMiddleware,
  getBranchListMiddleware,
  getProductListMiddleware,
  updateAssignMiddleware,
  updateUnassignMiddleware,
  getNodeSnRangeMiddleware,
];
