import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { checkSession, parseHash } from './session.action';

const SESSION = 'session';
const initialState = {
  language: localStorage.getItem('language') || 'en',
  isSessionChecked: false,
  accessToken: '',
  expiresIn: 0,
  idTokenPayload: {
    sub: '',
    name: '',
    email: '',
  },
};

const sessionSlice = createSlice({
  name: SESSION,
  initialState,
  reducers: {
    setSession: (state, action) => {
      const { payload } = action;

      axios.defaults.headers.common.Authorization = `Bearer ${payload.access_token}`;

      state.isSessionChecked = true;
      state.accessToken = payload.access_token;
      state.expiresIn = payload.access_token_payload.exp;
      state.idTokenPayload.name = payload.access_token_payload.name;
      state.idTokenPayload.email = payload.access_token_payload.email;
      state.idTokenPayload.sub = payload.access_token_payload.sub;
    },
    clearSession: (state) => {
      /* eslint-disable */
      state = initialState;
      /* eslint-enable */
    },
    setCheckedSession: (state, action) => {
      state.isSessionChecked = action.payload;
    },
  },
  extraReducers: {
    [checkSession.rejected]: (state) => {
      state.isSessionChecked = true;
      localStorage.clear();
    },
    [parseHash.fulfilled]: (state, action) => {
      state.isSessionChecked = true;
      state.isAccessGranted = true;
      state.isHashParsed = true;
      state.accessToken = action.payload.accessToken;
      state.expiresIn = action.payload.expiresIn;
      state.idToken = action.payload.idToken;
      state.idTokenPayload = action.payload.idTokenPayload;
      state.refreshToken = action.payload.refreshToken;
      state.scope = action.payload.scope;
      state.state = action.payload.state;
      state.tokenType = action.payload.tokenType;
      state.isSessionSet = true;
    },
  },
});

export const {
  setSession,
  clearSession,
  setCheckedSession,
} = sessionSlice.actions;
export const sessionReducer = sessionSlice.reducer;
