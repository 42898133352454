import {
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize
} from "@material-ui/core";
import { useState } from "react";

export default function CompanyChangeStatusDialogComponent({
  isOpen,
  action,
  handleClose,
  handleConfirm
}) {
  const [remark, setRemark] = useState('');

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {action === 'enable' ? 'Confirm to enable this company ?' : null}
        {action === 'disable' ? 'Confirm to disable this company ?' : null}
      </DialogTitle>
      <DialogContent>
        {action === 'disable' && (
          <TextareaAutosize
            style={{
              width: "100%",
              boxSizing: "border-box",
              fontFamily: "'IBM Plex Sans', sans-serif",
              fontSize: "0.875rem",
              fontWeight: 400,
              lineHeight: 1.5,
              padding: "8px 12px",
              borderRadius: "8px",
              color: '#1C2025',
              background: '#fff',
              border: "1px solid #DAE2ED",
              boxShadow: "0px 2px 2px #F3F6F9",
              '&:hover': {
                borderColor: '#3399FF',
              },
              '&:focus': {
                borderColor: '#3399FF',
                boxShadow: "0 0 0 3px #b6daff",
              },
              '&:focus-visible': {
                outline: 0,
              },
            }}
            onChange={(event) => setRemark(event.target.value)}
            placeholder="Remark"
            minRows={3}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setRemark('');
            handleClose();
          }}
        >
          No
        </Button>
        <Button
          onClick={() => {
            setRemark('');
            handleConfirm(action, remark);
          }}
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
