import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import dashboardApi from 'src/configs/apis/dashboard';
import { setLoading } from 'src/modules/notification/redux';

const DASHBOARD = 'dashboard';

export const filterDashboard = createAction('filterDashboard');

// This is for the line chart
export const fetchLineChartScanCount = createAsyncThunk(
  `${DASHBOARD}/fetchLineChartScanCount`,
  async (payload, thunkApi) => dashboardApi.getLineChartScanCount(payload)
    .then((response) => response)
    .catch((error) => thunkApi.rejectWithValue(error)),
);

// This is for the Country table
export const fetchListingCountryTable = createAsyncThunk(
  `${DASHBOARD}/fetchListingCountryTable`,
  async (payload, thunkApi) => dashboardApi.getListingCountryTable(payload)
    .then((response) => response)
    .catch((error) => thunkApi.rejectWithValue(error)),
);

// This is for the Google Map display
export const fetchMapScanCount = createAsyncThunk(
  `${DASHBOARD}/fetchMapScanCount`,
  async (payload, thunkApi) => dashboardApi.getMapScanCount(payload)
    .then((response) => response)
    .catch((error) => thunkApi.rejectWithValue(error)),
);

// This is for the doughnut chart
export const fetchPieChartScanValidity = createAsyncThunk(
  `${DASHBOARD}/fetchPieChartScanValidity`,
  async (payload, thunkApi) => dashboardApi.getPieChartScanValidity(payload)
    .then((response) => response)
    .catch((error) => thunkApi.rejectWithValue(error)),
);

// This is for the date range picker
export const updateDateRangeFilter = createAction(
  `${DASHBOARD}/UPDATE_DATE_RANGE_FILTER`,
  (startDate, endDate) => {
    const validStartDate = startDate instanceof Date ? startDate : new Date(startDate);
    const validEndDate = endDate instanceof Date ? endDate : new Date(endDate);

    // Check if the dates are valid
    const isStartDateValid = !Number.isNaN(validStartDate.getTime());
    const isEndDateValid = !Number.isNaN(validEndDate.getTime());

    // Return null values if the dates are invalid
    if (!isStartDateValid || !isEndDateValid) {
      return {
        payload: {
          startDate: null,
          endDate: null,
        },
      };
    }

    return {
      payload: {
        startDate: validStartDate.toISOString(),
        endDate: validEndDate.toISOString(),
      },
    };
  },
);

// This is for the view all companies in dropdown
export const getAllCompanies = createAsyncThunk(
  `${DASHBOARD}/getAllCompanies`,
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setLoading({ id: getAllCompanies.typePrefix, state: true }));
    return dashboardApi.getAllCompanies()
      .then((response) => {
        dispatch(
          setLoading({ id: getAllCompanies.typePrefix, state: false }),
        );

        return response.data;
      })
      .catch((error) => {
        dispatch(
          setLoading({ id: getAllCompanies.typePrefix, state: false }),
        );

        return rejectWithValue(error);
      });
  },
);

// This is for the get Top 20 scans companies's id and name
export const fetchTop20Companies = createAsyncThunk(
  `${DASHBOARD}/fetchTop20Companies`,
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setLoading({ id: fetchTop20Companies.typePrefix, state: true }));
    try {
      const response = await dashboardApi.getTop20Companies(payload);
      dispatch(setLoading({ id: fetchTop20Companies.typePrefix, state: false }));
      return response.data;
    } catch (error) {
      dispatch(setLoading({ id: fetchTop20Companies.typePrefix, state: false }));
      console.error('Error fetching top 20 companies:', error);
      return rejectWithValue(error);
    }
  },
);

// This is for the Company table
export const fetchListingCompanyTable = createAsyncThunk(
  `${DASHBOARD}/fetchListingCompanyTable`,
  async (payload, thunkApi) => dashboardApi.getListingCompanyTable(payload)
    .then((response) => response)
    .catch((error) => thunkApi.rejectWithValue(error)),
);

export const fetchCompanyGenerateTable = createAsyncThunk(
  `${DASHBOARD}/fetchCompanyGenerateTable`,
  async (payload, thunkApi) => dashboardApi
    .getCompanyGenerate({ payload })
    .then((response) => response)
    .catch((error) => thunkApi.rejectWithValue(error)),
);

export const fetchCompanyAssignedTable = createAsyncThunk(
  `${DASHBOARD}/fetchCompanyAssignedTable`,
  async (payload, thunkApi) => dashboardApi
    .getCompanyAssigned({ payload })
    .then((response) => response)
    .catch((error) => thunkApi.rejectWithValue(error)),
);

export const fetchGeneratedAssignedGraph = createAsyncThunk(
  `${DASHBOARD}/fetchGeneratedAssignedGraph`,
  async (payload, thunkApi) => dashboardApi
    .getGeneratedAssignGraph(payload)
    .then((response) => response)
    .catch((error) => thunkApi.rejectWithValue(error)),
);
