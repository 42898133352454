import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  TextField,
  DialogActions,
  FormControl,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  Skeleton,
  IconButton,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import { Formik } from "formik";
import CloseIcon from "@material-ui/icons/CloseRounded";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Masonry from "../../masonry/Masonry";
import AccessRightAccordion from "../../accordion/accessRightAccordion";

const useStyles = makeStyles((theme) => ({
  accessBox: {
    display: "flex",
    flexFlow: "column",
  },
  publishButton: {
    color: theme.palette.common.white,
  },
  scrollContainer: {
    maxHeight: "70vh",
    overflowY: "auto",
    backgroundColor: "#f3f3f3",
  },
}));

const Accordion = withStyles({
  root: {
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    margin: "0px 0",
    "&$expanded": {
      margin: "0px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: "unset",
  },
}))(MuiAccordionDetails);

export default function CompanySwapPlanDialogComponent({
  selectedCompany,
  handleSubmit,
  onCompanySwapPlanDialogCloseClick,
  accessRights,
  isLoading,
  isUpdating,
  plans,
  planInfo,
}) {
  const classes = useStyles();

  if (!selectedCompany) {
    return null;
  }
  return (
    <Dialog
      open={!!selectedCompany}
      onClose={onCompanySwapPlanDialogCloseClick}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
    >
      <Formik
        initialValues={{
          plan: plans.length ? plans[0].uuid : "",
          need_access_right_update: false,
        }}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle id="scroll-dialog-title">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body2">
                  <b>
                    {selectedCompany ? selectedCompany.name : ""} - Swap Plan
                  </b>
                </Typography>
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={onCompanySwapPlanDialogCloseClick}
                  style={{ padding: 0 }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent dividers className={classes.scrollContainer}>
              <Box className={classes.accessBox}>
                {isLoading ? (
                  <>
                    <Skeleton variant="text" animation="wave" height={25} />
                    <Skeleton variant="text" animation="wave" height={25} />
                    <Skeleton variant="text" animation="wave" height={25} />
                    <Skeleton variant="text" animation="wave" height={25} />
                  </>
                ) : (
                  <Box>
                    <Box mb={3}>
                      <Typography variant="body1">
                        Current Plan: {planInfo ? planInfo.plan_name : ""}
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      label="Plan"
                      name="plan"
                      onChange={(event) => {
                        formik.setFieldValue("plan", event.target.value);
                      }}
                      helperText={formik.touched.plan && formik.errors.plan}
                      required
                      select
                      SelectProps={{ native: true }}
                      value={formik.values.plan}
                      variant="outlined"
                      disabled={isUpdating}
                      inputProps={{ style: { textTransform: "capitalize" } }}
                    >
                      {plans.map((plan, i) => (
                        <option
                          value={plan.uuid}
                          key={i}
                          style={{ textTransform: "capitalize" }}
                        >
                          {plan.name}
                        </option>
                      ))}
                    </TextField>
                    <Box display="flex" alignItems="center" mt={2} mb={1}>
                      <Checkbox
                        checked={!formik.values.need_access_right_update}
                        onChange={(event, checked) =>
                          formik.setFieldValue(
                            "need_access_right_update",
                            !checked
                          )
                        }
                      />
                      <Box>
                        Maintain current access right when switching plan
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                variant="text"
                type="button"
                disabled={isUpdating}
                onClick={onCompanySwapPlanDialogCloseClick}
                style={{ color: "black" }}
              >
                Cancel
              </Button>
              <Button variant="contained" type="submit" disabled={isUpdating}>
                Update
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}
