import React from 'react';
import { useSelector } from 'react-redux';
import CompanyGenerateTableComponent from './companyGenerateTable.component';

function CompanyGenerateTableContainer() {
  const generateRecordData = useSelector((state) => state.dashboard.companyGenerateRecords.data);
  const isLoading = useSelector((state) => state.dashboard.companyGenerateRecords.isLoading);
  const error = useSelector((state) => state.dashboard.companyGenerateRecords.error);

  return (
    <CompanyGenerateTableComponent
      isLoading={isLoading}
      data={generateRecordData}
      error={error}
    />
  );
}

export default CompanyGenerateTableContainer;
