import axios from 'axios';

export default function getApprovals({ page, length, companyName, regNo, status, plan }) {
  const start = page * length;
  const promise = new Promise((resolve, reject) => axios
    .get('/account/system-admin/api/v1/companies',
      {
        params: {
          start,
          length,
          companyName,
          regNo,
          status,
          plan,
        },
      })
    .then((response) => resolve(response.data))
    .catch((error) => reject(error.message)));

  return promise;
}
