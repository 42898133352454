/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  ButtonBase,
  Typography,
  Button,
  Box,
} from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '5px 12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#eceff0',
    borderRadius: 4,
    width: '100%',
    height: 43,
    overflow: 'hidden',
  },
  inputText: {
    fontSize: '1rem',
    color: '#a4a6a8',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  filled: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  clearButton: {
    minWidth: 0,
  },
}));

function SearchableSelectComponent({
  handleClick,
  handleClear,
  value,
  placeholder,
  disabled,
  style,
  isEmpty,
  id,
  open,
  textStyle,
  isClearable,
}) {
  const classes = useStyles();

  return (
    <ButtonBase
      aria-describedby={id}
      className={classes.root}
      disableRipple
      onClick={handleClick}
      disabled={disabled}
      style={{ paddding: 12, ...style }}
    >
      <Typography
        variant="body2"
        className={clsx(classes.inputText, {
          [classes.filled]: !isEmpty,
        })}
        style={textStyle}
      >
        {
          isEmpty ?
            placeholder :
            value?.name !== undefined ?
              value?.name :
              value
        }
      </Typography>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {isClearable && !isEmpty && (
          <Button
            className={classes.clearButton}
            onClick={(e) => {
              e.stopPropagation();
              handleClear();
            }}
          >
            <ClearIcon style={{ fontSize: 16 }} />
          </Button>
        )}
        {!disabled && (open ? (
          <ArrowDropUpIcon fontSize="small" />
        ) : (
          <ArrowDropDownIcon fontSize="small" />
        ))}
      </Box>
    </ButtonBase>
  );
}

SearchableSelectComponent.propTypes = {
  handleClick: PropTypes.func,
  handleClear: PropTypes.func,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isEmpty: PropTypes.bool,
  isClearable: PropTypes.bool,
};

export default SearchableSelectComponent;
