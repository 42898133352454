import { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { ThemeProvider, StyledEngineProvider } from "@material-ui/core";
import GlobalStyles from "./components/GlobalStyles";
import "react-perfect-scrollbar/dist/css/styles.css";
import theme from "./configs/theme";
import routes from "./configs/routes";
import { getIndustries } from "./configs/redux/constants/constants.action";
import { checkSession } from "./configs/redux/session/session.action";
import Loading from "./components/Loading";
import { setCheckedSession } from "./configs/redux/session/session.slice";
import Notification from "./modules/notification";

const { REACT_APP_API_HOST_URL } = process.env;

export default function App() {
  const dispatch = useDispatch();
  const router = useRoutes(routes);
  // Language
  const language = useSelector((state) => state.session.language);
  // Default Redux state Configs
  const isSessionChecked = useSelector(
    (state) => state.session.isSessionChecked
  );
  // Axios Default Configs
  axios.defaults.baseURL = REACT_APP_API_HOST_URL;
  axios.defaults.headers.common["Content-Type"] = "application/json";
  // Check session
  useEffect(() => {
    dispatch(getIndustries({ language }));

    const sessionId = localStorage.getItem("session_id");
    const sessionExpiration = localStorage.getItem("session_expiration");

    if (sessionId && sessionExpiration) {
      dispatch(
        checkSession({
          sessionId,
          sessionExpiration,
        })
      );
    } else {
      dispatch(setCheckedSession(true));
    }
  }, [dispatch]);

  if (!isSessionChecked) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <StyledEngineProvider injectFirst>
      <Notification />
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {router}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
