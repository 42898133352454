import React from "react";
import { ButtonBase, Typography, Box } from "@material-ui/core";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#ECEFF0",
    borderRadius: "4px",
    width: "100%",
  },
  inputText: {
    color: "#a4a6a8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  disabledField: {
    backgroundColor: "#eceff0",
  },
  filled: {
    minWidth: "auto",
    color: "black",
    display: "inline-block",
    flexWrap: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    alignSelf: "center",
  },
}));

export default function ProviderSelectComponent({
  anchorRef,
  handleClick,
  value,
  style,
  disabled,
  placeholder,
  open,
  textStyle,
}) {
  const classes = useStyles();

  const getDisplay = () => {
    let display = placeholder;
    if (value) {
      display = value.name;
    }
    return display;
  };

  const displayValue = () => (
    <Box
      style={{
        display: "flex",
        flexWrap: "wrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        flex: "2",
      }}
    >
      <Typography
        variant="body1"
        noWrap
        style={textStyle}
        className={clsx(
          classes.inputText,
          getDisplay() !== placeholder && classes.filled
        )}
      >
        {getDisplay()}
      </Typography>
    </Box>
  );

  return (
    <ButtonBase
      ref={anchorRef}
      className={clsx(classes.root, {
        [classes.disabledField]: disabled,
      })}
      disableRipple
      onClick={handleClick}
      disabled={disabled}
      style={{ padding: 12, ...style }}
    >
      {displayValue()}
      <Box
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {open ? (
          <ArrowDropUpIcon fontSize="small" />
        ) : (
          <ArrowDropDownIcon fontSize="small" />
        )}
      </Box>
    </ButtonBase>
  );
}
