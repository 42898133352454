import axios from "axios";

export default function getCompanyPlanInfo(id) {
  return new Promise((resolve, reject) =>
    axios
      .get(`/account/system-admin/api/v1/company/${id}/plan-info`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.message))
  );
}
