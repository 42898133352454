import axios from 'axios';

export default function removeBranchWhiteLabel({
  id,
}) {
  return new Promise((resolve, reject) => axios
    .put(`/account/system-admin/api/v1/node/${id}/remove-white-label`,
      {
        id,
      })
    .then((response) => resolve(response.data))
    .catch((error) => reject(error.message)));
}
