import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from '../Logo';

const DashboardNavbar = ({
  onDrawerToggle,
}) => (
  <AppBar
    elevation={0}
  >
    <Toolbar>
      <RouterLink to="/">
        <Logo />
      </RouterLink>
      <Box sx={{ flexGrow: 1 }} />
      <Hidden mdUp>
        <IconButton color="inherit" onClick={onDrawerToggle} size="large">
          <MenuIcon />
        </IconButton>
      </Hidden>
    </Toolbar>
  </AppBar>
);

DashboardNavbar.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default DashboardNavbar;
