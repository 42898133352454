import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateRangePickerComponent from './dateRangePicker.component';

const DateRangePickerContainer = ({
  handleDateRangeChange,
}) => {
  const dispatch = useDispatch();
  const dateRangeFilter = useSelector((state) => state.dateRangeFilter);

  return (
    <DateRangePickerComponent
      handleDateRangeChange={handleDateRangeChange}
      defaultValue={[dateRangeFilter]}
    />
  );
};

export default DateRangePickerContainer;
