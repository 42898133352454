import { Helmet } from "react-helmet";
import {
  Box,
  Paper,
  InputBase,
  Container,
  Card,
  Typography,
  TableCell,
  Button,
  Popper,
  MenuList,
  MenuItem,
  ClickAwayListener,
  Divider,
  TextField,
  Input,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PropTypes from "prop-types";
import SearchableSelect from 'src/components/SearchableSelect';
import { Formik } from 'formik';
import DataTable from "../../../../components/DataTable";

const style = (theme) => ({
  paper: {
    backgroundColor: "#3A4D54",
    color: theme.palette.common.white,
    borderRadius: "4px"
  },
  boxSubmenu: {
    "&::before": {
      content: '""',
      position: "absolute",
      width: "0",
      height: "0",
      top: "8px",
      right: "-15px",
      border: "8px solid",
      borderColor: "transparent transparent transparent",
      borderLeftColor: "#3A4D54",
      zIndex: 1
    }
  },
  divider: {
    height: '70%',
    backgroundColor: theme.palette.divider,
    margin: theme.spacing(0, 1),
  },
  menuItem: {
    fontSize: "small",
    display: "block"
  },
});

const useStyles = makeStyles(style);

export default function CompaniesPage({
  industries,
  isCompaniesLoading,
  companies,
  totalCompanies,
  companiesPage,
  onAccessRightClick,
  onCompanyViewClick,
  onCompanyDashboardViewClick,
  onCompanyWhiteLabelClick,
  onCompanyManageBranchClick,
  onCompaniesPageClick,
  onCompanySwapPlanClick,
  onSerialNumberAssignClick,
  onEnableClick,
  onDisableClick,
  allBranchWhiteLabelCount,
  open,
  anchorEl,
  anchorRef,
  handleOpen,
  handleClose,
  selectedRow,
  setSelectedRow,
  //  👇  Companies Search Function  👇  //
  initialFilter,
  typeList,
  planList,
  statusList,
  onSearchSubmit,
  //  👇  Company Users Dialog  👇  //
  onCompanyUsersViewClick,
  handleItemPerPageChange,
  itemPerPage,
}) {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>Companies | Kood Asia Management Portal</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          paddingTop: 3,
          paddingBottom: 12,
        }}
      >
        <Container maxWidth={false}>
          <Card sx={{ mb: 2 }}>
            <Typography
              variant="h4"
              sx={{
                padding: 2,
              }}
            >
              Companies
            </Typography>
          </Card>
          <Card sx={{ mb: 2, p: 2 }}>
            <Formik
              initialValues={initialFilter}
              onSubmit={(values, actions) => {
                onSearchSubmit(values);
                actions.setSubmitting(false);
              }}
            >
              {({
                values,
                setFieldValue,
                resetForm,
                isSubmitting,
                handleSubmit
              }) => (
                <form
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "20px"
                  }}
                  onSubmit={handleSubmit}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#eceff0",
                      borderRadius: "4px",
                    }}
                  >
                    <SearchableSelect
                      style={{
                        height: "fit-content",
                        width: "fit-content",
                        display: "flex",
                        gap: "10px",
                        padding: "0 12px",
                      }}
                      value={values.type}
                      handleChange={(value) => setFieldValue('type', value)}
                      disabled={isSubmitting}
                      dropdownItem={typeList.map((item) => item.label)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    />
                    <Divider style={{ height: "23px", borderLeft: "1px solid rgba(0, 0, 0, 0.12)" }} />
                    <Input
                      value={values.query}
                      style={{
                        flex: 1,
                        marginLeft: "15px"
                      }}
                      disabled={isSubmitting}
                      disableUnderline
                      placeholder="Enter"
                      onChange={(event) => {
                        setFieldValue('query', event.target.value);
                      }}
                    />
                  </Box>
                  <SearchableSelect
                    style={{
                      height: "fit-content",
                      width: "210px",
                      padding: values.plan ? "2px 15px" : "5px 15px"
                    }}
                    placeholder="Plan"
                    isEmpty={!values.plan}
                    value={values.plan}
                    handleChange={(value) => setFieldValue('plan', value)}
                    disabled={isSubmitting}
                    isClearable
                    dropdownItem={planList.map((item) => item.label)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    handleClear={() => setFieldValue("plan", "")}
                  />
                  <SearchableSelect
                    style={{
                      height: "fit-content",
                      width: "280px",
                      padding: values.status ? "2px 15px" : "5px 15px"
                    }}
                    placeholder="Status"
                    isEmpty={!values.status}
                    value={values.status}
                    handleChange={(value) => setFieldValue('status', value)}
                    disabled={isSubmitting}
                    isClearable
                    dropdownItem={statusList.map((item) => item.label)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    handleClear={() => setFieldValue("status", "")}
                  />
                  <Button
                    sx={{ px: 3 }}
                    disabled={isSubmitting}
                    type="button"
                    size="small"
                    variant="text"
                    aria-label="search"
                    onClick={resetForm}
                  >
                    CLEAR
                  </Button>
                  <Button
                    sx={{ px: 3 }}
                    disabled={isSubmitting}
                    type="submit"
                    size="small"
                    variant="contained"
                    aria-label="search"
                  >
                    SEARCH
                  </Button>
                </form>
              )}
            </Formik>
          </Card>
          <DataTable
            isLoading={isCompaniesLoading}
            rows={companies}
            rowsPerPage={itemPerPage}
            total={totalCompanies}
            page={companiesPage}
            renderHeader={() => (
              <>
                <TableCell>Registration Number</TableCell>
                <TableCell>Company Name</TableCell>
                <TableCell>White Label</TableCell>
                <TableCell>Branch White Label Count</TableCell>
                <TableCell>Plan</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </>
            )}
            renderRow={(row, index) => {
              const actionItems = [
                {
                  label: 'Enable',
                  condition: row.company.status === 'deactivated',
                  onClick: () => onEnableClick(selectedRow.company.id),
                },
                {
                  label: 'Disable',
                  condition: row.company.status === 'approved',
                  onClick: () => onDisableClick(selectedRow.company.id),
                },
                {
                  label: 'Access Rights',
                  onClick: () => onAccessRightClick(selectedRow),
                },
                {
                  label: 'View Company Details',
                  onClick: () => onCompanyViewClick(selectedRow),
                },
                {
                  label: 'View Company Dashboard',
                  onClick: () => onCompanyDashboardViewClick(selectedRow),
                },
                {
                  label: 'White Label',
                  onClick: () => onCompanyWhiteLabelClick(selectedRow),
                },
                {
                  label: 'Manage Branch',
                  onClick: () => onCompanyManageBranchClick(selectedRow),
                },
                {
                  label: 'Users',
                  onClick: () =>
                    onCompanyUsersViewClick({
                      companyId: selectedRow.company.id,
                      companyPlan: selectedRow.company.plan,
                    }),
                },
                {
                  label: 'Swap Plan',
                  onClick: () => onCompanySwapPlanClick(selectedRow),
                  condition: row.company.plan.includes("Free Saas"),
                },
                {
                  label: 'Assign',
                  onClick: () =>
                    onSerialNumberAssignClick({
                      companyUuid: selectedRow.company.id,
                      company: selectedRow,
                    }),
                },
              ];

              return (
                <>
                  <TableCell>{row.company.regNo}</TableCell>
                  <TableCell>{row.company.name}</TableCell>
                  <TableCell>{row.company.whiteLabel}</TableCell>
                  <TableCell>{allBranchWhiteLabelCount[index]}</TableCell>
                  <TableCell
                    sx={{
                      color:
                        row.company.plan.includes("Full Saas") &&
                        !["Pro", "Biz", "Lite"].includes(row.company.plan) &&
                        "red",
                    }}
                  >
                    {row.company.plan}
                  </TableCell>
                  <TableCell>{row.company.status}</TableCell>
                  {/* Action button starts here */}
                  <TableCell>
                    <Button
                      onClick={(event) => {
                        handleOpen(event);
                        setSelectedRow(row);
                      }}
                    >
                      <MoreHorizIcon />
                    </Button>
                    <Popper
                      open={open && selectedRow.company.id === row.company.id}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      placement="left-start"
                      style={{ zIndex: 1 }}
                    >
                      <Box className={classes.boxSubmenu}>
                        <Paper className={classes.paper}>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList>
                              {actionItems.map((item) => (
                                item.condition !== undefined ? item.condition && (
                                  <MenuItem
                                    className={classes.menuItem}
                                    key={row}
                                    onClick={(e) => {
                                      item.onClick();
                                      handleClose(e);
                                    }}
                                  >
                                    {item.label}
                                  </MenuItem>
                                ) : (
                                  <MenuItem
                                    className={classes.menuItem}
                                    key={row}
                                    onClick={(e) => {
                                      item.onClick();
                                      handleClose(e);
                                    }}
                                  >
                                    {item.label}
                                  </MenuItem>
                                )
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Box>
                    </Popper>
                  </TableCell>
                </>
              );
            }}
            onPageClick={onCompaniesPageClick}
            handleItemPerPageChange={handleItemPerPageChange}
          />
        </Container>
      </Box>
    </>
  );
}

CompaniesPage.propTypes = {
  industries: PropTypes.array.isRequired,
  isCompaniesLoading: PropTypes.bool.isRequired,
  companies: PropTypes.array.isRequired,
  totalCompanies: PropTypes.number.isRequired,
  companiesPage: PropTypes.number.isRequired,
  onCompanyViewClick: PropTypes.func.isRequired,
  onCompanyDashboardViewClick: PropTypes.func.isRequired,
  onCompanyWhiteLabelClick: PropTypes.func.isRequired,
  onCompanyManageBranchClick: PropTypes.func.isRequired,
  onCompaniesPageClick: PropTypes.func.isRequired,
  allBranchWhiteLabelCount: PropTypes.array.isRequired,
};
