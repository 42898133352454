import axios from 'axios';

export default function removeCompanyWhiteLabel({
  id,
}) {
  return new Promise((resolve, reject) => axios
    .delete(`/account/system-admin/api/v1/company/${id}/white_label`)
    .then((response) => resolve(response.data))
    .catch((error) => reject(error.message)));
}
