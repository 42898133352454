import { useEffect, useState } from "react";
import ReassignResultComponent from "./reassign-result.component";

export default function ReassignResultContainer({
  result,
  onClose
}) {
  const [display, setDisplay] = useState(result.map((e) => ({
    ...e,
    open: false,
  })));

  return (
    <ReassignResultComponent
      result={display}
      onCollapseClick={(i) => {
        const temp = display.map((item, index) => (index === i ? { ...item, open: !item.open } : item));
        setDisplay(temp);
      }}
      onClose={onClose}
    />
  );
}
