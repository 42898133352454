import getLineChartScanCount from './getLineChartScanCount.api';
import getListingCountryTable from './getListingCountryTable.api';
import getMapScanCount from './getMapScanCount.api';
import getPieChartScanValidity from './getPieChartScanValidity.api';
import getAllCompanies from './allCompanies.api';
import getTop20Companies from './getTop20Companies.api';
import getListingCompanyTable from './getListingCompanyTable.api';
import getCompanyGenerate from './getCompanyGenerate.api';
import getCompanyAssigned from "./getCompanyAssigned.api";
import getGeneratedAssignGraph from "./getGeneratedAssignGraph.api";

const dashboardApi = {
  getLineChartScanCount,
  getListingCountryTable,
  getMapScanCount,
  getPieChartScanValidity,
  getAllCompanies,
  getTop20Companies,
  getListingCompanyTable,
  getCompanyGenerate,
  getCompanyAssigned,
  getGeneratedAssignGraph,
};

export default dashboardApi;
