import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addAlert } from 'src/modules/notification';
import BranchSelectPanelComponent from './branch-select-panel.component';
import BranchSelectComponent from "./branch-select.component";

function BranchSelectContainer({
  branchListFilter,
  setBranchListFilter,
  disabled,
}) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const isLoading = useSelector((state) => state.companyDashboard.branchList.isLoading);
  const branchList = useSelector((state) => state.companyDashboard.branchList.data);
  const error = useSelector((state) => state.companyDashboard.branchList.error);

  useEffect(() => {
    if (!branchList.length) return;
    setBranchListFilter(branchList.map((b) => b.id));
  }, [branchList]);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!error) return;
    dispatch(
      addAlert({
        severity: 'warning',
        message: 'Error while fetching branches'
      })
    );
  }, [error]);

  return (
    <>
    <BranchSelectComponent
      handleClick={handleClick}
      open={!!anchorEl}
      disabled={disabled}
      branchList={branchList}
      branchListFilter={branchListFilter}
    />
    <BranchSelectPanelComponent
      anchorEl={anchorEl}
      handleClose={handleClose}
      disabled={disabled}
      isLoading={isLoading}
      branchList={branchList}
      branchListFilter={branchListFilter}
      setBranchListFilter={setBranchListFilter}
    />
    </>
  );
}

export default BranchSelectContainer;
