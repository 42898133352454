import axios from "axios";

export default function getNotificationSettings() {
  return new Promise((resolve, reject) =>
    axios
      .get(`/notification/system-admin/api/v1/tngReportNotificationSetting`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.message))
  );
}
