import { forwardRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  DialogActions,
  FormControl,
  FormControlLabel,
  Checkbox,
  Button,
  Divider,
  Box,
  Skeleton,
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/styles";
import MuiTextField from "@material-ui/core/TextField";
import AccessRightAccordion from "src/modules/companies/components/accordion/accessRightAccordion";
import Masonry from "src/modules/companies/components/masonry/Masonry";

const useStyles = makeStyles((theme) => ({
  content: {
    maxHeight: "70vh",
    overflowY: "auto",
    backgroundColor: "#f3f3f3",
  },
}));

const TextField = withStyles({
  root: {
    background: "white",
  },
  expanded: {},
})(MuiTextField);

const Checkboxes = forwardRef((props, ref) => (
  <FormControl ref={ref} sx={{ padding: 2 }}>
    {props.options.map((option) => (
      <FormControlLabel
        label={option.charAt(0).toUpperCase() + option.slice(1)}
        name="rejectModules"
        value={option}
        checked={props.value.filter((v) => v === option).length > 0}
        onChange={props.onChange}
        disabled={props.disabled}
        control={<Checkbox value={option} />}
      />
    ))}
  </FormControl>
));

const CompanyApprovalDialogComponent = ({
  industries,
  selectedCompany,
  onCompanyApprovalDialogSubmitClick,
  onCompanyApprovalDialogCloseClick,
  plans,
  availableAccessRights,
  isFetching,
  setSelectedPlan,
  selectedPlan,
}) => {
  const classes = useStyles();

  if (!selectedCompany) {
    return null;
  }
  const rejectModuleOptions = ["user", "company"];
  if (selectedCompany.owner.registrationType === 1) {
    rejectModuleOptions.push("plan");
  }

  const selectedIds = availableAccessRights.reduce((list, group) => {
    group.subgroup.forEach((s) => {
      s.details.forEach((d) => {
        if (d.is_enabled) {
          list.push(d.id);
        }
      });
    });
    return list;
  }, []);

  return (
    <Dialog
      open={!!selectedCompany}
      onClose={onCompanyApprovalDialogCloseClick}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
    >
      <Formik
        initialValues={{
          approval: "approve",
          remarks: "",
          rejectModules: [],
          plan: selectedPlan,
          selectedIds,
        }}
        validationSchema={Yup.object().shape({
          approval: Yup.string()
            .oneOf(["approve", "reject", "suspend"])
            .required("Approval is required"),
          remarks: Yup.string().when("approval", {
            is: (approval) => approval !== "approve",
            then: Yup.string().required("Remarks is a required field"),
          }),
          rejectModules: Yup.array().when("approval", {
            is: (approval) => approval === "reject",
            then: Yup.array()
              .of(Yup.string())
              .min(1, "Reject modules field must have at least 1 items"),
          }),
        })}
        enableReinitialize
        onSubmit={onCompanyApprovalDialogSubmitClick}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="scroll-dialog-title">
              {selectedCompany.name}
              &nbsp;Approval
            </DialogTitle>
            <DialogContent dividers className={classes.content}>
              <Typography
                variant="subtitle2"
                sx={{
                  pb: 2,
                }}
              >
                Owner Details
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="First Name"
                    name="first_name"
                    value={selectedCompany.owner.firstName}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="last_name"
                    value={selectedCompany.owner.lastName}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Contact"
                    name="owner_contact"
                    value={selectedCompany.owner.contact}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="owner_email"
                    value={selectedCompany.owner.email}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Timezone"
                    name="owner_time_zone"
                    value={selectedCompany.owner.timezone}
                    variant="outlined"
                    disabled
                  />
                </Grid>
              </Grid>
              <Typography
                variant="subtitle2"
                sx={{
                  pt: 2,
                  pb: 2,
                }}
              >
                Company Details
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Company Name"
                    name="company_name"
                    value={selectedCompany.company.name}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Registration No."
                    name="reg_no"
                    value={selectedCompany.company.regNo}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Industry"
                    name="industry"
                    value={
                      industries.filter(
                        (i) =>
                          Number(i.id) ===
                          Number(selectedCompany.company.industry)
                      )[0].name
                    }
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={selectedCompany.company.email}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Contact"
                    name="contact"
                    value={selectedCompany.company.contact}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Line 1"
                    name="line1"
                    value={selectedCompany.company.addressLine1}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Line 2"
                    name="line2"
                    value={selectedCompany.company.addressLine2}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="City"
                    name="city"
                    value={selectedCompany.company.city}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="State"
                    name="state"
                    value={selectedCompany.company.state}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Country"
                    name="country"
                    value={selectedCompany.company.country}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Postal Code"
                    name="postal_code"
                    value={selectedCompany.company.postcode}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Article of Assoclation"
                    name="article_assoclation"
                    value="Article of Assoclation"
                    variant="outlined"
                    type="button"
                    onClick={() =>
                      window.open(
                        selectedCompany.articleOfAssoclationUrl,
                        "_blank"
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Director's Identification"
                    name="director_identification"
                    value="Director's Identification"
                    variant="outlined"
                    type="button"
                    onClick={() =>
                      window.open(
                        selectedCompany.directorIdentificationUrl,
                        "_blank"
                      )
                    }
                  />
                </Grid>
              </Grid>
              {selectedCompany.owner.registrationType === 1 ? (
                <>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      pt: 2,
                      pb: 2,
                    }}
                  >
                    Plan
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Name"
                        name="name"
                        value={selectedCompany.preSubscription.name}
                        variant="outlined"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Interval"
                        name="interval"
                        value={selectedCompany.preSubscription.interval}
                        variant="outlined"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Price"
                        name="price"
                        value={selectedCompany.preSubscription.price}
                        variant="outlined"
                        disabled
                      />
                    </Grid>
                  </Grid>
                </>
              ) : null}
              <Typography
                variant="subtitle2"
                sx={{
                  pt: 2,
                  pb: 2,
                }}
              >
                Approval
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Approval"
                    name="approval"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.approval && errors.approval}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.approval}
                    variant="outlined"
                    disabled={isSubmitting}
                    inputProps={{
                      style: {
                        backgroundColor: "white",
                      },
                    }}
                  >
                    <option value="approve">Approve</option>
                    <option value="reject">Reject</option>
                    <option value="suspend">Suspend</option>
                  </TextField>
                </Grid>
                {values.approval === "reject" ||
                values.approval === "suspend" ? (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Remarks"
                      name="remarks"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.remarks}
                      helperText={touched.remarks && errors.remarks}
                      variant="outlined"
                      disabled={isSubmitting}
                    />
                  </Grid>
                ) : null}
                {values.approval === "reject" ? (
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      label="Reject Module"
                      name="rejectModules"
                      fullWidth
                      value={values.rejectModules}
                      onChange={async (e) => {
                        await handleChange(e);
                        setFieldTouched("rejectModules", true, true);
                      }}
                      disabled={isSubmitting}
                      helperText={touched.rejectModules && errors.rejectModules}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        inputComponent: (props) => (
                          <Checkboxes
                            options={rejectModuleOptions}
                            {...props}
                          />
                        ),
                      }}
                    />
                  </Grid>
                ) : null}
                {values.approval !== "reject" &&
                values.approval !== "suspend" &&
                (selectedCompany.registrationType === 2 ||
                  selectedCompany.registrationType === 3) ? (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Plan"
                      name="plan"
                      onChange={({ target }) => {
                        const data = JSON.parse(target.value);
                        setFieldValue("plan", data);
                        setSelectedPlan(data);
                      }}
                      onBlur={handleBlur}
                      helperText={touched.plan && errors.plan}
                      required
                      select
                      SelectProps={{ native: true }}
                      value={JSON.stringify(values.plan)}
                      variant="outlined"
                      disabled={isSubmitting}
                      inputProps={{ style: { textTransform: "capitalize" } }}
                    >
                      {plans.map((plan, i) => (
                        <option
                          value={JSON.stringify(plan)}
                          key={i}
                          style={{ textTransform: "capitalize" }}
                        >
                          {plan.name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                ) : null}
              </Grid>
              {values.approval !== "reject" &&
                values.approval !== "suspend" && (
                  <>
                    <Box py={3}>
                      <Divider />
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box flexGrow={1}>
                        <Typography
                          variant="body2"
                          className={classes.labelText}
                        >
                          <b>Assign access right</b>
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Box>
                          <Button
                            color="secondary"
                            variant="text"
                            onClick={() => {
                              const allRole = [];
                              availableAccessRights.forEach((groups) =>
                                groups.subgroup.forEach((subgroups) =>
                                  subgroups.details.forEach((detail) => {
                                    allRole.push(detail.id);
                                  })
                                )
                              );
                              setFieldValue("selectedIds", allRole);
                            }}
                            style={{ textTransform: "none", color: "#6AAF68" }}
                          >
                            Select All
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            color="secondary"
                            variant="text"
                            onClick={() => {
                              setFieldValue("selectedIds", []);
                            }}
                            style={{ textTransform: "none", color: "red" }}
                          >
                            Deselect All
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                    <Box className={classes.accessBox} mt={2}>
                      {isFetching ? (
                        <>
                          <Skeleton
                            variant="text"
                            animation="wave"
                            height={25}
                          />
                          <Skeleton
                            variant="text"
                            animation="wave"
                            height={25}
                          />
                          <Skeleton
                            variant="text"
                            animation="wave"
                            height={25}
                          />
                          <Skeleton
                            variant="text"
                            animation="wave"
                            height={25}
                          />
                        </>
                      ) : (
                        <Masonry container>
                          {availableAccessRights.map((field, index) => {
                            const disableSummaryAccordion = field.subgroup.some(
                              (element) =>
                                !values.selectedIds.includes(
                                  element.details[0].action
                                )
                            );
                            return (
                              <AccessRightAccordion
                                key={index}
                                label={field.group}
                                subgroup={field.subgroup}
                                selectedIds={values.selectedIds}
                                disableSummaryAccordion={
                                  disableSummaryAccordion
                                }
                                disabledCheckbox={isSubmitting}
                                selectAccessRight={(info, isChecked) => {
                                  let temp = [...values.selectedIds];
                                  if (isChecked) {
                                    temp.push(info.id);
                                  } else {
                                    temp = temp.filter((t) => t !== info.id);
                                  }
                                  setFieldValue("selectedIds", temp);
                                }}
                                selectGroup={(ids, isChecked) => {
                                  let temp = [...values.selectedIds];
                                  if (isChecked) {
                                    temp = temp.concat(ids);
                                  } else {
                                    temp = temp.filter((t) => !ids.includes(t));
                                  }
                                  temp = [...new Set(temp)];
                                  setFieldValue("selectedIds", temp);
                                }}
                              />
                            );
                          })}
                        </Masonry>
                      )}
                    </Box>
                  </>
                )}
            </DialogContent>
            <DialogActions sx={{ padding: 2 }}>
              <Button
                variant="contained"
                type="submit"
                disabled={isSubmitting || isFetching}
              >
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

CompanyApprovalDialogComponent.propTypes = {
  industries: PropTypes.array.isRequired,
  selectedCompany: PropTypes.object,
  onCompanyApprovalDialogSubmitClick: PropTypes.func.isRequired,
  onCompanyApprovalDialogCloseClick: PropTypes.func.isRequired,
};

export default CompanyApprovalDialogComponent;
