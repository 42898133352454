import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmailLogFilterComponent from './email-log-filter.component';
import {
  setPage,
  setStatusFilter,
  setTypeFilter,
  setDateRangeFilter,
} from '../../redux/email-log.slice';
import { getEmailTypeFilter } from '../../redux/email-log.action';

export default function EmailLogContainer() {
  const isFetching = false;

  const statusList = [
    'success',
    'failed',
    'sending',
    'pending',
  ];

  const dispatch = useDispatch();
  const filterType = useSelector((state) => state.emailLog.filterType);
  const filterStatus = useSelector((state) => state.emailLog.filterStatus);
  const filterDateRange = useSelector((state) => state.emailLog.filterDateRange);
  const typeList = useSelector((state) => state.emailLog.emailTypeDropdown);

  useEffect(() => {
    dispatch(getEmailTypeFilter());
  }, [dispatch]);

  const handleFilter = (values) => {
    dispatch(setPage(0));
    dispatch(setTypeFilter(values.type));
    dispatch(setStatusFilter(values.status));
    dispatch(setDateRangeFilter(values.dateRange));
  };

  const handleClear = () => {
    dispatch(setPage(0));
    dispatch(setTypeFilter(''));
    dispatch(setStatusFilter(''));
    dispatch(setDateRangeFilter([]));
  };

  return (
    <EmailLogFilterComponent
      typeList={typeList}
      statusList={statusList}
      isFetching={isFetching}
      filterState={{ type: filterType, status: filterStatus, dateRange: filterDateRange }}
      handleFilter={handleFilter}
      resetFilter={handleClear}
    />
  );
}
