import PropTypes from "prop-types";
import { Button, ListItem, Typography } from "@material-ui/core";

const DashboardSideBarNavItemComponent = ({ icon, title, active, onClick }) => (
  <ListItem
    disableGutters
    sx={{
      display: "flex",
      py: 0,
    }}
  >
    <Button
      sx={{
        display: "flex",
        fontWeight: "medium",
        justifyContent: "flex-start",
        letterSpacing: 0,
        py: 1.25,
        textTransform: "none",
        width: "100%",
        color: active ? "primary.main" : "text.secondary",
        "& svg": {
          mr: 1,
        },
      }}
      onClick={onClick}
    >
      {icon}
      <Typography variant="subtitle2" textAlign="start">
        {title}
      </Typography>
    </Button>
  </ListItem>
);

DashboardSideBarNavItemComponent.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DashboardSideBarNavItemComponent;
