import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectLoading, addAlert } from "src/modules/notification/redux";
import { getNodesWithWhiteLabel, putBranchWhiteLabel, removeBranchWhiteLabel } from "src/modules/companies/redux";
import ConfirmationDialogComponent from "src/components/Dialog/confirmation/confirmationDialog.component";
import CompanyManageBranchDialogComponent from './company-manage-branch-dialog.component';
import CompanyWhiteLabelDialog from "../company-white-label-dialog";
import { addBranchWhiteLabelCount, minusBranchWhiteLabelCount } from "../../../redux/slice";

export default function CompanyManageBranchDialogContainer({
  selectedCompany,
  onCompanyManageBranchDialogCloseClick,
}) {
  const companyBranch = useSelector((state) => state.companies.nodesWithWhiteLabel);
  const companyManageBranchPage = useSelector((state) => state.companies.companyManageBranchPage);
  const totalCompanyManageBranch = useSelector((state) => state.companies.totalCompanyManageBranch);
  const [selectedBranchWhiteLabel, setSelectedBranchWhiteLabel] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPageOption = [10, 25, 50];
  const [currentBranchPage, setCurrentBranchPage] = useState(0);
  const [confirmationDialogModal, setConfirmationDialogModal] = useState(false);

  const isRemoving = useSelector((state) =>
    selectLoading(state, removeBranchWhiteLabel.typePrefix)
  );

  const isLoading = useSelector((state) =>
    selectLoading(state, getNodesWithWhiteLabel.typePrefix)
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedCompany) {
      dispatch(
        getNodesWithWhiteLabel({
          id: selectedCompany.company.id,
          page: 0,
          length: rowsPerPage,
        })
      );
    }
  }, [selectedCompany, rowsPerPage]);

  const onCompanyManageBranchPageClick = (page) => {
    if (companyManageBranchPage !== page) {
      setCurrentBranchPage(page);
      dispatch(
        getNodesWithWhiteLabel({
          id: selectedCompany.company.id,
          page,
          length: rowsPerPage,
        })
      );
    }
  };

  const handleItemPerPageChange = (value) => {
    setRowsPerPage(value);
  };

  const onBranchWhiteLabelClick = (company) => {
    setSelectedBranchWhiteLabel(company);
  };

  const onBranchWhiteLabelDialogSubmitClick = (values) => {
    dispatch(
      putBranchWhiteLabel({
        id: selectedBranchWhiteLabel.uuid,
        whiteLabel: values.whiteLabel,
      })
    )
    .then(unwrapResult)
    .then(() => {
      dispatch(
        getNodesWithWhiteLabel({
          id: selectedCompany.company.id,
          page: 0,
          length: rowsPerPage,
        })
      );
      if (!selectedBranchWhiteLabel.white_label) {
        dispatch(
          addBranchWhiteLabelCount(selectedCompany.company.row)
        );
      }
      dispatch(
        addAlert({
          severity: "success",
          message: "Node's white label updated successfully",
        })
      );
    })
    .finally(() => {
      setSelectedBranchWhiteLabel(null);
    });
  };

  const onBranchWhiteLabelDialogCloseClick = () => {
    setSelectedBranchWhiteLabel(null);
  };

  const closeConfirmationDialog = () => {
    setConfirmationDialogModal(false);
  };

  const setConfirmationDialog = () => {
    setConfirmationDialogModal(true);
  };

  const onRemoveBranchWhiteLabelClick = () => {
    setConfirmationDialogModal(false);
    dispatch(
      removeBranchWhiteLabel({
        id: selectedBranchWhiteLabel.uuid,
      })
    )
    .then(unwrapResult)
    .then(() => {
      dispatch(
        getNodesWithWhiteLabel({
          id: selectedCompany.company.id,
          page: 0,
          length: rowsPerPage,
        })
      );
      dispatch(
        minusBranchWhiteLabelCount(selectedCompany.company.row)
      );
      dispatch(
        addAlert({
          severity: "success",
          message: "Node's white label removed successfully",
        })
      );
    })
    .finally(() => {
      setSelectedBranchWhiteLabel(null);
    });
  };

  return (
    <>
      <CompanyManageBranchDialogComponent
        selectedCompany={selectedCompany}
        onCompanyManageBranchDialogCloseClick={onCompanyManageBranchDialogCloseClick}
        isCompanyManageBranchLoading={isLoading}
        companyBranch={companyBranch}
        totalCompanyManageBranch={totalCompanyManageBranch}
        companyManageBranchPage={companyManageBranchPage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOption={rowsPerPageOption}
        handleItemPerPageChange={handleItemPerPageChange}
        onCompanyManageBranchPageClick={onCompanyManageBranchPageClick}
        onBranchWhiteLabelClick={onBranchWhiteLabelClick}
      />
      <CompanyWhiteLabelDialog
        selectedCompany={selectedBranchWhiteLabel}
        onCompanyWhiteLabelDialogSubmitClick={onBranchWhiteLabelDialogSubmitClick}
        onCompanyWhiteLabelDialogCloseClick={onBranchWhiteLabelDialogCloseClick}
        onRemoveBranchWhiteLabelClick={setConfirmationDialog}
        isRemoving={isRemoving}
      />
      <ConfirmationDialogComponent
        isOpen={confirmationDialogModal}
        handleClose={closeConfirmationDialog}
        handleProceed={onRemoveBranchWhiteLabelClick}
        title="Are you sure to remove the white label?"
      />
    </>
  );
}
