import CompanyApprovalDialogComponent from './company-dialog.component';

export default function CompanyDialogContainer({
  industries,
  selectedCompany,
  onCompanyDialogCloseClick,
}) {
  return (
    <CompanyApprovalDialogComponent
      industries={industries}
      selectedCompany={selectedCompany}
      onCompanyDialogCloseClick={onCompanyDialogCloseClick}
    />
  );
}
