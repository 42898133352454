import React from 'react';
import { useSelector } from 'react-redux';
import PieChartScanValidityComponent from './pie-chart-scan-validity.component';

const PieChartScanValidityContainer = () => {
  const pieChartData = useSelector((state) => state.companyDashboard.pieChartData.data);
  const isLoading = useSelector((state) => state.companyDashboard.pieChartData.isLoading);
  const error = useSelector((state) => state.companyDashboard.pieChartData.error);

  return (
    <PieChartScanValidityComponent
      data={pieChartData}
      isLoading={isLoading}
      error={error}
    />
  );
};

export default PieChartScanValidityContainer;
