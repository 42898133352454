import axios from "axios";

export default function getTransactionReport({ uuid }) {
  return new Promise((resolve, reject) =>
    axios
      .get(`/campaignV2/transactionReport/getReportDetails?id=${uuid}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.message))
  );
}
