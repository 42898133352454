import React from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Container,
  Card,
  Typography,
  TableCell,
  Button,
  Input,
  Divider,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Formik } from "formik";
import SearchableSelect from "src/components/SearchableSelect";
import DataTable from "../../../../components/DataTable";
import CompanyApprovalDialog from "../../components/dialogs/company-approval-dialog";

const useStyle = makeStyles((theme) => ({
  filterMainContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
  filterFieldContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    rowGap: theme.spacing(2),
    columnGap: theme.spacing(0.5),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  filterItem: {
    minWidth: '170px',
    maxWidth: '200px',
    columnGap: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#eceff0',
    borderRadius: '4px',
    height: '34px',
    minWidth: '250px',
    maxWidth: '3000px',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  inputRootProduct: {
    paddingTop: '8px !important',
    paddingBottom: '7px !important',
    paddingLeft: '3px !important',
    paddingRight: '5px !important',
  },
  typePaperRoot: {
    maxWidth: '370px',
    width: '370px',
  },
  searchInput: {
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '& input:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 30px #eceff0 inset !important',
      WebkitTextFillColor: theme.palette.text.primary,
    },
  },
  divider: {
    height: '70%',
    backgroundColor: theme.palette.divider,
    margin: theme.spacing(0, 1),
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  button: {
    minWidth: '120px',
  },
  marginLeft: {
    marginLeft: theme.spacing(2),
  },
}));

const ApprovalsPage = ({
  industries,
  isCompaniesLoading,
  companies,
  totalCompanies,
  companiesPage,
  selectedCompany,
  onCompanyClick,
  onCompanyApprovalDialogSubmitClick,
  onCompanyApprovalDialogCloseClick,
  onCompaniesPageClick,
  handleItemPerPageChange,
  itemPerPage,
  plans,
  typeList,
  statusList,
  isFetching,
  filterState,
  handleFilter,
  resetFilter,
}) => {
  const classes = useStyle();
  const typeSearchable = false;
  const statusSearchable = false;

  return (
    <>
      <Helmet>
        <title>Approvals | Kood Asia Management Portal</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Card
            sx={{
              mb: 2,
            }}
          >
            <Typography
              variant="h4"
              sx={{
                padding: 2,
              }}
            >
              Approvals
            </Typography>
          </Card>
          <Box className={classes.filterMainContainer}>
            <Formik
              initialValues={{
                type: filterState.type || "Company Name",
                searchValue: filterState.searchValue || '',
                status: filterState.status,
              }}
              enableReinitialize
              onSubmit={(values, actions) => {
                handleFilter(values);
                actions.setSubmitting(false);
              }}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <Box className={classes.filterFieldContainer}>
                    <Box className={classes.filterContainer}>
                      <Box className={classes.filterItem}>
                        <SearchableSelect
                          value={formik.values.type}
                          handleChange={(value) => {
                            formik.setFieldValue('type', value);
                          }}
                          disabled={formik.isSubmitting}
                          searchable={typeSearchable}
                          style={{ height: '34px' }}
                          panelStyle={{ width: '249px' }}
                          PaperProps={{
                            classes: { root: classes.typePaperRoot },
                          }}
                          dropdownItem={typeList.map((item) => item.label)}
                          emptyMessage="Not found"
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          SelectProps={{
                            classes: { root: classes.inputRootProduct },
                          }}
                          isEmpty={formik.values.type === ''}
                          returnType="object"
                        />
                      </Box>
                      <Divider className={classes.divider} orientation="vertical" />
                      <Input
                        hiddenlabel="true"
                        variant="filled"
                        size="small"
                        placeholder="Enter"
                        className={classes.searchInput}
                        disableUnderline
                        classes={{ root: classes.inputRootProduct }}
                        inputProps={{
                          disableUnderline: true,
                          margin: 'none',
                          style: {
                            paddingTop: '3px',
                            paddingBottom: '3px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            borderRadius: '4px',
                          },
                        }}
                        {...formik.getFieldProps('searchValue')}
                      />
                    </Box>
                    <Box className={`${classes.filterItem} ${classes.marginLeft}`}>
                      <SearchableSelect
                        value={formik.values.status}
                        handleChange={(value) => {
                          formik.setFieldValue('status', value);
                        }}
                        placeholder="Status"
                        disabled={formik.isSubmitting}
                        searchable={statusSearchable}
                        style={{ height: '34px' }}
                        panelStyle={{ width: '249px' }}
                        PaperProps={{
                          classes: { root: classes.typePaperRoot },
                        }}
                        dropdownItem={statusList.map((item) => item.label)}
                        emptyMessage="Not found"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        SelectProps={{
                          classes: { root: classes.inputRootProduct },
                        }}
                        isEmpty={formik.values.status === ''}
                        returnType="object"
                        isClearable
                        handleClear={() => {
                          formik.setFieldValue('status', '');
                        }}
                      />
                    </Box>
                    <Box className={`${classes.buttonContainer} ${classes.marginLeft}`}>
                      <Button
                        variant="text"
                        disableElevation
                        color="primary"
                        size="small"
                        onClick={() => {
                          resetFilter();
                          formik.resetForm();
                        }}
                        className={classes.button}
                      >
                        Clear
                      </Button>
                      <Button
                        disabled={isFetching}
                        variant="contained"
                        disableElevation
                        color="primary"
                        size="small"
                        type="submit"
                        className={classes.button}
                      >
                        Search
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
          <DataTable
            isLoading={isCompaniesLoading}
            rows={companies}
            rowsPerPage={itemPerPage}
            total={totalCompanies}
            page={companiesPage}
            renderHeader={() => (
              <>
                <TableCell>Company Name</TableCell>
                <TableCell>Registration Number</TableCell>
                <TableCell>Registration Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </>
            )}
            renderRow={(row) => (
              <>
                <TableCell>{row.company.name}</TableCell>
                <TableCell>{row.company.regNo}</TableCell>
                <TableCell>{row.company.createdAt}</TableCell>
                <TableCell>{row.company.status}</TableCell>
                <TableCell>
                  <Button variant="contained" onClick={() => onCompanyClick(row)}>
                    Approve/Reject
                  </Button>
                </TableCell>
              </>
            )}
            onPageClick={onCompaniesPageClick}
            handleItemPerPageChange={handleItemPerPageChange}
          />
        </Container>
        <CompanyApprovalDialog
          plans={plans}
          industries={industries}
          selectedCompany={selectedCompany}
          onCompanyApprovalDialogSubmitClick={onCompanyApprovalDialogSubmitClick}
          onCompanyApprovalDialogCloseClick={onCompanyApprovalDialogCloseClick}
        />
      </Box>
    </>
  );
};

ApprovalsPage.propTypes = {
  industries: PropTypes.array.isRequired,
  isCompaniesLoading: PropTypes.bool.isRequired,
  companies: PropTypes.array.isRequired,
  totalCompanies: PropTypes.number.isRequired,
  companiesPage: PropTypes.number.isRequired,
  selectedCompany: PropTypes.object,
  onCompanyClick: PropTypes.func.isRequired,
  onCompanyApprovalDialogSubmitClick: PropTypes.func.isRequired,
  onCompanyApprovalDialogCloseClick: PropTypes.func.isRequired,
  onCompaniesPageClick: PropTypes.func.isRequired,
  handleItemPerPageChange: PropTypes.func.isRequired,
  itemPerPage: PropTypes.number.isRequired,
  typeList: PropTypes.array.isRequired,
  statusList: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  filterState: PropTypes.object.isRequired,
  handleFilter: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
};

export default ApprovalsPage;
