import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { addAlert, selectLoading } from "src/modules/notification/redux";
import { updateUnassign } from "src/modules/companies/redux";
import UnassignPanelComponent from "./unassignPanel.component";

export default function UnassignPanelContainer({
  selectedCompany,
  onSerialNumberAssignDialogCloseClick,
}) {
  const dispatch = useDispatch();
  const isUpdating = useSelector((state) =>
    selectLoading(state, updateUnassign.typePrefix)
  );
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [code, setCode] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const handleBranchChange = (field) => {
    setSelectedBranch(field);
  };

  const handleCodeEnter = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    setCode(newValue.split('\n'));
  };

  const handleSubmit = () => {
    dispatch(
      updateUnassign({
        nodeUuid: selectedBranch.node_uuid,
        qrCodes: code
      })
    )
      .then(unwrapResult)
      .then(() => {
        dispatch(
          addAlert({
            severity: "success",
            message: "Serial Number Unassigned successfully",
          })
        );
        onSerialNumberAssignDialogCloseClick(true);
      });
  };

  return (
    <UnassignPanelComponent
      selectedCompany={selectedCompany}
      onSerialNumberAssignDialogCloseClick={onSerialNumberAssignDialogCloseClick}
      isUpdating={isUpdating}
      handleBranchChange={handleBranchChange}
      selectedBranch={selectedBranch}
      inputValue={inputValue}
      handleCodeEnter={handleCodeEnter}
      handleSubmit={handleSubmit}
    />
  );
}
