import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MainLayoutComponent from './MainLayout.component';

export default function MainLayoutContainer() {
  const navigate = useNavigate();
  const location = useLocation();
  const alerts = useSelector((state) => state.alerts.alerts);
  const isAuthenticated = useSelector((state) => state.session.accessToken !== '');

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/admin');
    }
  }, [navigate, location, isAuthenticated]);
  return (
    <MainLayoutComponent
      alerts={alerts}
    />
  );
}
