import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DashboardPage from './company-dashboard.page';
import {
  getBranchList,
  getMapScanCount,
  getGraphScanCount,
  getPieChartScanValidity,
  getTableCountryScanCount,
  getTableBranchScanCount,
  getGraphQR,
  getTableDailyGeneratedQRCount,
  getTableDailyAssignedQRCount,
} from '../../redux';

export default function CompanyDashboardContainer() {
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const branchList = useSelector((state) => state.companyDashboard.branchList.data);
  const [branchListFilter, setBranchListFilter] = useState([]);
  const [dateRangeFilter, setDateRangeFilter] = useState({
    start: moment().subtract(13, 'days').toDate(),
    end: moment().toDate()
  });
  const initialFilter = {
    branchListFilter,
    dateRangeFilter,
  };

  useEffect(() => {
    dispatch(getBranchList({ companyUuid: companyId }));
  }, []);

  const fetchData = () => {
    if (!branchList.length) return;
    const filters = {
      companyUuid: companyId,
      branches: branchListFilter.length ? branchListFilter : branchList.map((b) => b.id),
      ...dateRangeFilter
    };
    dispatch(getMapScanCount(filters));
    dispatch(getGraphScanCount(filters));
    dispatch(getPieChartScanValidity(filters));
    dispatch(getTableBranchScanCount(filters));
    dispatch(getTableCountryScanCount(filters));
    dispatch(getGraphQR(filters));
    dispatch(getTableDailyGeneratedQRCount(filters));
    dispatch(getTableDailyAssignedQRCount(filters));
  };

  useEffect(fetchData, [branchList]);

  return (
    <DashboardPage
      initialFilter={initialFilter}
      setDateRangeFilter={setDateRangeFilter}
      branchListFilter={branchListFilter}
      setBranchListFilter={setBranchListFilter}
      handleSearch={fetchData}
    />
  );
}
