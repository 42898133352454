import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { addAlert, selectLoading } from "src/modules/notification/redux";
import { pushAlerts } from "src/configs/redux/alerts/alerts.slice";
import {
  getAccessRights,
  updateAccessRights,
} from "src/modules/companies/redux/action/companies.action";
import CompanyAccessRightDialogComponent from "./company-access-right-dialog.component";

export default function CompanyAccessRightDialogContainer({
  selectedCompany,
  onCompanyAccessRightDialogCloseClick,
}) {
  const dispatch = useDispatch();
  const accessRights = useSelector(
    (state) => state.companies.accessRights.list
  );
  const selectedIds = useSelector(
    (state) => state.companies.accessRights.selectedIds
  );
  const isError = useSelector((state) => state.companies.accessRights.isError);
  const isLoading = useSelector((state) =>
    selectLoading(state, getAccessRights.typePrefix)
  );
  const isUpdating = useSelector((state) =>
    selectLoading(state, updateAccessRights.typePrefix)
  );

  const getData = () => {
    dispatch(getAccessRights(selectedCompany.id));
  };

  useEffect(() => {
    if (selectedCompany) {
      getData();
    }
  }, [selectedCompany]);

  const handleSubmit = (values) => {
    dispatch(
      updateAccessRights({
        uuid: selectedCompany.id,
        accessRights: values.selectedIds,
      })
    )
      .then(unwrapResult)
      .then(() => {
        dispatch(
          addAlert({
            severity: "success",
            message: "Access rights updated successfully",
          })
        );
      });
  };

  return (
    <CompanyAccessRightDialogComponent
      accessRights={accessRights}
      isError={isError}
      selectedCompany={selectedCompany}
      selectedIds={selectedIds}
      handleSubmit={handleSubmit}
      onCompanyAccessRightDialogCloseClick={
        onCompanyAccessRightDialogCloseClick
      }
      isLoading={isLoading}
      isUpdating={isUpdating}
    />
  );
}
