import DashboardNavbarComponent from './DashboardNavbar.component';

export default function DashboardNavbarContainer({
  onDrawerToggle,
}) {
  return (
    <DashboardNavbarComponent
      onDrawerToggle={onDrawerToggle}
    />
  );
}
