import React from "react";
import {
  Dialog,
  Box,
  Tab,
  Tabs,
  Typography,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/CloseRounded";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import AssignPanel from "../../Panel/assignPanel";
import UnassignPanel from "../../Panel/unassignPanel";

const useStyles = makeStyles((theme) => ({
  accessBox: {
    display: "flex",
    flexFlow: "column",
  },
  scrollContainer: {
    maxHeight: "70vh",
    overflowY: "auto",
    backgroundColor: "#f3f3f3",
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    fontSize: "0.875rem",
    fontWeight: 500
  },
  header: {
    position: "sticky",
    top: 0,
    width: "100%",
    backgroundColor: "white",
    paddingTop: "4px",
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid lightgray",
  },
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main
  },
  scroll: {
    width: "min-content"
  }
}));

export default function CompanySerialNumberAssignDialogComponent({
  selectedCompany,
  onSerialNumberAssignDialogCloseClick,
  activeIndex,
  tabLabels,
  handleTabChange,
}) {
  const classes = useStyles();

  if (!selectedCompany) {
    return null;
  }

  function TabPanel({ children, value, index, ...other }) {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  return (
    <Dialog
      open={!!selectedCompany}
      onClose={onSerialNumberAssignDialogCloseClick}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
      PaperProps={{
        style: {
          maxWidth: activeIndex === 0 ? 1200 : 600,
        }
      }}
    >
      <Box className={classes.header}>
        <Tabs
          value={activeIndex}
          onChange={handleTabChange}
          aria-label="scrollable auto tabs example"
          textColor="primary"
          variant="scrollable"
          indicatorColor="primary"
          classes={{
            indicator: classes.indicator,
            scrollButtons: classes.scroll
          }}
        >
          {tabLabels.map((label, index) =>
            <Tab label={label} key={index} />
          )}
        </Tabs>

        <IconButton
          size="small"
          color="inherit"
          onClick={onSerialNumberAssignDialogCloseClick}
          style={{ marginRight: "15px" }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box>
        <TabPanel value={activeIndex} index={0}>
          <AssignPanel
            selectedCompany={selectedCompany}
            onSerialNumberAssignDialogCloseClick={onSerialNumberAssignDialogCloseClick}
          />
        </TabPanel>

        <TabPanel value={activeIndex} index={1}>
          <UnassignPanel
            selectedCompany={selectedCompany}
            onSerialNumberAssignDialogCloseClick={onSerialNumberAssignDialogCloseClick}
          />
        </TabPanel>
      </Box>
    </Dialog>
  );
}
