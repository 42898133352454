import signIn from './signIn.api';
import checkSession from './checkSession.api';
import signOut from './signOut.api';

const authApi = {
  signIn,
  checkSession,
  signOut,
};

export default authApi;
