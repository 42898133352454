import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Card,
  Typography,
  TableCell,
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import DataTable from '../../../../components/DataTable';
import NewPartnerAndResellerDialog from '../../components/dialogs/new-partner-and-reseller-dialog';

const PartnersAndResellersPage = ({
  isPartnersAndResellersLoading,
  partnersAndResellers,
  partnersAndResellersPage,
  totalPartnersAndResellers,
  onRegistrationLinkCopyClick,
  onPartnersAndResellersPageClick,
  isNewPartnerAndResellerDialogOpen,
  onPartnerAndResellerAddClick,
  onNewPartnerAndResellerDialogSubmitClick,
  onNewPartnerAndResellerDialogCloseClick,
}) => (
  <>
    <Helmet>
      <title>Partners & Resellers | Kood Asia Management Portal</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Card
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
          sx={{
            mb: 2,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              padding: 2,
            }}
          >
            Partners/Resellers
          </Typography>
          <Button
            sx={{
              margin: 2,
            }}
            variant="contained"
            onClick={onPartnerAndResellerAddClick}
          >
            Add
          </Button>
        </Card>
        <DataTable
          isLoading={isPartnersAndResellersLoading}
          rows={partnersAndResellers}
          rowPerPage={10}
          total={totalPartnersAndResellers}
          page={partnersAndResellersPage}
          renderHeader={() => (
            <>
              <TableCell>
                Name
              </TableCell>
              <TableCell>
                Email
              </TableCell>
              <TableCell>
                Type
              </TableCell>
              <TableCell>
                Status
              </TableCell>
              <TableCell>
                Action
              </TableCell>
            </>
          )}
          renderRow={(row) => (
            <>
              <TableCell>
                {row.name}
              </TableCell>
              <TableCell>
                {row.email}
              </TableCell>
              <TableCell>
                {row.type}
              </TableCell>
              <TableCell>
                {row.status}
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  onClick={() => onRegistrationLinkCopyClick(row.customer_invitation_url.a)}
                >
                  Copy Plan A Link
                </Button>
                <Button
                  variant="contained"
                  onClick={() => onRegistrationLinkCopyClick(row.customer_invitation_url.b)}
                >
                  Copy Plan B Link
                </Button>
              </TableCell>
            </>
          )}
          onPageClick={onPartnersAndResellersPageClick}
        />
        <NewPartnerAndResellerDialog
          isNewPartnerAndResellerDialogOpen={isNewPartnerAndResellerDialogOpen}
          onNewPartnerAndResellerDialogSubmitClick={onNewPartnerAndResellerDialogSubmitClick}
          onNewPartnerAndResellerDialogCloseClick={onNewPartnerAndResellerDialogCloseClick}
        />
      </Container>
    </Box>
  </>
);

PartnersAndResellersPage.propTypes = {
  isPartnersAndResellersLoading: PropTypes.bool.isRequired,
  partnersAndResellers: PropTypes.array.isRequired,
  partnersAndResellersPage: PropTypes.number.isRequired,
  totalPartnersAndResellers: PropTypes.number.isRequired,
  onRegistrationLinkCopyClick: PropTypes.func.isRequired,
  onPartnersAndResellersPageClick: PropTypes.func.isRequired,
  isNewPartnerAndResellerDialogOpen: PropTypes.bool.isRequired,
  onPartnerAndResellerAddClick: PropTypes.func.isRequired,
  onNewPartnerAndResellerDialogSubmitClick: PropTypes.func.isRequired,
  onNewPartnerAndResellerDialogCloseClick: PropTypes.func.isRequired,
};

export default PartnersAndResellersPage;
