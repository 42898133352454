import { setLoading, addAlert } from "src/modules/notification/redux";
import {
  getTransactionReports,
  getTransactionReport, getNotificationSettings, setNotificationSettings,
} from "../action";

const getTransactionReportsMiddleware =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);

        const { payload, type } = action;

        switch (type) {
          case getTransactionReports.pending.type:
            dispatch(
              setLoading({ id: getTransactionReports.typePrefix, state: true })
            );
            break;
          case getTransactionReports.fulfilled.type:
            dispatch(
              setLoading({ id: getTransactionReports.typePrefix, state: false })
            );
            break;
          case getTransactionReports.rejected.type:
            dispatch(
              setLoading({ id: getTransactionReports.typePrefix, state: false })
            );
            dispatch(
              addAlert({
                severity: "error",
                message: payload.code,
              })
            );
            break;
          default:
            break;
        }
      };

const getTransactionReportMiddleware =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);

        const { payload, type } = action;

        switch (type) {
          case getTransactionReport.pending.type:
            dispatch(
              setLoading({ id: getTransactionReport.typePrefix, state: true })
            );
            break;
          case getTransactionReport.fulfilled.type:
            dispatch(
              setLoading({ id: getTransactionReport.typePrefix, state: false })
            );
            break;
          case getTransactionReport.rejected.type:
            dispatch(
              setLoading({ id: getTransactionReport.typePrefix, state: false })
            );
            dispatch(
              addAlert({
                severity: "error",
                message: payload.code,
              })
            );
            break;
          default:
            break;
        }
      };

const getNotificationSettingMiddleware =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);

        const { payload, type } = action;

        switch (type) {
          case getNotificationSettings.pending.type:
            dispatch(
              setLoading({ id: getNotificationSettings.typePrefix, state: true })
            );
            break;
          case getNotificationSettings.fulfilled.type:
            dispatch(
              setLoading({ id: getNotificationSettings.typePrefix, state: false })
            );
            break;
          case getNotificationSettings.rejected.type:
            dispatch(
              setLoading({ id: getNotificationSettings.typePrefix, state: false })
            );
            dispatch(
              addAlert({
                severity: "error",
                message: payload.code,
              })
            );
            break;
          default:
            break;
        }
      };

const setNotificationSettingMiddleware =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);

        const { payload, type } = action;

        switch (type) {
          case setNotificationSettings.pending.type:
            dispatch(
              setLoading({ id: setNotificationSettings.typePrefix, state: true })
            );
            break;
          case setNotificationSettings.fulfilled.type:
            dispatch(
              setLoading({ id: setNotificationSettings.typePrefix, state: false })
            );
            dispatch(
              addAlert({
                severity: "success",
                message: "Setting updated successfully",
              })
            );
            break;
          case setNotificationSettings.rejected.type:
            dispatch(
              setLoading({ id: setNotificationSettings.typePrefix, state: false })
            );
            dispatch(
              addAlert({
                severity: "error",
                message: payload.code,
              })
            );
            break;
          default:
            break;
        }
      };

export default [
  getTransactionReportsMiddleware,
  getTransactionReportMiddleware,
  getNotificationSettingMiddleware,
  setNotificationSettingMiddleware
];
