import React from 'react';
import { useSelector } from 'react-redux';
import TableDailyGeneratedQRCountComponent from './table-daily-generated-qr.component';

function TableDailyGeneratedQRCountContainer() {
  const data = useSelector((state) => state.companyDashboard.tableDailyGeneratedQRCount.data);
  const isLoading = useSelector((state) => state.companyDashboard.tableDailyGeneratedQRCount.isLoading);
  const error = useSelector((state) => state.companyDashboard.tableDailyGeneratedQRCount.error);

  return (
    <TableDailyGeneratedQRCountComponent
      isLoading={isLoading}
      data={data}
      error={error}
    />
  );
}

export default TableDailyGeneratedQRCountContainer;
