import axios from 'axios';

export default function getCompanyUsers({
  companyId,
  companyPlan,
  page = 0,
  length = 10,
  query = '',
  searchColumn = '',
  status = '',
}) {
  return new Promise((resolve, reject) => axios
    .get(`/account/system-admin/api/v1/company/${companyId}/users?page=${page}&length=${length}&query=${query}&search_column=${searchColumn}&status=${status}`)
    .then((response) => resolve(response))
    .catch((error) => reject(error.message)));
}
