import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  Hidden,
  List,
} from '@material-ui/core';
import DashboardSideBarNavItem from '../DashboardSideBarNavItem';

const DashboardSidebarComponent = ({
  items,
  isDrawerOpen,
  onDrawerToggle,
}) => (
  <>
    <Hidden lgUp>
      <Drawer
        anchor="left"
        onClose={onDrawerToggle}
        open={isDrawerOpen}
        variant="temporary"
        PaperProps={{
          sx: {
            width: 256,
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <List>
            {items.map((item) => (
              <DashboardSideBarNavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
                active={item.active}
                onClick={item.onClick}
              />
            ))}
          </List>
        </Box>
      </Drawer>
    </Hidden>
    <Hidden mdDown>
      <Drawer
        anchor="left"
        open
        variant="persistent"
        PaperProps={{
          sx: {
            width: 256,
            top: 64,
            height: 'calc(100% - 64px)',
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <List>
            {items.map((item) => (
              <DashboardSideBarNavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
                active={item.active}
                onClick={item.onClick}
              />
            ))}
          </List>
        </Box>
      </Drawer>
    </Hidden>
  </>
);

DashboardSidebarComponent.propTypes = {
  items: PropTypes.array.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
};

export default DashboardSidebarComponent;
