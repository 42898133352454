import React from 'react';
import { useSelector } from 'react-redux';
import TableCountryScanCountComponent from './table-country-scan-count.component';

export default function TableCountryScanCountContainer() {
  const data = useSelector((state) => state.companyDashboard.tableCountryScanCount.data);
  const isLoading = useSelector((state) => state.companyDashboard.tableCountryScanCount.isLoading);
  const error = useSelector((state) => state.companyDashboard.tableCountryScanCount.error);

  return (
    <TableCountryScanCountComponent
      data={data}
      isLoading={isLoading}
      error={error}
    />
  );
}
