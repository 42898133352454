import {
  createAsyncThunk,
} from '@reduxjs/toolkit';
import industriesApi from '../../apis/industries';

export const getIndustries = createAsyncThunk(
  'constants/getIndustries',
  (payload, { rejectWithValue }) => industriesApi
    .getIndustries()
    .then((response) => response)
    .catch((error) => rejectWithValue(error)),
);
