import { setLoading, addAlert } from "src/modules/notification/redux";
import { getApprovalAvailableAccessRight } from "../action";

const getApprovalAvailableAccessRightMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case getApprovalAvailableAccessRight.pending.type:
        dispatch(
          setLoading({
            id: getApprovalAvailableAccessRight.typePrefix,
            state: true,
          })
        );
        break;
      case getApprovalAvailableAccessRight.fulfilled.type:
        dispatch(
          setLoading({
            id: getApprovalAvailableAccessRight.typePrefix,
            state: false,
          })
        );
        break;
      case getApprovalAvailableAccessRight.rejected.type:
        dispatch(
          setLoading({
            id: getApprovalAvailableAccessRight.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: payload.code,
          })
        );
        break;
      default:
        break;
    }
  };

export default [getApprovalAvailableAccessRightMiddleware];
