import axios from 'axios';

export default function updateAssign({ payload }) {
  return new Promise((resolve, reject) => axios
    .post('/distributor/system-admin/api/v1/reassign', {
      node_uuid: payload.nodeUuid,
      product_uuid: payload.productUuid,
      batch_id: payload.batchId,
      from: payload.fromSeq,
      to: payload.toSeq,
    })
    .then((response) => resolve(response.data))
    .catch((error) => reject(error.message))
  );
}
