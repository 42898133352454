import {
  Box,
  Container,
  MenuItem,
  Select,
  Card, Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import TngTransactionReportTable from "../../components/table/tngTransactionReportTable";

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    display: "grid",
    gridTemplateColumns: "9fr 1fr",
    columnGap: theme.spacing(2),
  },
}));

export default function TransactionReportsPage({
  handleProviderChange,
  onConfigureNotificationButtonClick,
  provider,
  providers,
}) {
  const classes = useStyles();

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          pt: 2,
          px: 2,
        }}
      >
        <Card>
          <Box p={1} display="flex" justifyContent="space-between">
            <Select
              value={provider}
              label="Provider"
              onChange={handleProviderChange}
              sx={{
                color: "black",
              }}
            >
              {providers.map((item) => (
                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
            <Button variant="contained" color="primary" onClick={onConfigureNotificationButtonClick}>
              Configure Notification
            </Button>
          </Box>
        </Card>
      </Box>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Container maxWidth={false}>
          {provider === "tng" && (
            <TngTransactionReportTable />
          )}
        </Container>
      </Box>
    </>
  );
}
