import { createSlice } from "@reduxjs/toolkit";
import {
  getApprovalAvailableAccessRight,
  getApprovals,
  getAvailablePlans,
} from "../action/approval.action";

const APPROVALS = "approvals";
const initialState = {
  totalCompanies: 0,
  companiesPage: 0,
  companies: [],
  plans: [],
  availableAccessRights: [],
  filterType: '',
  filterStatus: '',
};
const approvalsSlice = createSlice({
  name: APPROVALS,
  initialState,
  reducers: {
    clearCompanies: (state, action) => {
      state.totalCompanies = 0;
      state.companies = [];
    },
    setPage: (state, action) => {
      state.companiesPage = action.payload;
    },
    setTypeFilter: (state, action) => {
      state.filterType = action.payload;
    },
    setStatusFilter: (state, action) => {
      state.filterStatus = action.payload;
    },
  },
  extraReducers: {
    [getApprovals.fulfilled]: (state, action) => {
      state.totalCompanies = action.payload.recordsFiltered;
      state.companiesPage = action.meta.arg.page;
      const processApprovals = action.payload.data.map((approval) => {
        const processApproval = {};
        processApproval.registrationType = approval.registration_type || "";

        if (approval.owner) {
          processApproval.owner = {
            firstName: approval.owner.first_name || "",
            lastName: approval.owner.last_name || "",
            contact: approval.owner.phone_number || "",
            email: approval.owner.email || "",
            timezone: approval.owner.timezone || "",
            registrationType: approval.owner.registration_type || 1,
          };
        } else {
          processApproval.owner = {
            firstName: "",
            lastName: "",
            contact: "",
            email: "",
            timezone: "",
            registrationType: 1,
          };
        }
        processApproval.company = {
          id: approval.id || "",
          name: approval.name || "",
          addressLine1: approval.line1 || "",
          addressLine2: approval.contact || "",
          city: approval.city || "",
          country: approval.country || "",
          postcode: approval.postal_code || "",
          state: approval.state || "",
          regNo: approval.reg_no || "",
          status: approval.status || "",
          contact: approval.contact || "",
          email: approval.email || "",
          industry: approval.industry || "",
          createdAt: approval.created_at || "",
        };
        if (approval.article_assoclation) {
          processApproval.articleOfAssoclationUrl =
            approval.article_assoclation.url || "";
        }
        if (approval.director_identification) {
          processApproval.directorIdentificationUrl =
            approval.director_identification.url || "";
        }
        if (approval.pre_subscription) {
          processApproval.preSubscription = {
            name: approval.pre_subscription.name || "",
            interval: approval.pre_subscription.interval || "",
            price: approval.pre_subscription.cost || "",
          };
        } else {
          processApproval.preSubscription = {
            name: "",
            interval: "",
            price: "",
          };
        }
        return processApproval;
      });
      state.companies = processApprovals;
    },
    [getAvailablePlans.fulfilled]: (state, action) => {
      state.plans = action.payload;
    },
    [getApprovalAvailableAccessRight.fulfilled]: (state, action) => {
      state.availableAccessRights = initialState.availableAccessRights;
    },
    [getApprovalAvailableAccessRight.fulfilled]: (state, action) => {
      state.availableAccessRights = action.payload;
    },
  },
});

export const { clearCompanies, setPage, setTypeFilter, setStatusFilter } = approvalsSlice.actions;
export const approvalsReducer = approvalsSlice.reducer;
