import { Snackbar } from "@material-ui/core";

const AlertComponent = ({ alerts }) => {
  if (alerts && alerts.length > 0) {
    return alerts.map((alert) => (
      <Snackbar
        key={alert.timestamps}
        open
        ContentProps={{
          style: {
            background: "red",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        message={alert.message}
      />
    ));
  }
  return null;
};

export default AlertComponent;
