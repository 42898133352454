import NewPartnerAndResellerDialogComponent from './new-partner-and-reseller-dialog.component';

export default function NewPartnerAndResellerDialogContainer({
  isNewPartnerAndResellerDialogOpen,
  onNewPartnerAndResellerDialogSubmitClick,
  onNewPartnerAndResellerDialogCloseClick,
}) {
  return (
    <NewPartnerAndResellerDialogComponent
      isNewPartnerAndResellerDialogOpen={isNewPartnerAndResellerDialogOpen}
      onNewPartnerAndResellerDialogSubmitClick={onNewPartnerAndResellerDialogSubmitClick}
      onNewPartnerAndResellerDialogCloseClick={onNewPartnerAndResellerDialogCloseClick}
    />
  );
}
