import React from "react";
import PropTypes from "prop-types";
import GeneralDialogComponent from "./generalDialog.component";

function GeneralDialogContainer({
  isLoading,
  isOpen,
  handleClose,
  handleProceed,
  title,
  description,
  rejectOption = true,
  icon,
  iconStyle,
  titleStyle,
  descriptionStyle,
  type,
  proceedLabel,
  rejectLabel,
}) {
  return (
    <GeneralDialogComponent
      isLoading={isLoading}
      isOpen={isOpen}
      handleClose={handleClose}
      handleProceed={handleProceed}
      title={title}
      description={description}
      proceedLabel={proceedLabel || "Confirm"}
      rejectLabel={rejectLabel || "Cancel"}
      rejectOption={rejectOption}
      icon={icon}
      iconStyle={iconStyle}
      titleStyle={titleStyle}
      descriptionStyle={descriptionStyle}
      type={type}
    />
  );
}

export default GeneralDialogContainer;
