import DashboardSideBarNavItemComponent from './DashboardSideBarNavItem.component';

export default function DashboardSideBarNavItemContainer({
  icon,
  title,
  active,
  onClick,
}) {
  return (
    <DashboardSideBarNavItemComponent
      icon={icon}
      title={title}
      active={active}
      onClick={onClick}
    />
  );
}
