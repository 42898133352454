import React from "react";

import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";

export default function SnackBarComponent({
  isOpen,
  handleClose,
  notification,
}) {
  return (
    <Snackbar
      open={isOpen}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      onClose={handleClose}
      autoHideDuration={notification.severity === "success" ? 3500 : null}
    >
      <Alert
        onClose={handleClose}
        severity={notification.severity}
        elevation={6}
        variant="filled"
      >
        {notification.message}
      </Alert>
    </Snackbar>
  );
}
