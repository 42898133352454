import { Box, Button, Card, Typography, Grid } from '@material-ui/core';
import { Formik } from 'formik';
import { Helmet } from 'react-helmet';
import DateRangePicker from 'src/components/DateRangePicker';
import BranchSelect from '../../components/branch-select';
import MapScanCount from '../../card/map-scan-count';
import GraphScanCount from '../../card/graph-scan-count';
import PieChartScanValidity from '../../card/pie-chart-scan-validity';
import TableCountryScanCount from '../../card/table-country-scan-count';
import TableBranchScanCount from '../../card/table-branch-scan-count';
import GraphQR from '../../card/graph-qr';
import TableDailyGeneratedQRCount from '../../card/table-daily-generated-qr';
import TableDailyAssignedQRCount from '../../card/table-daily-assigned-qr';

export default function CompanyDashboardPage({
  initialFilter,
  setDateRangeFilter,
  branchListFilter,
  setBranchListFilter,
  handleSearch
}) {
  return (
    <>
    <Helmet>
      <title>Company Dashboard | Kood Asia Management Portal</title>
    </Helmet>
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: '#e5e5ea',
        p: 3
      }}
    >
      <Card
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h4" sx={{ p: 2 }}>Company Dashboard</Typography>
        <Formik
          initialValues={initialFilter}
          onSubmit={handleSearch}
        >
          {({
            values,
            isSubmitting,
            setSubmitting,
            handleSubmit
          }) => (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
                setSubmitting(false);
              }}
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <BranchSelect
                branchListFilter={branchListFilter}
                setBranchListFilter={setBranchListFilter}
              />
              <Box style={{ height: '100%' }}>
                <DateRangePicker
                  handleDateRangeChange={(start, end) => setDateRangeFilter({ start, end })}
                  defaultValue={values.dateRangeFilter}
                />
              </Box>
              <Button
                disabled={isSubmitting || !branchListFilter.length}
                variant="contained"
                disableElevation
                color="primary"
                size="small"
                type="submit"
                style={{
                  width: '75px', height: '40px', margin: 'auto 10px auto auto',
                }}
              >
                Search
              </Button>
            </form>
          )}
        </Formik>
      </Card>
      <MapScanCount />
      <GraphScanCount />
      <Grid container spacing={2} mt={2}>
        <Grid item sm={12} md={4}>
          <PieChartScanValidity />
        </Grid>
        <Grid item sm={12} md={4}>
          <TableCountryScanCount />
        </Grid>
        <Grid item sm={12} md={4}>
          <TableBranchScanCount />
        </Grid>
      </Grid>
      <GraphQR />
      <Grid container spacing={2} mt={2}>
        <Grid item sm={12} md={6}>
          <TableDailyGeneratedQRCount />
        </Grid>
        <Grid item sm={12} md={6}>
          <TableDailyAssignedQRCount />
        </Grid>
      </Grid>
    </Box>
    </>
  );
}
