import { createSlice } from '@reduxjs/toolkit';
import { getPartnersAndResellers } from './partners-and-resellers.action';

const PARTNERS_AND_RESELLERS = 'partnersAndResellers';
const initialState = {
  totalPartnersAndResellers: 0,
  partnersAndResellersPage: 0,
  partnersAndResellers: [],
};
const partnersAndResellersSlice = createSlice({
  name: PARTNERS_AND_RESELLERS,
  initialState,
  reducers: {
    clearPartnersAndResellers: (state, action) => {
      state.partnersAndResellers = [];
    },
  },
  extraReducers: {
    [getPartnersAndResellers.fulfilled]: (state, action) => {
      state.totalPartnersAndResellers = action.payload.data.recordsFiltered;
      state.partnersAndResellersPage = action.meta.arg.page;
      state.partnersAndResellers = action.payload.data.data;
    },
  },
});

export const {
  clearPartnersAndResellers,
} = partnersAndResellersSlice.actions;
export const partnersAndResellersReducer = partnersAndResellersSlice.reducer;
