import axios from "axios";

export default function getNodesWithWhiteLabel({
    id,
    page,
    length,
  }) {
  const start = page * length;
  return new Promise((resolve, reject) =>
    axios
      .get(`/account/system-admin/api/v1/company/${id}/nodes-with-white-label`,
      {
        params: {
          start,
          length,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.message))
  );
}
