import axios from 'axios';

const url = '/analytic/admin/api/v1/managementportal/get-all-dropdown';

const getAllCompanies = () => (
  new Promise((resolve, reject) => {
    axios
      .get(url, {}, { timeout: 15000 })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  })
);

export default getAllCompanies;
