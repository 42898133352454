import React from 'react';
import { useSelector } from 'react-redux';
import QrGeneratedAssignedGraphCardComponent from "./qrGeneratedAssignedGraphCard.component";

const QrGeneratedAssignedGraphCardContainer = () => {
  const lineChartData = useSelector((state) => state.dashboard.generatedAssignedGraph.data);
  const isLoading = useSelector((state) => state.dashboard.generatedAssignedGraph.isLoading);
  const error = useSelector((state) => state.dashboard.generatedAssignedGraph.error);

  return (
    <QrGeneratedAssignedGraphCardComponent
      data={lineChartData}
      isLoading={isLoading}
      error={error}
    />
  );
};

export default QrGeneratedAssignedGraphCardContainer;
