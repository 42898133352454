import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { addAlert, selectLoading } from "src/modules/notification/redux";
import { setReassignResult, updateAssign } from "src/modules/companies/redux";
import AssignPanelComponent from "./assignPanel.component";

export default function AssignPanelContainer({
  selectedCompany,
  onSerialNumberAssignDialogCloseClick,
}) {
  const dispatch = useDispatch();
  const isUpdating = useSelector((state) =>
    selectLoading(state, updateAssign.typePrefix)
  );
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);

  const handleOpenResultDialog = (value) => {
    dispatch(setReassignResult(value));
  };

  const handleSubmit = (formValue) => {
    const { branch, product, batch, from, to } = formValue;

    setIsBackdropOpen(true);

    dispatch(
      updateAssign({
        nodeUuid: branch.node_uuid,
        productUuid: product.product_uuid,
        batchId: batch.id,
        fromSeq: from,
        toSeq: to,
      })
    )
      .then(unwrapResult)
      .then((value) => {
        handleOpenResultDialog(value.failed_validate);
        dispatch(
          addAlert({
            severity: "success",
            message: "Serial Number assigned successfully",
          })
        );
        onSerialNumberAssignDialogCloseClick(true);
      })
      .finally(() => {
        setIsBackdropOpen(false);
      });
  };

  return (
    <>
      <AssignPanelComponent
        selectedCompany={selectedCompany}
        onSerialNumberAssignDialogCloseClick={onSerialNumberAssignDialogCloseClick}
        isUpdating={isUpdating}
        handleSubmit={handleSubmit}
        isBackdropOpen={isBackdropOpen}
      />
    </>
  );
}
