import {
  Outlet,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/styles';
import DashboardNavbar from '../DashboardNavbar';
import DashboardSidebar from '../DashboardSidebar';
import Alerts from '../Alerts';

const DashboardLayoutRoot = styled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  }),
);

const DashboardLayoutWrapper = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
  }),
);

const DashboardLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

const DashboardLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
});

const DashboardLayoutComponent = ({
  items,
  alerts,
  isDrawerOpen,
  onDrawerToggle,
}) => (
  <DashboardLayoutRoot>
    <DashboardNavbar
      onDrawerToggle={onDrawerToggle}
    />
    <DashboardSidebar
      items={items}
      isDrawerOpen={isDrawerOpen}
      onDrawerToggle={onDrawerToggle}
    />
    <DashboardLayoutWrapper>
      <DashboardLayoutContainer>
        <DashboardLayoutContent>
          <Outlet />
        </DashboardLayoutContent>
      </DashboardLayoutContainer>
    </DashboardLayoutWrapper>
    <Alerts
      alerts={alerts}
    />
  </DashboardLayoutRoot>
);

DashboardLayoutComponent.propTypes = {
  items: PropTypes.array.isRequired,
  alerts: PropTypes.array.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
};

export default DashboardLayoutComponent;
