/* eslint-disable no-nested-ternary */
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { addAlert, selectLoading } from "src/modules/notification/redux";
import { pushAlerts } from "src/configs/redux/alerts/alerts.slice";
import {
  getAccessRights,
  getCompanyPlanInfo,
  updateAccessRights,
  updateCompanyPlan,
} from "src/modules/companies/redux/action/companies.action";
import { getAvailablePlans } from "src/modules/approvals/redux/action";
import CompanySwapPlanDialogComponent from "./company-swap-plan-dialog.component";

export default function CompanySwapPlanDialogContainer({
  selectedCompany,
  onCompanySwapPlanDialogCloseClick,
}) {
  const dispatch = useDispatch();
  const planInfo = useSelector((state) => state.companies.planInfo);
  const plans = useSelector((state) => state.approvals.plans);

  const isLoading = useSelector((state) =>
    selectLoading(state, getCompanyPlanInfo.typePrefix)
  );
  const isUpdating = useSelector((state) =>
    selectLoading(state, updateCompanyPlan.typePrefix)
  );

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getAvailablePlans());
      dispatch(getCompanyPlanInfo(selectedCompany.id));
    }
  }, [selectedCompany]);

  const handleSubmit = (values) => {
    dispatch(
      updateCompanyPlan({
        id: selectedCompany.id,
        plan: values.plan ? values.plan : plans.length ? plans[0].uuid : "",
        need_access_right_update: values.need_access_right_update,
      })
    )
      .then(unwrapResult)
      .then(() => {
        dispatch(
          addAlert({
            severity: "success",
            message: "Plan updated successfully",
          })
        );
        onCompanySwapPlanDialogCloseClick(true);
      });
  };

  return (
    <CompanySwapPlanDialogComponent
      planInfo={planInfo}
      selectedCompany={selectedCompany}
      handleSubmit={handleSubmit}
      onCompanySwapPlanDialogCloseClick={onCompanySwapPlanDialogCloseClick}
      isLoading={isLoading}
      isUpdating={isUpdating}
      plans={plans}
    />
  );
}
