import {
  Box,
  Paper,
  InputBase,
  Container,
  Card,
  TableCell,
  Button,
  Table,
} from "@material-ui/core";
import { Formik } from "formik";
import { makeStyles } from "@material-ui/styles";
import ActionDetailButton from "src/components/ActionButton";
import DataTable from "../../../../components/DataTable";

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    display: "grid",
    gridTemplateColumns: "9fr 1fr",
    columnGap: theme.spacing(2),
  },
}));

export default function DisbursementPage({
  disbursements,
  isLoading,
  totalFiltered,
  tableFilter,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearch,

  onUpdateCreditBalance,
  onUpdateTransactionLimit,
  onAddCompany,
}) {
  const classes = useStyles();

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Container maxWidth={false}>
          <Box
            display="flex"
            alignItems="center"
            mb={2}
            className={classes.filterContainer}
          >
            <Card
              sx={{
                display: "flex",
                flex: 1,
              }}
            >
              <Formik
                initialValues={{
                  search: tableFilter.search,
                }}
                enableReinitialize
                onSubmit={handleSearch}
              >
                {(formik) => (
                  <Paper
                    component="form"
                    onSubmit={formik.handleSubmit}
                    sx={{
                      p: "2px 6px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <InputBase
                      sx={{ flex: 1 }}
                      placeholder="Search..."
                      onChange={(event) =>
                        formik.setFieldValue("search", event.target.value)
                      }
                      value={formik.values.search}
                    />
                    <Button
                      sx={{
                        height: 38,
                        fontSize: "1.5rem",
                        backgroundColor: "#d7d7d7",
                        ":hover": {
                          bgcolor: "primary.main",
                          color: "white",
                        },
                      }}
                      type="submit"
                      aria-label="search"
                    >
                      🔍︎
                    </Button>
                  </Paper>
                )}
              </Formik>
            </Card>
            <Button variant="contained" color="primary" onClick={onAddCompany}>
              Add
            </Button>
          </Box>
          <DataTable
            isLoading={isLoading}
            rows={disbursements}
            rowsPerPage={tableFilter.rowsPerPage}
            total={totalFiltered}
            page={tableFilter.page}
            renderHeader={() => (
              <>
                <TableCell>Company Name</TableCell>
                <TableCell>Provider Name</TableCell>
                <TableCell>Currency</TableCell>
                <TableCell>Balance</TableCell>
                <TableCell>Transaction Limit</TableCell>
                <TableCell>Action</TableCell>
              </>
            )}
            renderRow={(row, index) => {
              const actionItems = [
                {
                  label: "Update credit balance",
                  action: () => onUpdateCreditBalance(row),
                },
                {
                  label: "Update transaction limit",
                  action: () => onUpdateTransactionLimit(row),
                },
              ];

              return (
                <>
                  <TableCell>{row.company_name}</TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      {row.provider_name}
                    </Box>
                  </TableCell>
                  <TableCell>{row.currency}</TableCell>
                  <TableCell>{row.balance}</TableCell>
                  <TableCell>{row.transaction_limit}</TableCell>
                  <TableCell>
                    <ActionDetailButton
                      actionItems={actionItems}
                      popperStyle={{ zIndex: 999 }}
                    />
                  </TableCell>
                </>
              );
            }}
            onPageClick={handleChangePage}
            handleItemPerPageChange={handleChangeRowsPerPage}
          />
        </Container>
      </Box>
    </>
  );
}
