import axios from "axios";

export default function getAvailablePlans() {
  return new Promise((resolve, reject) =>
    axios
      .get("/account/system-admin/api/v1/approval/available-plans")
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.message))
  );
}
