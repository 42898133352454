import { createAsyncThunk } from "@reduxjs/toolkit";
import engagementApi from "src/configs/apis/consumer-engagement";

export const getDisbursementList = createAsyncThunk(
  "disbursement/getDisbursementList",
  async (payload, { rejectWithValue }) =>
    engagementApi
      .getDisbursementList({
        page: payload.page,
        search: payload.search,
        length: payload.length,
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const updateDisbursementInfo = createAsyncThunk(
  "disbursement/updateDisbursementInfo",
  async (payload, { rejectWithValue }) =>
    engagementApi
      .updateDisbursementInfo(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const createDisbursementInfo = createAsyncThunk(
  "disbursement/createDisbursementInfo",
  async (payload, { rejectWithValue }) =>
    engagementApi
      .createDisbursementInfo(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const getProviderList = createAsyncThunk(
  "disbursement/getProviderList",
  async (_, { rejectWithValue }) =>
    engagementApi
      .getProviderList()
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const getCurrencies = createAsyncThunk(
  "disbursement/getCurrencies",
  async (payload, { rejectWithValue }) =>
    engagementApi
      .getCurrencies(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const getCompanyDropdown = createAsyncThunk(
  "disbursement/getCompanyDropdown",
  async (payload, { rejectWithValue }) =>
    engagementApi
      .getCompanyDropdown(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const updateTransactionLimit = createAsyncThunk(
  "disbursement/updateTransactionLimit",
  async (payload, { rejectWithValue }) =>
    engagementApi
      .updateTransactionLimit(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);
