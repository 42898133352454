import React from 'react';
import { useSelector } from 'react-redux';
import LineChartCardComponent from './lineChartCard.component';

const LineChartCardContainer = () => {
  const lineChartData = useSelector((state) => state.dashboard.lineChartData.data);
  const isLoading = useSelector((state) => state.dashboard.lineChartData.isLoading);
  const error = useSelector((state) => state.dashboard.lineChartData.error);

  return (
    <LineChartCardComponent
      data={lineChartData}
      isLoading={isLoading}
      error={error}
    />
  );
};

export default LineChartCardContainer;
