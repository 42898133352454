import React from 'react';
import { useSelector } from 'react-redux';
import GraphScanCountComponent from './graph-scan-count.component';

export default function GraphScanCountContainer() {
  const graphData = useSelector((state) => state.companyDashboard.graphScanCount.data);
  const branchList = useSelector((state) => state.companyDashboard.branchList.data);
  const isLoading = useSelector((state) => state.companyDashboard.graphScanCount.isLoading);
  const error = useSelector((state) => state.companyDashboard.graphScanCount.error);

  return (
    <GraphScanCountComponent
      branchList={branchList}
      data={graphData}
      isLoading={isLoading}
      error={error}
    />
  );
}
