import axios from "axios";

export default function getProviderList() {
  const promise = new Promise((resolve, reject) =>
    axios
      .get(`/campaignV2/adminDisbursement/providerList`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.message))
  );

  return promise;
}
