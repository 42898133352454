import axios from "axios";

export default function getApprovalAvailableAccessRight(parameters) {
  const promise = new Promise((resolve, reject) =>
    axios
      .get(
        `/account/system-admin/api/v1/approval/available-access-rights/${parameters.uuid}?plan=${parameters.plan}`
      )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.message))
  );

  return promise;
}
