import React from "react";
import MasterControlDialogComponent from "./master-control-dialog.component";

function MasterControlDialogContainer({
  isLoading,
  isOpen,
  handleClose,
  handleProceed,
  title,
  description,
  icon,
  type,
}) {
  return (
    <MasterControlDialogComponent
      isLoading={isLoading}
      isOpen={isOpen}
      handleClose={handleClose}
      handleProceed={handleProceed}
      title={title}
      description={description}
      icon={icon}
      type={type}
      proceedLabel="Confirm"
      rejectLabel="Cancel"
    />
  );
}

export default MasterControlDialogContainer;
