/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import {
  Autocomplete,
  Box,
  TextField,
  createFilterOptions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles((theme) => ({
  option: {
    "& > span": {
      flex: 1,
    },
  },
  paper: {
    width: "100%",
  },
  codePaper: {
    width: "200%",
  },
}));

export default function CurrencySelectComponent({
  textFieldProps,
  formProps,
  currencyDropdownData,
  isLoading,
}) {
  const classes = useStyle();

  return (
    <Autocomplete
      {...formProps}
      fullWidth={textFieldProps.fullWidth || false}
      options={currencyDropdownData}
      disableClearable
      noOptionsText="No result found"
      classes={{
        option: classes.option,
        paper: classes.paper,
      }}
      autoHighlight
      loading={isLoading}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box key={key} p={1} {...optionProps}>
            <span>{option.name}</span>
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          name="currency"
          {...params}
          {...textFieldProps}
          style={{
            margin: 0,
          }}
          InputProps={{
            ...params.InputProps,
            style: {
              padding: "5px",
              borderRadius: "4px",
              margin: 0,
            },
          }}
          placeholder="Select a currency"
          inputProps={{
            ...params.inputProps,
            style: {
              paddingLeft: "8px",
              margin: 0,
            },
            autoComplete: "off", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
