import axios from "axios";

export default function getCurrencies() {
  const promise = new Promise((resolve, reject) =>
    axios
      .get(`/account/system-admin/api/v1/currency/get-dropdown-list`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.message))
  );

  return promise;
}
