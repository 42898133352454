import axios from "axios";

export default function updateTransactionLimit({
  id,
  increase,
  remark,
  decrease,
}) {
  const promise = new Promise((resolve, reject) =>
    axios
      .post(`/campaignV2/adminDisbursement/updateTransactionLimit`, {
        disbursement_company_id: id,
        increase,
        decrease,
        remark,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.message))
  );

  return promise;
}
