/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "src/modules/notification";
import { getApprovalAvailableAccessRight } from "src/modules/approvals/redux";
import CompanyApprovalDialogComponent from "./company-approval-dialog.component";

export default function CompanyApprovalDialogContainer({
  industries,
  selectedCompany,
  onCompanyApprovalDialogSubmitClick,
  onCompanyApprovalDialogCloseClick,
  plans,
}) {
  const dispatch = useDispatch();
  const availableAccessRights = useSelector(
    (state) => state.approvals.availableAccessRights
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, getApprovalAvailableAccessRight.typePrefix)
  );
  const [selectedPlan, setSelectedPlan] = useState(null);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(
        getApprovalAvailableAccessRight({
          uuid: selectedCompany.company.id,
          plan: selectedPlan
            ? selectedPlan.name
            : plans.length
            ? plans[0].name
            : "pro",
        })
      );
    }
  }, [selectedCompany, selectedPlan]);

  useEffect(() => {
    if (plans.length) setSelectedPlan(plans[0]);
    else setSelectedPlan(null);
  }, [plans]);

  return (
    <CompanyApprovalDialogComponent
      industries={industries}
      selectedCompany={selectedCompany}
      onCompanyApprovalDialogSubmitClick={onCompanyApprovalDialogSubmitClick}
      onCompanyApprovalDialogCloseClick={onCompanyApprovalDialogCloseClick}
      plans={plans}
      isFetching={isFetching}
      availableAccessRights={availableAccessRights}
      setSelectedPlan={setSelectedPlan}
      selectedPlan={selectedPlan}
    />
  );
}
