import { createSlice } from "@reduxjs/toolkit";
import {
  getNotificationSettings,
  getTransactionReport,
  getTransactionReports
} from "../action/transactionReports.action";

const TRANSACTION_REPORTS = "transactionReports";

const initialState = {
  records: {
    data: [],
    totalRecords: 0,
    totalFiltered: 0,
    isError: false,
  },
  record: null,
  settings: {
    enabled: false,
    emails: [],
  },
};
const transactionReportsSlice = createSlice({
  name: TRANSACTION_REPORTS,
  initialState,
  reducers: {
    resetTransactionReportsRecord: (state) => {
      state.records = initialState.records;
    },
    resetTransactionReportSelected: (state) => {
      state.record = initialState.record;
    },
  },
  extraReducers: {
    [getTransactionReports.pending]: (state) => {
      state.records = initialState.records;
    },
    [getTransactionReports.fulfilled]: (state, action) => {
      const { payload } = action;
      state.records.data = payload.records;
      state.records.totalRecords = payload.recordsTotal;
      state.records.totalFiltered = payload.recordsFiltered;
    },
    [getTransactionReports.rejected]: (state) => {
      state.records.isError = true;
    },
    [getTransactionReport.pending]: (state) => {
      state.record = initialState.record;
    },
    [getTransactionReport.fulfilled]: (state, action) => {
      const { payload } = action;
      state.record = payload;
    },
    [getNotificationSettings.pending]: (state) => {
      state.settings = initialState.settings;
    },
    [getNotificationSettings.fulfilled]: (state, action) => {
      const { payload } = action;
      state.settings.enabled = payload.enabled === "1";
      state.settings.emails = payload.emails;
    }
  },
});

export const {
  resetTransactionReportsRecord,
  resetTransactionReportSelected,
} = transactionReportsSlice.actions;
export const transactionReportsReducer = transactionReportsSlice.reducer;
