import axios from 'axios';

export default function getBranchList({ payload }) {
    return new Promise((resolve, reject) => axios
        .get("/account/system-admin/api/v1/nodes-by-company",
            {
                params: {
                    companyUuid: payload.companyUuid,
                    length: payload.length,
                    start: payload.start,
                    Search: payload.search,
                },
            })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.message))
    );
}
