import React from 'react';
import { useSelector } from 'react-redux';
import CompanyAssignedTableComponent from './companyAssignedTable.component';

function CompanyAssignedTableContainer() {
  const data = useSelector((state) => state.dashboard.companyAssignedRecords.data);
  const isLoading = useSelector((state) => state.dashboard.companyAssignedRecords.isLoading);
  const error = useSelector((state) => state.dashboard.companyAssignedRecords.error);

  return (
    <CompanyAssignedTableComponent
      isLoading={isLoading}
      error={error}
      data={data}
    />
  );
}

export default CompanyAssignedTableContainer;
