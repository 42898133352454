import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmailLogPage from './email-log.page';
import {
  getEmailLog,
  resendEmail,
  setSelectedAll,
  setSelectedAllFailed,
} from '../../redux/email-log.action';
import {
  clearEmailLogs,
  resetFilter,
  setItemPerPage,
  setPage,
  setSelected,
} from '../../redux/email-log.slice';

export default function EmailLogContainer() {
  const dispatch = useDispatch();
  const isEmailLogLoading = useSelector((state) => state.loading.inProcess.filter((p) => p.id === getEmailLog.typePrefix).length > 0);
  const emailLog = useSelector((state) => state.emailLog.logs);
  const emailLogPage = useSelector((state) => state.emailLog.emailLogsPage);
  const totalEmailLog = useSelector((state) => state.emailLog.totalEmailLogs);
  const isResending = useSelector((state) => state.loading.inProcess.filter((p) => p.id === resendEmail.typePrefix).length > 0);
  const searchValue = useSelector((state) => state.emailLog.searchValue);
  const filterType = useSelector((state) => state.emailLog.filterType);
  const filterStatus = useSelector((state) => state.emailLog.filterStatus);
  const filterDateRange = useSelector((state) => state.emailLog.filterDateRange);
  const itemPerPage = useSelector((state) => state.emailLog.itemPerPage);
  const selected = useSelector((state) => state.emailLog.selected);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectionOptionAnchorEl, setSelectionOptionAnchorEl] = useState(null);
  const [isResendConfirmDialogOpen, setResendConfirmDialogOpen] = useState(false);
  const [resendSelected, setResendSelected] = useState(null);

  const enableItemPerPageOption = true;

  useEffect(() => {
    dispatch(getEmailLog({
      page: emailLogPage,
      searchValue,
      filterType,
      filterStatus,
      filterDateRange,
      itemPerPage,
    }));
  }, [searchValue, filterType, filterStatus, filterDateRange, itemPerPage]);

  useEffect(() => {
    if (isResending === false) {
      dispatch(getEmailLog({
        page: emailLogPage,
        searchValue,
        filterType,
        filterStatus,
        filterDateRange,
        itemPerPage,
      }));
      setResendConfirmDialogOpen(false);
      dispatch(setSelected([]));
      setResendSelected(null);
    }
  }, [isResending]);

  useEffect(() => {
    dispatch(getEmailLog({
      page: emailLogPage,
      searchValue,
      filterType,
      filterStatus,
      filterDateRange,
      itemPerPage,
    }));
    return () => {
      dispatch(clearEmailLogs());
      dispatch(resetFilter());
    };
  }, [dispatch]);

  const onEmailLogPageClick = (page) => {
    if (emailLogPage !== page) {
      dispatch(getEmailLog({
        page,
        searchValue,
        filterType,
        filterStatus,
        filterDateRange,
        itemPerPage,
      }));
    }
  };

  const onEmailResendClick = (emailObj, page) => {
    setResendSelected(emailObj);
    setResendConfirmDialogOpen(true);
  };

  const onCheckboxClick = (emailObj) => {
    if (selected.some((e) => e.id === emailObj.id)) {
      dispatch(setSelected(selected.filter((e) => e.id !== emailObj.id)));
    } else {
      dispatch(setSelected([...selected, emailObj]));
    }
  };

  const toggleFilterOpen = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleCloseDialog = (event, reason) => {
    if (reason !== 'backdropClick') {
      setResendConfirmDialogOpen(!isResendConfirmDialogOpen);
      setResendSelected(null);
    }
  };

  const resendDialogCallback = () => {
    if (resendSelected !== null) {
      dispatch(resendEmail({ id: [resendSelected], emailLogPage }));
      setResendSelected(null);
    } else {
      dispatch(resendEmail({ id: selected.map((e) => e.id), emailLogPage }));
    }
  };

  const onBulkResendClick = () => {
    setResendConfirmDialogOpen(true);
  };

  const onItemPerPageChange = (value) => {
    dispatch(setPage(0));
    dispatch(setItemPerPage(value));
  };

  const handleOpenSelectionMenu = (event) => {
    setSelectionOptionAnchorEl(event.currentTarget);
  };

  const handleCloseSelectionMenu = () => {
    setSelectionOptionAnchorEl(null);
  };

  const handleSelectionOption = (option) => {
    handleCloseSelectionMenu();

    switch (option) {
      case 'all':
        dispatch(setSelectedAll({
          searchValue,
          filterType,
          filterStatus,
          filterDateRange,
        }));
        break;

      case 'all-page':
        dispatch(setSelected(selected.concat(emailLog.filter((pageLog) => !selected.some((selectedLog) => selectedLog.id === pageLog.id)))));
        break;

      case 'all-failed':
        dispatch(setSelectedAllFailed({
          searchValue,
          filterType,
          filterStatus,
          filterDateRange,
        }));
        break;

      case 'all-page-failed':
        dispatch(setSelected(selected.concat(emailLog.filter((pageLog) => !selected.some((selectedLog) => selectedLog.id === pageLog.id) && pageLog.status === 'failed'))));
        break;

      default:
        dispatch(setSelected(selected));
    }
  };

  const onDeselectedAllClick = () => {
    dispatch(setSelected([]));
    handleCloseSelectionMenu();
  };

  return (
    <EmailLogPage
      isEmailLogLoading={isEmailLogLoading}
      emailLog={emailLog}
      emailLogPage={emailLogPage}
      totalEmailLog={totalEmailLog}
      isResending={isResending}
      isFilterOpen={isFilterOpen}
      selected={selected}
      resendSelected={resendSelected}
      isResendConfirmDialogOpen={isResendConfirmDialogOpen}
      onEmailLogPageClick={onEmailLogPageClick}
      onEmailResendClick={onEmailResendClick}
      toggleFilterOpen={toggleFilterOpen}
      handleClose={handleCloseDialog}
      onConfirmClick={resendDialogCallback}
      onCheckboxClick={onCheckboxClick}
      onBulkResendClick={onBulkResendClick}
      onDeselectedAllClick={onDeselectedAllClick}
      enableItemPerPageOption={enableItemPerPageOption}
      itemPerPage={itemPerPage}
      handleItemPerPageChange={onItemPerPageChange}
      selectionOptionAnchorEl={selectionOptionAnchorEl}
      handleOpenSelectionMenu={handleOpenSelectionMenu}
      handleCloseSelectionMenu={handleCloseSelectionMenu}
      handleSelectionOption={handleSelectionOption}
    />
  );
}
