import {
  createAsyncThunk,
} from '@reduxjs/toolkit';
import partnersAndResellersApi from '../../../configs/apis/partners-and-resellers';

export const getPartnersAndResellers = createAsyncThunk(
  'partnersAndResellers/getListing',
  async (payload, { rejectWithValue }) => partnersAndResellersApi
    .getListing({ page: payload.page })
    .then((response) => response)
    .catch((error) => rejectWithValue(error)),
);

export const createPartnersAndResellers = createAsyncThunk(
  'partnersAndResellers/create',
  async (payload, { rejectWithValue }) => partnersAndResellersApi
    .create({
      name: payload.name,
      email: payload.email,
      password: payload.password,
      type: payload.type,
    })
    .then((response) => response)
    .catch((error) => rejectWithValue(error)),
);
