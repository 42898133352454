import { createSlice } from "@reduxjs/toolkit";
import { NOTIFICATION } from "../constants";

const initialState = {
  inProcess: [],
  alerts: [],
};

const notificationSlice = createSlice({
  name: NOTIFICATION,
  initialState,
  reducers: {
    addAlert(state, action) {
      state.alerts = [...state.alerts, action.payload];
    },
    resetAlert(state, action) {
      state.alerts = [];
    },
    closeSingleAlert(state) {
      state.alerts = state.alerts.slice(1);
    },
    setLoading(state, action) {
      /**
       * payload: {id: string, state: boolean}
       */
      const { payload } = action;
      if (payload.state) state.inProcess = [...state.inProcess, payload];
      else {
        state.inProcess = state.inProcess.filter(({ id }) => id !== payload.id);
      }
    },
  },
});

export const { addAlert, resetAlert, setLoading, closeSingleAlert } =
  notificationSlice.actions;
export const notificationReducer = notificationSlice.reducer;
