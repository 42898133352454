import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "src/modules/notification/redux";
import ProductSelectComponent from "./productSelect.component";
import ProductSelectPopoverComponent from "./productSelectPopover.component";
import { getProductList } from "../../../redux/action/companies.action";
import { resetProductDropdown } from "../../../redux/slice/companies.slice";

function ProductSelectContainer({
    placeholder,
    handleProductChange,
    selectedBranch,
    selectedProduct,
    isBranchSelected,
    fetchAllProduct = false,
}) {
    const dispatch = useDispatch();
    const isFetching = useSelector((state) =>
    selectLoading(state, getProductList.typePrefix)
    );
    const dropdown = useSelector(
        (state) => state.companies.productDropdown.list
    );
    const hasNextPage = useSelector(
        (state) => state.companies.productDropdown.hasNextPage
    );
    const totalFiltered = useSelector(
        (state) => state.companies.productDropdown.totalFiltered
    );
    const isError = useSelector(
        (state) => state.companies.productDropdown.isError
    );

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [filter, setFilter] = useState({
        length: fetchAllProduct ? -1 : 25,
        page: 0,
        search: "",
    });

    useEffect(() => {
        dispatch(
            getProductList({
                nodeId: selectedBranch.node_id,
                length: filter.length,
                start: filter.page * filter.length,
                search: filter.search,
            })
        );
    }, [selectedBranch, filter]);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFetchMoreItems = () => {
        if (hasNextPage) {
            const newFilter = {
                ...filter,
                page: filter.page + 1
            };
            setFilter(newFilter);
        }
    };

    const handleReload = () => {
        dispatch(resetProductDropdown());
        setFilter({
            ...filter,
            page: 0,
            search: "",
        });
    };

    const handleSelectField = (field) => {
        handleProductChange(field);
        handleClose();
    };

    return (
        <>
            <ProductSelectComponent
              open={open}
              handleClick={handleOpen}
              isBranchSelected={isBranchSelected}
              selectedProduct={selectedProduct}
              placeholder={placeholder}
            />
            <ProductSelectPopoverComponent
              anchorEl={anchorEl}
              open={open}
              handleClose={handleClose}
              isFetching={isFetching}
              isError={isError}
              productDropdown={dropdown}
              totalFiltered={totalFiltered}
              hasNextPage={hasNextPage}
              handleFetchMoreItems={handleFetchMoreItems}
              handleSelectField={handleSelectField}
              handleReload={handleReload}
            />
        </>
    );
}

export default ProductSelectContainer;
