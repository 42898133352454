import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Dashboard as DashboardIcon,
  Business as BusinessIcon,
  Logout as LogoutIcon,
  Email as EmailIcon,
  People as PeopleIcon,
} from "@material-ui/icons";
import DashboardLayoutComponent from "./DashboardLayout.component";
import { logout } from "../../configs/redux/session/session.action";

export default function DashboardLayoutContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const alerts = useSelector((state) => state.alerts.alerts);
  const isAuthenticated = useSelector(
    (state) => state.session.accessToken !== ""
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [navigate, isAuthenticated]);

  const onDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const items = [
    {
      icon: <DashboardIcon />,
      title: "Home",
      active: location.pathname === "/admin",
      onClick: () => navigate("/admin"),
    },
    {
      icon: <BusinessIcon />,
      title: "Approvals",
      active: location.pathname === "/admin/approvals",
      onClick: () => navigate("/admin/approvals"),
    },
    {
      icon: <BusinessIcon />,
      title: "Companies",
      active: location.pathname === "/admin/companies",
      onClick: () => navigate("/admin/companies"),
    },
    {
      icon: <PeopleIcon />,
      title: "Company Consumer Engagement",
      active: location.pathname === "/admin/consumer-engagement",
      onClick: () => navigate("/admin/consumer-engagement"),
    },
    {
      icon: <BusinessIcon />,
      title: "Partners & Resellers",
      active: location.pathname === "/admin/partners-and-resellers",
      onClick: () => navigate("/admin/partners-and-resellers"),
    },
    {
      icon: <EmailIcon />,
      title: "Email Log",
      active: location.pathname === "/admin/email-log",
      onClick: () => navigate("/admin/email-log"),
    },
    {
      icon: <BusinessIcon />,
      title: "Master Control",
      active: location.pathname === "/admin/master-control",
      onClick: () => navigate("/admin/master-control"),
    },
    {
      icon: <LogoutIcon />,
      title: "Logout",
      active: false,
      onClick: () => navigate("/logout"),
    },
  ];

  return (
    <DashboardLayoutComponent
      items={items}
      alerts={alerts}
      isDrawerOpen={isDrawerOpen}
      onDrawerToggle={onDrawerToggle}
    />
  );
}
