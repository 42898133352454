import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Tabs, Divider, Button, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Helmet } from "react-helmet";
import DisbursementContainer from "../disbursement";
import TransactionReports from "../transactionReports";

const style = (theme) => ({
  indicator: {
    border: "0.5px solid",
    borderColor: "#000000",
  },
  scroll: {
    width: "min-content",
  },
  tab: {
    padding: 0,
    marginRight: 20,
    // borderBottom: "#3A4D54 2px solid",
    alignItems: "center",
    minWidth: 0,
    textTransform: "none",
    overflow: "visible",
  },
});

const useStyles = makeStyles(style);

export default function MainPageComponent({
  handleChange,
  activeIndex,
  tabLabels,
}) {
  const classes = useStyles();

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button">{label}</Typography>,
    };
  }

  function TabPanel({ children, value, index, ...other }) {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  return (
    <Box>
      <Helmet>
        <title>Company Consumer Engagement | Kood Asia Management Portal</title>
      </Helmet>
      <Box p={2}>
        <Box gridArea="panel">
          <Box>
            <Box px={2}>
              <Tabs
                value={activeIndex || 0}
                onChange={handleChange}
                aria-label="scrollable auto tabs example"
                variant="scrollable"
                textColor="inherit"
                classes={{
                  indicator: classes.indicator,
                  scrollButtons: classes.scroll,
                }}
              >
                {tabLabels.map((item, index) => (
                  <Tab
                    className={classes.tab}
                    key={index}
                    {...a11yProps(index, item)}
                  />
                ))}
              </Tabs>
            </Box>

            <Box>
              {activeIndex !== null &&
                tabLabels.map((item, index) => {
                  if (item === "Disbursements") {
                    return (
                      <TabPanel value={activeIndex} index={index} key={index}>
                        <Box className={classes.panel}>
                          <DisbursementContainer />
                        </Box>
                      </TabPanel>
                    );
                  }

                  if (item === "Transaction Reports") {
                    return (
                      <TabPanel value={activeIndex} index={index} key={index}>
                        <Box className={classes.panel}>
                          <TransactionReports />
                        </Box>
                      </TabPanel>
                    );
                  }

                  return null;
                })}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
