import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TngTransactionReportTableComponent from "./tngTransactionReportTable.component";
import { selectLoading } from "../../../../notification";
import { getTransactionReports, resetTransactionReportSelected, resetTransactionReportsRecord } from "../../../redux";
import TngReportDetailDialog from "../../dialog/tng-report-detail-dialog";

export default function TngTransactionReportTableContainer() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.transactionReport.records.data);
  const totalRow = useSelector((state) => state.transactionReport.records.totalRecords);
  const isFetching = useSelector((state) =>
    selectLoading(state, getTransactionReports.typePrefix)
  );

  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
  });

  const [selected, setSelected] = useState(null);

  const fetchData = () => {
    dispatch(getTransactionReports({
      page: tableFilter.page + 1,
      length: tableFilter.rowsPerPage
    }));
  };

  useEffect(() => {
    fetchData();

    return () => {
      dispatch(resetTransactionReportsRecord());
      dispatch(resetTransactionReportSelected());
    };
  }, [dispatch, tableFilter]);

  const handleChangePage = (newPage) => {
    setTableFilter({
      ...tableFilter,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setTableFilter({
      ...tableFilter,
      page: 0,
      rowsPerPage: event,
    });
  };

  const handleClose = () => {
    setSelected(null);
  };

  return (
    <>
      <TngTransactionReportTableComponent
        data={data}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        tableFilter={tableFilter}
        totalRow={totalRow}
        isFetching={isFetching}
        setSelected={setSelected}
      />
      {selected && (
        <TngReportDetailDialog
          uuid={selected}
          handleClose={handleClose}
        />
      )}
    </>
  );
}
