/* eslint-disable no-nested-ternary */

import React from "react";
import { makeStyles, withStyles } from "@material-ui/styles";
import { Box, Radio, Skeleton, TableBody, TableCell, TableRow, Typography, TableContainer, Table, TableHead, Paper } from "@material-ui/core";
import ScrollBar from "react-perfect-scrollbar";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "4px",
    width: "100%",
    backgroundColor: "#FFFFFF",
    border: "2px solid #D0D5DD",
  },
  disabled: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "4px",
    width: "100%",
    color: "F3F3F3",
    backgroundColor: "#F3F3F3",
    border: "2px solid #D0D5DD",
  },
  inputText: {
    fontSize: "1rem",
    color: "#A4A6A8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  filled: {
    color: "rgba(0, 0, 0, 0.87)",
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F4F7F8",
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.common.white,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const SkeletonTableRow = ({ columnCount }) => (
  <StyledTableRow>
    {[...new Array(columnCount)].map((v, index) => (
      <StyledTableCell key={index}>
        <Skeleton variant="text" animation="wave" />
      </StyledTableCell>
    ))}
  </StyledTableRow>
);

export default function BatchSelectComponent({
  isBranchSelected,
  selectedBatch,
  isFetching,
  isError,
  batches,
  handleSelect,
}) {
  const classes = useStyle();

  return (
    <Paper className={classes.container}>
      <ScrollBar>
        <Box>
          <TableContainer className={classes.tableContainer} style={{ maxHeight: "35vh" }}>
            <Table
              className={classes.table}
              aria-label="sn table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px", background: "white" }}>
                <TableRow>
                  <TableCell>
                    Select
                  </TableCell>
                  <TableCell align="left">
                    Batch Number
                  </TableCell>
                  <TableCell align="left">
                    Type
                  </TableCell>
                  <TableCell align="left">
                    Prefix
                  </TableCell>
                  <TableCell align="left">
                    Length
                  </TableCell>
                  <TableCell align="left">
                    Postfix
                  </TableCell>
                  <TableCell align="left">
                    Starting Sequence Number
                  </TableCell>
                  <TableCell align="left">
                    Ending Sequence Number
                  </TableCell>
                  <TableCell align="right">
                    Quantity
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                    <SkeletonTableRow key={index} columnCount={9} />
                  )) : !isBranchSelected ? (
                    <TableCell colSpan={9}>
                      <Box display="flex" justifyContent="center" alignItems="center" p={1}>
                        <Typography>
                          Not Branch Selected
                        </Typography>
                      </Box>
                    </TableCell>
                  ) : batches.length <= 0 ? (
                    <TableCell colSpan={9}>
                      <Box display="flex" justifyContent="center" alignItems="center" p={1}>
                        <Typography>
                          No Record Found
                        </Typography>
                      </Box>
                    </TableCell>
                  ) : isError ? (
                    <TableCell colSpan={9}>
                      <Box display="flex" justifyContent="center" alignItems="center" p={1}>
                        <Typography>
                          Something when wrong
                        </Typography>
                      </Box>
                    </TableCell>
                  ) : batches.map((batch, index) => (
                    <TableRow
                      key={index}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                    >
                      <TableCell align="left">
                        <Radio
                          checked={batch.id === selectedBatch?.id}
                          onChange={(e) => {
                            if (e.target.checked) {
                              handleSelect(batch);
                            }
                          }}
                          name="pattern-selection"
                          size="small"
                          style={{ padding: "0px" }}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography color="textSecondary" variant="body2">
                          <b>{batch.batch}</b>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="textSecondary" variant="body2">
                          <b>{batch.generatingType}</b>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="textSecondary" variant="body2">
                          <b>{batch.prefix}</b>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="textSecondary" variant="body2">
                          <b>{batch.length}</b>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="textSecondary" variant="body2">
                          <b>{batch.postfix}</b>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="textSecondary" variant="body2">
                          <b>{batch.fromSeqNum}</b>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="textSecondary" variant="body2">
                          <b>{batch.toSeqNum}</b>
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          variant="body2"
                          color="textPrimary"
                        >
                          <b>{batch.quantity}</b>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </ScrollBar>
    </Paper>
  );

  // return (
  //   <ButtonBase
  //     disableRipple
  //     onClick={handleClick}
  //     disabled={!isBranchSelected}
  //     className={clsx(
  //       isBranchSelected ?
  //         classes.root :
  //         classes.disabled
  //     )}
  //   >
  //     <Box display="flex" style={{ maxWidth: "85%" }}>
  //       <Typography
  //         variant="body2"
  //         className={clsx(classes.inputText, classes.filled)}
  //       >
  //         {selectedBatch
  //           ? `${selectedBatch.generatingType}, ${selectedBatch.prefix}/${selectedBatch.length}/${selectedBatch.postfix}, ${selectedBatch.fromSeqNum} - ${selectedBatch.toSeqNum} (${selectedBatch.quantity})`
  //           : placeholder
  //         }
  //       </Typography>
  //     </Box>
  //     <Box
  //       style={{
  //         display: "flex",
  //         alignItems: "center",
  //       }}
  //     >
  //       {isBranchSelected &&
  //         (open ? (
  //           <ArrowDropUpIcon fontSize="small" />
  //         ) : (
  //           <ArrowDropDownIcon fontSize="small" />
  //         ))}
  //     </Box>
  //   </ButtonBase>
  // );
}
