import UserCreditTransferComponent from './user-credit-transfer.component';

export default function UserCreditTransferContainer({
  //  👇  Credit Transfer Dialog  👇  //
  selectedCompanyUser,
  onCreditTransferCloseClick,
  //  👇  Credit Transfer Confirmation Dialog  👇  //
  openCreditTransferConfirmation,
  onCreditTransferConfirmationClick,
  onCreditTransferConfirmSubmitClick,
  isSubmitting,
  onCreditTransferConfirmationCloseClick,
  //  👇  Credit Transfer Complete Dialog  👇  //
  completeDialogErrors,
  openCompleteDialog,
  onCompleteDialogClose,
}) {
  return (
    <UserCreditTransferComponent
      //  👇  Credit Transfer Dialog  👇  //
      selectedCompanyUser={selectedCompanyUser}
      onCreditTransferCloseClick={onCreditTransferCloseClick}
      //  👇  Credit Transfer Confirmation Dialog  👇  //
      openCreditTransferConfirmation={openCreditTransferConfirmation}
      onCreditTransferConfirmationClick={onCreditTransferConfirmationClick}
      onCreditTransferConfirmSubmitClick={onCreditTransferConfirmSubmitClick}
      isSubmitting={isSubmitting}
      onCreditTransferConfirmationCloseClick={onCreditTransferConfirmationCloseClick}
      //  👇  Credit Transfer Complete Dialog  👇  //
      completeDialogErrors={completeDialogErrors}
      openCompleteDialog={openCompleteDialog}
      onCompleteDialogClose={onCompleteDialogClose}
    />
  );
}
