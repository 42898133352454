/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  TextField,
  DialogActions,
  Button,
  IconButton,
  Box,
  Paper,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  FormLabel,
} from "@material-ui/core";
import * as Yup from "yup";
import CloseIcon from "@material-ui/icons/CloseRounded";
import { Formik } from "formik";

const UpdateTransactionLimitDialogComponent = ({
  selectedCompany,
  isOpen,
  handleClose,
  isSubmitting,
  handleSubmit,
  handleValidateBalance,
}) => {
  if (!selectedCompany) {
    return null;
  }

  const actions = [
    {
      title: "Deduct Limit",
      action: "credit",
    },
    {
      title: "Add Limit",
      action: "debit",
    },
  ];

  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        handleClose();
      }}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <Formik
        initialValues={{
          limitUpdateAction: "credit",
          amount: 0,
          confirmAmount: 0,
          remark: "",
          newBalance: 0,
        }}
        validationSchema={Yup.object().shape({
          limitUpdateAction: Yup.string().required("Action is required"),
          amount: Yup.number()
            .min(1)
            .required("Positive Amount Number is required")
            .typeError("Invalid number"),
          confirmAmount: Yup.number().oneOf(
            [Yup.ref("amount"), null],
            "Amount must match"
          ),
          newBalance: Yup.number().min(0),
        })}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle id="scroll-dialog-title">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body2">
                  <b>Update transaction limit</b>
                </Typography>
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={handleClose}
                  style={{ padding: 0 }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent
              dividers
              style={{ backgroundColor: "#f3f3f3", padding: 16 }}
            >
              <Paper>
                <Box p={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <Typography marginBottom={1} display="inline">
                        Current Balance:{" "}
                      </Typography>
                      <Typography display="inline">
                        <b>
                          {selectedCompany
                            ? selectedCompany.transaction_limit
                            : "0"}
                        </b>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormLabel
                        style={{
                          color: formik.errors.limitUpdateAction
                            ? "#d32f2f"
                            : "inherit",
                        }}
                      >
                        Transaction Limit Update Action
                      </FormLabel>
                      <RadioGroup
                        name="limitUpdateAction"
                        value={formik.values.limitUpdateAction}
                        onChange={formik.handleChange}
                      >
                        {actions.map((action) => (
                          <FormControlLabel
                            key={action}
                            value={action.action}
                            control={<Radio disabled={formik.isSubmitting} />}
                            label={action.title}
                          />
                        ))}
                      </RadioGroup>
                      <FormHelperText error={!!formik.errors.limitUpdateAction}>
                        {formik.errors.limitUpdateAction}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        label="Amount"
                        name="amount"
                        type="number"
                        value={formik.values.amount}
                        onKeyDown={(event) => {
                          if (
                            event.key === "e" ||
                            event.key === "E" ||
                            event.key === "."
                          ) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          handleValidateBalance(
                            e.target.value,
                            "amount",
                            formik
                          );
                        }}
                        error={!!formik.errors.amount}
                        helperText={formik.errors.amount}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        label="Confirm Amount"
                        name="confirmAmount"
                        type="number"
                        value={formik.values.confirmAmount}
                        onKeyDown={(event) => {
                          if (
                            event.key === "e" ||
                            event.key === "E" ||
                            event.key === "."
                          ) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          handleValidateBalance(
                            e.target.value,
                            "confirmAmount",
                            formik
                          );
                        }}
                        error={!!formik.errors.confirmAmount}
                        helperText={formik.errors.confirmAmount}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography
                        variant="h4"
                        marginBottom={1}
                        fontFamily="Roboto"
                        display="inline"
                      >
                        New Balance:{" "}
                      </Typography>
                      <Typography
                        variant="h4"
                        fontFamily="Roboto"
                        display="inline"
                      >
                        {formik.values.limitUpdateAction === "credit"
                          ? Number(selectedCompany.transaction_limit) -
                            (formik.values.amount
                              ? Number(formik.values.amount)
                              : 0)
                          : Number(selectedCompany.transaction_limit) +
                            (formik.values.amount
                              ? Number(formik.values.amount)
                              : 0)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        label="Remark"
                        name="remark"
                        multiline
                        rows={4}
                        value={formik.values.remark}
                        onChange={formik.handleChange}
                        error={!!formik.errors.remark}
                        helperText={formik.errors.remark}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                type="submit"
                disabled={
                  isSubmitting ||
                  formik.values.amount === "" ||
                  Number(formik.values.amount) !==
                    Number(formik.values.confirmAmount)
                }
                startIcon={
                  isSubmitting ? (
                    <CircularProgress style={{ width: 10, height: 10 }} />
                  ) : null
                }
              >
                Submit
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default UpdateTransactionLimitDialogComponent;
