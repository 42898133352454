import axios from 'axios';

export default function getProductList({ payload }) {
    return new Promise((resolve, reject) => axios
        .get("/serialnumber/system-admin/api/v1/products/dropdown-name",
            {
                params: {
                    nodeId: payload.nodeId,
                    length: payload.length,
                    start: payload.start,
                    Search: payload.search,
                },
            })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.message))
    );
}
