import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "src/modules/notification";
import { getTransactionReport, resetTransactionReportSelected } from "../../../redux";
import TngReportDetailDialogComponent from "./tngReportDetailDialog.component";

export default function TngReportDetailDialogContainer({
  uuid,
  handleClose,
}) {
  const dispatch = useDispatch();
  const isFetching = useSelector((state) =>
    selectLoading(state, getTransactionReport.typePrefix)
  );
  const data = useSelector((state) => state.transactionReport.record);

  useEffect(() => {
    dispatch(getTransactionReport({ uuid }));

    return () => {
      dispatch(resetTransactionReportSelected());
    };
  }, []);

  return (
    <>
      <TngReportDetailDialogComponent
        handleClose={handleClose}
        isFetching={isFetching}
        data={data}
      />
    </>
  );
}
