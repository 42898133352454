import axios from 'axios';

export default function signOut({
  sessionId,
  sessionExpiration,
}) {
  const headers = {};

  headers['Session-Id'] = sessionId;
  headers['Session-Expiration'] = sessionExpiration;

  return axios.get('/authentication/api/basic/signout',
    {
      headers,
    });
}
