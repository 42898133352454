import React from 'react';
import { Box, Card, Typography } from '@material-ui/core';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import the datalabels plugin
import Loading from 'src/components/Loading';

const PieChartScanValidityComponent = ({
  data,
  isLoading,
  error,
}) => {
  const pieChartData = {
    labels: ['Total Scan Count', 'Suspended Count'],
    datasets: [
      {
        data: [data.totalScanCount, data.totalSuspendedScanCount],
        backgroundColor: ['rgb(75, 192, 192)', 'rgb(255, 99, 132)'],
      },
    ],
  };

  const renderContent = () => {
    if (isLoading) return <Loading />;
    if (error) return <div style={{ height: '100%', display: 'grid', placeItems: 'center' }}>Error: {error}</div>;
    if (data.totalScanCount === 0 && data.totalSuspendedScanCount === 0) return <div style={{ height: '100%', display: 'grid', placeItems: 'center' }}>No data found</div>;
    return (
      <Doughnut
        data={pieChartData}
        options={{
          plugins: {
            datalabels: { // Enable the datalabels plugin for Doughnut chart only
              display: true,
              color: 'white', // Set the font color of the data labels
            },
          },
        }}
        plugins={[ChartDataLabels]} // Register the datalabels plugin for the Doughnut chart
      />
    );
  };

  return (
    <Card
      sx={{
        height: '100%',
        width: '100%',
        maxHeight: '600px',
        overflow: 'auto',
        p: 2,
        borderRadius: '12px',
      }}
    >
      <Typography variant="h6" style={{ fontSize: '24px', fontWeight: 'bolder' }}>
        Scan Count Statistics
      </Typography>
      <Box style={{ maxWidth: '300px', height: '300px', margin: '0 auto' }}>
        {renderContent()}
      </Box>
    </Card>
  );
};

export default PieChartScanValidityComponent;
