import React from 'react';
import { GoogleMap, Marker, MarkerClusterer } from '@react-google-maps/api';
import { Card } from '@material-ui/core';
import Loading from 'src/components/Loading';
import ClusterImage from '../../assets/Eo_circle_green_blank.svg.png';

export default function MapScanCountComponent({
  onLoad,
  isLoading,
  isLoaded,
  center,
  onUnmount,
  data,
  error
}) {
  const clusterStyles = [
    {
      url: ClusterImage,
      textColor: '#FFFFFF',
      textSize: 12,
      width: 40,
      height: 40,
    },
  ];

  const renderContent = () => {
    if (!isLoaded || isLoading) return <Loading />;
    if (error) return <div style={{ height: '100%', display: 'grid', placeItems: 'center' }}>Error: {error}</div>;
    if (!data.length) return <div style={{ height: '100%', display: 'grid', placeItems: 'center' }}>No data found</div>;
    return (
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '340px' }}
        onLoad={onLoad}
        center={center}
        onUnmount={onUnmount}
        options={{
          mapTypeControl: true,
          streetViewControl: true,
          rotateControl: true,
          clickableIcons: false,
          keyboardShortcuts: false,
        }}
      >
        <MarkerClusterer
          options={{
            styles: clusterStyles,
            maxZoom: 14, // The maximum zoom level where clustering should occur
          }}
        >
          {(clusterer) => data.map((scan, i) => (
            <Marker
              key={i}
              clusterer={clusterer}
              position={{ lat: parseFloat(scan.latitude), lng: parseFloat(scan.longitude) }}
            />
          ))}
        </MarkerClusterer>
      </GoogleMap>
    );
  };

  return (
    <Card
      sx={{
        height: '380px',
        width: '100%',
        marginTop: '20px',
        padding: '20px',
        borderRadius: '12px',
      }}
    >
      {renderContent()}
    </Card>
  );
}
