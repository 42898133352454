import axios from 'axios';

const getGeneratedAssignGraph = ({ companyIds, startDate, endDate }) => new Promise((resolve, reject) => {
  axios
    .post('/serialnumber/system-admin/api/v1/get-generate-assigned-graph', {
        company_ids: companyIds.toString(),
        start_date: startDate,
        end_date: endDate,
      },
    )
    .then((response) => resolve(response.data))
    .catch((error) => {
      if (error.response && error.response.data) {
        reject(error.response.data);
      } else {
        reject(error);
      }
    });
});

export default getGeneratedAssignGraph;
