import createDisbursementInfo from "./createDisbursementInfo.api";
import getCompanyDropdown from "./getCompanyDropdown.api";
import getCurrencies from "./getCurrencies.api";
import getDisbursementList from "./getDisbursementList.api";
import getProviderList from "./getProviderList.api";
import updateDisbursementInfo from "./updateDisbursementInfo.api";
import updateTransactionLimit from "./updateTransactionLimit.api";
import getTransactionReports from "./getTransactionReports.api";
import getTransactionReport from "./getTransactionReport.api";
import getNotificationSettings from "./getNotificationSettings.api";
import setNotificationSettings from "./setNotificationSettings.api";

const engagementApi = {
  getDisbursementList,
  updateDisbursementInfo,
  createDisbursementInfo,
  getProviderList,
  getCurrencies,
  getCompanyDropdown,
  updateTransactionLimit,
  getTransactionReports,
  getTransactionReport,
  getNotificationSettings,
  setNotificationSettings,
};

export default engagementApi;
