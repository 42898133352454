import { createSlice } from "@reduxjs/toolkit";
import {
  getAccessRights,
  getCompanies,
  getCompanyPlanInfo,
  getNodesWithWhiteLabel,
  getBranchList,
  getProductList, getNodeSnRange,
} from "../action/companies.action";

const COMPANIES = "companies";
const initialState = {
  totalCompanies: 0,
  companiesPage: 0,
  companies: [],
  accessRights: {
    list: [],
    selectedIds: [],
    isError: false,
  },
  planInfo: null,
  nodesWithWhiteLabel: null,
  totalCompanyManageBranch: 0,
  companyManageBranchPage: 0,
  allBranchWhiteLabelCount: [],
  branchDropdown: {
    list: [],
    totalRecords: 0,
    totalFiltered: 0,
    hasNextPage: true,
    isError: false,
  },
  productDropdown: {
    list: [],
    totalRecords: 0,
    totalFiltered: 0,
    hasNextPage: true,
    isError: false,
  },
  nodeSnRange: {
    list: [],
    isError: false,
  },
  reassignResult: null,
};
const companiesSlice = createSlice({
  name: COMPANIES,
  initialState,
  reducers: {
    clearCompanies: (state, action) => {
      state.totalCompanies = 0;
      state.companies = [];
    },
    addBranchWhiteLabelCount: (state, action) => {
      state.allBranchWhiteLabelCount[action.payload] += 1;
    },
    minusBranchWhiteLabelCount: (state, action) => {
      state.allBranchWhiteLabelCount[action.payload] -= 1;
    },
    resetBranchDropdown: (state) => {
      state.branchDropdown = initialState.branchDropdown;
    },
    resetProductDropdown: (state) => {
      state.productDropdown = initialState.productDropdown;
    },
    setReassignResult: (state, action) => {
      state.reassignResult = action.payload;
    },
    clearReassignResult: (state, action) => {
      state.reassignResult = initialState.reassignResult;
    }
  },
  extraReducers: {
    [getCompanies.fulfilled]: (state, action) => {
      state.totalCompanies = action.payload.recordsFiltered;
      state.companiesPage = action.meta.arg.page;
      state.allBranchWhiteLabelCount = [];
      const processCompanies = action.payload.data.map((company, index) => {
        const processCompany = {};
        processCompany.company = {
          row: index,
          id: company.id || "",
          whiteLabel: company.white_label || "",
          name: company.name || "",
          regNo: company.reg_no || "",
          industry: company.industry || "",
          email: company.email || "",
          contact: company.contact || "",
          addressLine1: company.line1 || "",
          addressLine2: company.contact || "",
          postcode: company.postal_code || "",
          state: company.state || "",
          city: company.city || "",
          country: company.country || "",
          status: company.status || "",
          plan: company.plan_name || "",
        };
        if (company.article_assoclation) {
          processCompany.company.articleOfAssoclationUrl =
            company.article_assoclation.url || "";
        }
        if (company.director_identification) {
          processCompany.company.directorIdentificationUrl =
            company.director_identification.url || "";
        }
        if (company.owner) {
          processCompany.owner = {
            firstName: company.owner.first_name || "",
            lastName: company.owner.last_name || "",
            contact: company.owner.phone_number || "",
            email: company.owner.email || "",
            timezone: company.owner.timezone || "",
          };
        } else {
          processCompany.owner = {
            firstName: "",
            lastName: "",
            contact: "",
            email: "",
            timezone: "",
          };
        }
        state.allBranchWhiteLabelCount.push(company.branch_white_label_count);
        return processCompany;
      });
      state.companies = processCompanies;
    },
    [getAccessRights.pending]: (state, action) => {
      state.accessRights = initialState.accessRights;
    },
    [getAccessRights.fulfilled]: (state, action) => {
      const { payload } = action;
      const ids = [];
      payload.forEach((group) => {
        group.subgroup.forEach((s) => {
          s.details.forEach((d) => {
            if (d.is_enabled) {
              ids.push(d.id);
            }
          });
        });
      });
      state.accessRights.list = payload;
      state.accessRights.selectedIds = ids;
    },
    [getAccessRights.rejected]: (state, action) => {
      state.accessRights.isError = true;
    },
    [getCompanyPlanInfo.pending]: (state, action) => {
      state.planInfo = initialState.planInfo;
    },
    [getCompanyPlanInfo.fulfilled]: (state, action) => {
      state.planInfo = action.payload;
    },
    [getNodesWithWhiteLabel.pending]: (state, action) => {
      state.nodesWithWhiteLabel = initialState.nodesWithWhiteLabel;
    },
    [getNodesWithWhiteLabel.fulfilled]: (state, action) => {
      state.nodesWithWhiteLabel = action.payload.data;
      state.totalCompanyManageBranch = action.payload.recordsTotal;
      state.companyManageBranchPage = action.meta.arg.page;
    },
    [getNodesWithWhiteLabel.rejected]: (state, action) => {
      state.nodesWithWhiteLabel.isError = true;
    },
    // [getBranchList.pending]: (state) => {
    //   state.branchDropdown.isError = true;
    // },
    [getBranchList.fulfilled]: (state, action) => {
      const { payload } = action;

      // state.branchDropdown.list = payload.data;

      // if (state.branchDropdown.hasNextPage) {
      //   state.branchDropdown.list = [
      //     ...state.branchDropdown.list,
      //     ...payload.data,
      //   ];
      // }

      state.branchDropdown.list = payload.data;

      state.branchDropdown.totalRecords = payload.recordsTotal;
      state.branchDropdown.totalFiltered = payload.recordsFiltered;
      state.branchDropdown.hasNextPage =
        state.branchDropdown.list.length < payload.recordsFiltered;
    },
    [getBranchList.rejected]: (state) => {
      state.branchDropdown.isError = true;
    },
    // [getProductList.pending]: (state) => {
    //   state.branchDropdown.isError = true;
    // },
    [getProductList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.productDropdown.list = payload.data;

      if (state.productDropdown.hasNextPage) {
        state.productDropdown.list = [
          ...state.productDropdown.list,
          ...payload.data,
        ];
      }
      state.productDropdown.totalRecords = payload.recordsTotal;
      state.productDropdown.totalFiltered = payload.recordsFiltered;
      state.productDropdown.hasNextPage =
        state.productDropdown.list.length < payload.recordsFiltered;
    },
    [getProductList.rejected]: (state) => {
      state.productDropdown.isError = true;
    },
    [getNodeSnRange.fulfilled]: (state, action) => {
      const { payload } = action;

      state.nodeSnRange.list = payload.map((e) => {
        const [prefix, length, postfix, generatingType] = e.format.split(',');

        return {
          id: e.id,
          batch: e.batch_number,
          format: e.format,
          prefix,
          length,
          postfix,
          generatingType,
          quantity: e.quantity,
          fromSeqNum: e.from_seq_num,
          toSeqNum: e.to_seq_num,
          genType: e.gen_type,
          status: e.status,
        };
      });
    },
    [getNodeSnRange.rejected]: (state) => {
      state.nodeSnRange.isError = true;
    },
  },
});

export const {
  clearCompanies,
  addBranchWhiteLabelCount,
  minusBranchWhiteLabelCount,
  resetBranchDropdown,
  resetProductDropdown,
  setReassignResult,
  clearReassignResult,
} = companiesSlice.actions;
export const companiesReducer = companiesSlice.reducer;
