import getApprovals from "./getApprovals.api";
import putApproval from "./putApproval.api";
import getListing from "./getApprovalListing.api";
import putWhiteLabel from "./putWhiteLabel.api";
import getCompanyUsers from "./getCompanyUsers.api";
import postCreditTransfer from "./postCreditTransfer.api";
import getAccessRights from "./getAccessRights.api";
import updateAccessRights from "./updateAccessRights.api";
import getAvailablePlans from "./getAvailablePlans.api";
import getCompanyPlanInfo from "./getCompanyPlanInfo.api";
import updateCompanyPlan from "./updateCompanyPlan.api";
import getApprovalAvailableAccessRight from "./getApprovalAvailableAccessRight.api";
import getNodesWithWhiteLabel from "./getNodesWithWhiteLabel.api";
import putBranchWhiteLabel from "./putBranchWhiteLabel.api";
import removeBranchWhiteLabel from "./removeBranchWhiteLabel.api";
import getBranchList from "./getBranchList.api";
import getProductList from "./getProductList.api";
import updateAssign from "./updateAssign.api";
import updateUnassign from "./updateUnassign.api";
import setCompanyStatus from "./setCompanyStatus.api";
import removeCompanyWhiteLabel from "./removeCompanyWhiteLabel.api";
import getNodeSnRange from "./getNodeSnRange.api";

const companiesApi = {
  getApprovals,
  putApproval,
  getListing,
  putWhiteLabel,
  removeCompanyWhiteLabel,
  getCompanyUsers,
  postCreditTransfer,
  getAccessRights,
  updateAccessRights,
  getAvailablePlans,
  getCompanyPlanInfo,
  updateCompanyPlan,
  getApprovalAvailableAccessRight,
  getNodesWithWhiteLabel,
  putBranchWhiteLabel,
  removeBranchWhiteLabel,
  getBranchList,
  getProductList,
  updateAssign,
  updateUnassign,
  setCompanyStatus,
  getNodeSnRange,
};

export default companiesApi;
