import { checkSession, login } from './session.action';
import { setSession } from './session.slice';

const checkSessionMiddleware = ({ dispatch }) => (next) => async (action) => {
  next(action);
  const { type, payload } = action;

  switch (type) {
    case checkSession.fulfilled.type:
      dispatch(setSession(payload));
      break;

    default:
      break;
  }
};

const loginMiddleware = ({ dispatch }) => (next) => async (action) => {
  next(action);

  const { type, payload } = action;

  switch (type) {
    case login.fulfilled.type:
      localStorage.setItem('session_id', payload.session_id);
      localStorage.setItem('session_expiration', payload.session_expiration);
      dispatch(setSession(payload));
      break;

    default:
      break;
  }
};

const middlewares = [
  checkSessionMiddleware,
  loginMiddleware,
];

export default middlewares;
