import getEmailLog from './get-email.log.api';
import resendEmail from './resend-email.api';
import getEmailTypeFilter from './get-email-type-filter.api';

const emailLogApi = {
  getEmailLog,
  resendEmail,
  getEmailTypeFilter
};

export default emailLogApi;
