import React from 'react';
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
} from '@material-ui/core';
import Loading from 'src/components/Loading';

export default function TableDailyAssignedQRCountComponent({
  data,
  isLoading,
  error
}) {
  const tableData = Object.entries(data.data).map(([date, totalAssignedQRCount]) => ({
    date, totalAssignedQRCount
  }));
  tableData?.sort((a, b) => b.totalAssignedQRCount - a.totalAssignedQRCount);

  const renderContent = () => {
    if (isLoading) return <div style={{ height: '300px', display: 'grid', placeItems: 'center' }}><Loading /></div>;
    if (error) return <div style={{ height: '300px', display: 'grid', placeItems: 'center' }}>Error: {error}</div>;
    if (!tableData.length) return <div style={{ height: '300px', display: 'grid', placeItems: 'center' }}>No data found</div>;
    return (
      <TableContainer
        component={Box}
        border={1}
        borderColor="grey.500"
        borderRadius={1}
        p={1}
        mt={2}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Date</TableCell>
              <TableCell align="center">QR Assigned</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((item, i) => (
              <TableRow key={i}>
                <TableCell>{i + 1}</TableCell>
                <TableCell>{item.date}</TableCell>
                <TableCell align="center">{item.totalAssignedQRCount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell />
              <TableCell>Total</TableCell>
              <TableCell align="center">{data.totalAssignedQRCount}</TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: '#ffffff',
        p: 2,
        borderRadius: '12px',
      }}
    >
      <Typography variant="h6" style={{ fontSize: '24px', fontWeight: 'bolder' }}>Daily Assigned QR Count</Typography>
      <Box
        sx={{
          maxHeight: '450px',
          overflow: 'auto',
        }}
      >
        { renderContent() }
      </Box>
    </Box>
  );
}
