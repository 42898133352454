import {
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';

const EmailLogSelectionBarComponent = ({
  selectedNum,
  onBulkResendClick,
  onDeselectedAllClick,
  anchorEl,
  handleClick,
  handleClose,
}) => (
  <>
    <Box
      style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      sx={{ mb: 1 }}
    >
      <Typography>
        {`Selected ${selectedNum} ${(selectedNum > 1) ? 'items' : 'item'}`}
      </Typography>
      <>
        <Button
          variant="outlined"
          onClick={handleClick}
        >
          Action
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={anchorEl}
          onClose={handleClose}
        >
          <MenuItem onClick={onBulkResendClick}>Resend Selected</MenuItem>
        </Menu>
      </>
    </Box>
  </>
);

export default EmailLogSelectionBarComponent;
