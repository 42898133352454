import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import MainPageComponent from './dashboard.page';
import {
  fetchListingCountryTable,
  fetchMapScanCount,
  fetchPieChartScanValidity,
  fetchLineChartScanCount,
  getAllCompanies,
  fetchTop20Companies,
  fetchListingCompanyTable,
  fetchCompanyGenerateTable,
  fetchCompanyAssignedTable,
  fetchGeneratedAssignedGraph,
} from '../../redux/action/dashboard.action';

function MainPageContainer() {
  const dispatch = useDispatch();
  const [dateRangeFilter, setDateRangeFilter] = useState({
    startDate: moment().subtract(13, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  });
  const companyDropdown = useSelector((state) => state.dashboard.allCompanies);
  const [isLoading, setIsLoading] = useState(true);
  const [companyFilter, setCompanyFilter] = useState(null);
  const top20Companies = useSelector((state) => state.dashboard.top20Companies);

  useEffect(() => {
    const fetchCompanies = () => new Promise((resolve, reject) => {
      getAllCompanies()
        .then((companies) => {
          resolve(companies);
        })
        .catch((error) => {
          console.error('Error fetching companies:', error);
          reject(error);
        });
    });

    fetchCompanies()
      .then((companies) => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  const handleSearch = () => {
    if (companyFilter == null || companyFilter.length < 1) {
      dispatch(fetchListingCountryTable({ ...dateRangeFilter, companyIds: top20Companies }));
      dispatch(fetchMapScanCount({ ...dateRangeFilter, companyIds: top20Companies }));
      dispatch(fetchPieChartScanValidity({ ...dateRangeFilter, companyIds: top20Companies }));
      dispatch(fetchLineChartScanCount({ ...dateRangeFilter, companyIds: top20Companies }));
      dispatch(fetchGeneratedAssignedGraph({ ...dateRangeFilter, companyIds: top20Companies }));
      dispatch(fetchListingCompanyTable({ ...dateRangeFilter, companyIds: top20Companies }));
      dispatch(fetchCompanyGenerateTable({ ...dateRangeFilter, isTop20Companies: true }));
      dispatch(fetchCompanyAssignedTable({ ...dateRangeFilter, isTop20Companies: true }));
    } else {
      dispatch(fetchListingCountryTable({ ...dateRangeFilter, companyIds: companyFilter }));
      dispatch(fetchMapScanCount({ ...dateRangeFilter, companyIds: companyFilter }));
      dispatch(fetchPieChartScanValidity({ ...dateRangeFilter, companyIds: companyFilter }));
      dispatch(fetchLineChartScanCount({ ...dateRangeFilter, companyIds: companyFilter }));
      dispatch(fetchGeneratedAssignedGraph({ ...dateRangeFilter, companyIds: companyFilter }));
      dispatch(fetchListingCompanyTable({ ...dateRangeFilter, companyIds: companyFilter }));
      dispatch(fetchCompanyGenerateTable({ ...dateRangeFilter, companyIds: companyFilter }));
      dispatch(fetchCompanyAssignedTable({ ...dateRangeFilter, companyIds: companyFilter }));
    }
  };

  async function fetchDataAndDispatch() {
    try {
      const top20CompaniesAction = await dispatch(fetchTop20Companies(dateRangeFilter));

      if (fetchTop20Companies.fulfilled.match(top20CompaniesAction)) {
        const firstTop20Companies = top20CompaniesAction.payload;
        const companyIds = firstTop20Companies.map((company) => company.id);
        dispatch(fetchListingCountryTable({ ...dateRangeFilter, companyIds }));
        dispatch(fetchMapScanCount({ ...dateRangeFilter, companyIds }));
        dispatch(fetchPieChartScanValidity({ ...dateRangeFilter, companyIds }));
        dispatch(fetchLineChartScanCount({ ...dateRangeFilter, companyIds }));
        dispatch(fetchGeneratedAssignedGraph({ ...dateRangeFilter, companyIds }));
        dispatch(fetchListingCompanyTable({ ...dateRangeFilter, companyIds }));
        dispatch(fetchCompanyGenerateTable({ ...dateRangeFilter, isTop20Companies: true }));
        dispatch(fetchCompanyAssignedTable({ ...dateRangeFilter, isTop20Companies: true }));
      } else {
        console.error('fetchTop20Companies failed');
      }

      setIsLoading(false);
    } catch (error) {
      console.error('An error occurred:', error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    dispatch(getAllCompanies());
    fetchDataAndDispatch();
  }, []);

  const handleDateRangeChange = (start, end) => {
    setDateRangeFilter({
      startDate: start,
      endDate: end,
    });
    dispatch(fetchTop20Companies({ startDate: start, endDate: end }));
  };

  const handleCompanySelect = (selectedCompany) => {
    setCompanyFilter(selectedCompany);
  };

  return (
    <MainPageComponent
      handleDateRangeChange={handleDateRangeChange}
      dateRangeFilter={dateRangeFilter}
      companyDropdown={companyDropdown}
      isLoading={isLoading}
      handleSearch={handleSearch}
      handleCompanySearch={handleCompanySelect}
    />
  );
}

export default MainPageContainer;
