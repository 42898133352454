/* eslint-disable react/jsx-no-duplicate-props */
import React, { useRef } from "react";
import {
  Typography,
  Popover,
  Box,
  Divider,
  InputAdornment,
  Button,
  MenuItem,
  IconButton,
  Skeleton,
} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import InputTextField from "src/components/Input/InputTextField";
import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles((theme) => ({
  name: {
    whiteSpace: "normal",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
  paper: {
    width: 425,
  },
}));

export default function ProductSelectPopoverComponent({
  value,
  anchorRef,
  open,
  handleClose,
  isFetching,
  handleSearch,
  items,
  handleChange,
}) {
  const classes = useStyle();
  const textRef = useRef();

  return (
    <Popover
      open={open}
      style={{ zIndex: 99 }}
      onClose={handleClose}
      anchorEl={anchorRef.current}
      role={undefined}
      disablePortal
      placement="bottom-start"
    >
      <Box className={classes.paper}>
        <InputTextField
          variant="filled"
          size="small"
          fullWidth
          inputRef={textRef}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" style={{ paddingRight: 6 }}>
                <IconButton
                  onClick={() => {
                    textRef.current.value = "";
                    handleSearch("");
                  }}
                  size="small"
                >
                  <ClearIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleSearch(textRef.current.value)}
                  size="small"
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
            style: {
              backgroundColor: "#fff",
              padding: 0,
            },
          }}
          inputProps={{
            onKeyDown: (e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSearch(textRef.current.value);
              }
            },
            style: {
              padding: 8,
            },
          }}
          placeholder="Search"
        />
      </Box>
      <Divider />
      <Box style={{ maxHeight: "250px", overflow: "auto" }}>
        {isFetching ? (
          <Box p={1}>
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
          </Box>
        ) : items.length > 0 ? (
          <Box>
            {items.map((item, index) => (
              <Box key={index}>
                <MenuItem
                  style={{
                    padding: "8px",
                    backgroundColor:
                      value && value.id === item.id ? "#f7f7f7" : "transparent",
                  }}
                  onClick={() => {
                    handleChange(item);
                    handleClose();
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="flex-start"
                  >
                    <Typography className={classes.name} variant="body2">
                      {item.name}
                    </Typography>
                  </Box>
                </MenuItem>
              </Box>
            ))}
          </Box>
        ) : (
          <Box py={9} style={{ textAlign: "center" }}>
            <Typography variant="body1">No record</Typography>
          </Box>
        )}
      </Box>
    </Popover>
  );
}
