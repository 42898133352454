import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from 'src/configs/redux/session/session.action';

export default function Logout() {
  const dispatch = useDispatch();

  useEffect(() => {
    const sessionId = localStorage.getItem('session_id');
    const sessionExpiration = localStorage.getItem('session_expiration');

    if (sessionId && sessionExpiration) {
      dispatch(logout({
        sessionId,
        sessionExpiration,
      }))
        .then(() => localStorage.clear())
        .finally(() => {
          window.location.href = '/login';
        });
    } else {
      window.location.href = '/login';
    }
  }, [dispatch]);

  return null;
}
