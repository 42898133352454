import axios from 'axios';

export default function getApprovals({ page, length, search = '', searchColumn = '', statusFilter = '' }) {
  const start = page * length;
  return new Promise((resolve, reject) => axios
    .get('/account/system-admin/api/v1/approvals',
      {
        params: {
          start,
          length,
          search,
          searchColumn,
          statusFilter,
        },
      })
    .then((response) => resolve(response.data))
    .catch((error) => reject(error.message)));
}
