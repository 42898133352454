import Auth0 from 'auth0-js';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const dbConnection = process.env.REACT_APP_AUTH0_DATABASE_CONNECTION;

export default class Auth0Client {
  constructor() {
    this.auth = new Auth0.WebAuth({
      domain,
      clientID: clientId,
      redirectUri: `${window.location.origin}/auth`,
      audience,
    });
  }

  checkSession() {
    return new Promise((resolve, reject) => {
      this.auth.checkSession(
        {
          responseType: 'code token id_token',
          scope: 'openid profile email',
        },
        (error, authResult) => {
          if (error) reject(error);
          else resolve(authResult);
        },
      );
    });
  }

  login(username, password) {
    return new Promise((resolve, reject) => {
      this.auth.login(
        {
          username,
          password,
          responseType: 'code token id_token',
          realm: dbConnection,
        },
        (error, authResult) => {
          if (error) reject(error);
          else resolve(authResult);
        },
      );
    });
  }

  logout() {
    this.auth.logout({
      clientID: clientId,
      returnTo: `${window.location.origin}`,
    });
  }

  parseHash(hash) {
    return new Promise((resolve, reject) => {
      this.auth.parseHash(
        {
          hash,
          responseType: 'code token id_token',
          realm: dbConnection,
        },
        (error, user) => {
          if (error) reject(error);
          else resolve(user);
        },
      );
    });
  }
}
