import { createSlice } from "@reduxjs/toolkit";
import { getCompanyUsers, postCreditTransfer } from "../action";

const COMPANYUSERS = "companyUsers";
const initialState = {
  totalUsers: 0,
  usersPage: 0,
  users: [],
  isLoading: false,
  errors: [],
};

const companyUsersSlice = createSlice({
  name: COMPANYUSERS,
  initialState,
  reducers: {
    clearCompanyUsers: (state, action) => {
      state.totalUsers = 0;
      state.users = [];
      state.isLoading = true;
    },
    clearErrors: (state, action) => {
      state.errors = [];
    },
  },
  extraReducers: {
    [getCompanyUsers.pending]: (state) => {
      state.isLoading = true;
    },
    [getCompanyUsers.fulfilled]: (state, action) => {
      state.users = action.payload.data;
      state.totalUsers = action.payload.recordsTotal;
      state.usersPage = action.meta.arg.page;
      state.isLoading = false;
    },
    [getCompanyUsers.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [postCreditTransfer.rejected]: (state, action) => {
      state.errors.push(action.error);
    },
  },
});

export const { clearCompanyUsers, clearErrors } = companyUsersSlice.actions;
export const companyUsersReducer = companyUsersSlice.reducer;
