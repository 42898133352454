import React from 'react';
import DateRangePickerComponent from './dateRangePicker.component';

const DateRangePickerContainer = ({
  defaultValue,
  handleDateRangeChange,
}) => (
  <DateRangePickerComponent
    handleDateRangeChange={handleDateRangeChange}
    defaultValue={defaultValue}
  />
);

export default DateRangePickerContainer;
