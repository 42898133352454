import { createAsyncThunk } from '@reduxjs/toolkit';
import companiesApi from '../../../../configs/apis/companies';

export const getCompanyUsers = createAsyncThunk(
  'companies/getCompanyUsers',
  async (payload, { rejectWithValue }) => companiesApi
    .getCompanyUsers({ ...payload,
      query: payload.filter?.searchValue,
      searchColumn: payload.filter?.searchColumn.id,
      status: payload.filter?.status.id,
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error)),
);

export const postCreditTransfer = createAsyncThunk(
  'companies/postCreditTransfer',
  async (payload, { rejectWithValue }) => companiesApi
    .postCreditTransfer(payload)
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error)),
);
