import axios from 'axios';

export default function getCompanyGenerate({ payload }) {
    return new Promise((resolve, reject) => axios
        .get('/serialnumber/system-admin/api/v1/get-generate-count',
            {
                params: {
                    start_date: payload.startDate,
                    end_date: payload.endDate,
                    top20_companies: payload.isTop20Companies,
                    company_ids: payload.companyIds ? payload.companyIds.toString() : ''
                }
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.message)));
}
