import { createAsyncThunk } from "@reduxjs/toolkit";
import companyDashboardApi from "../../../../configs/apis/company-dashboard";

export const getBranchList = createAsyncThunk(
  "companies/getBranchList",
  async (payload, { rejectWithValue }) =>
    companyDashboardApi
      .getBranchList({ payload })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const getMapScanCount = createAsyncThunk(
  "companies/getMapScanCount",
  async (payload, { rejectWithValue }) =>
    companyDashboardApi
      .getMapScanCount({ payload })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const getGraphScanCount = createAsyncThunk(
  "companies/getGraphScanCount",
  async (payload, { rejectWithValue }) =>
    companyDashboardApi
      .getGraphScanCount({ payload })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const getPieChartScanValidity = createAsyncThunk(
  "companies/getPieChartScanValidity",
  async (payload, { rejectWithValue }) =>
    companyDashboardApi
      .getPieChartScanValidity({ payload })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const getTableCountryScanCount = createAsyncThunk(
  "companies/getTableCountryScanCount",
  async (payload, { rejectWithValue }) =>
    companyDashboardApi
      .getTableCountryScanCount({ payload })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const getTableBranchScanCount = createAsyncThunk(
  "companies/getTableBranchScanCount",
  async (payload, { rejectWithValue }) =>
    companyDashboardApi
      .getTableBranchScanCount({ payload })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const getGraphQR = createAsyncThunk(
  "companies/getGraphQR",
  async (payload, { rejectWithValue }) =>
    companyDashboardApi
      .getGraphQR({ payload })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const getTableDailyGeneratedQRCount = createAsyncThunk(
  "companies/getTableDailyGeneratedQRCount",
  async (payload, { rejectWithValue }) =>
    companyDashboardApi
      .getTableDailyGeneratedQRCount({ payload })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const getTableDailyAssignedQRCount = createAsyncThunk(
  "companies/getTableDailyAssignedQRCount",
  async (payload, { rejectWithValue }) =>
    companyDashboardApi
      .getTableDailyAssignedQRCount({ payload })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);
