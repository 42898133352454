import axios from 'axios';

export default function create({
  name,
  email,
  password,
  type,
}) {
  return new Promise((resolve, reject) => axios
    .post(
      '/account/system-admin/api/v1/collaboration-groups',
      {
        name,
        email,
        password,
        type,
      },
    )
    .then((response) => resolve(response.data))
    .catch((error) => reject(error.message)));
}
