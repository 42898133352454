import PropTypes from 'prop-types';
import {
  Card,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Skeleton,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

const DataTableComponent = ({
  isLoading,
  rows,
  rowsPerPage,
  rowsPerPageOption,
  total,
  page,
  renderHeader,
  renderRow,
  onPageClick,
  handleItemPerPageChange,
}) => (
  <Card>
    <TableContainer sx={{ minWidth: '100%' }}>
      <Table>
        <TableHead>
          <TableRow>

            {
              isLoading
                ? (
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                )
                : renderHeader()
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            isLoading
              ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((row) => (
                <TableRow key={JSON.stringify(row)}>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))
              : (
                <>
                  {
                  rows?.length > 0
                    ? rows.map((row, index) => (
                      <TableRow key={JSON.stringify(row)}>
                        {renderRow(row, index)}
                      </TableRow>
                    ))
                    : (
                      <TableRow>
                        <TableCell colSpan={99}>
                          No Data
                        </TableCell>
                      </TableRow>
                    )
                }
                </>
              )
          }
        </TableBody>
      </Table>
    </TableContainer>
    <Box
      sx={{
        padding: 2,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <FormControl sx={{ minWidth: 120 }} size="small">
        <InputLabel id="demo-select-small-label">Items</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          label="Items"
          value={rowsPerPage}
          onChange={(e) => handleItemPerPageChange(e.target.value)}
        >
          {
            rowsPerPageOption ? rowsPerPageOption.map((element, i) => (<MenuItem key={i} value={element}>{element}</MenuItem>))
              : [25].concat([50, 100, 200, 400].filter((e) => (total / (e / 2)) > 1)).map((e, i) => <MenuItem key={i} value={e}>{e}</MenuItem>)
          }
        </Select>
      </FormControl>
      <Pagination
        page={page + 1}
        count={Math.ceil(total / rowsPerPage)}
        onChange={(event, newPage) => onPageClick(newPage - 1)}
      />
    </Box>
  </Card>
);

DataTableComponent.propTypes = {
  isLoading: PropTypes.bool,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  renderHeader: PropTypes.func.isRequired,
  renderRow: PropTypes.func.isRequired,
  onPageClick: PropTypes.func.isRequired,
  handleItemPerPageChange: PropTypes.func,
};

DataTableComponent.defaultProps = {
  isLoading: true,
  rowsPerPage: 10,
};

export default DataTableComponent;
