import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Box,
  Grid,
  Switch,
  TextField,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/CloseRounded";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";
import Loading from "../../../../../components/Loading";

const validationSchema = Yup.object().shape({
  enabled: Yup.boolean(),
  emails: Yup.array()
    .of(Yup.string().email("Invalid email").required("Required"))
    .when("enabled", {
      is: true,
      then: Yup.array().max(10, "Maximum 10 emails"),
    })
    .required("At least one email is required"),
});

export default function NotificationConfigurationDialogComponent({
  handleClose,
  handleSubmit,
  isFetching,
  settings,
}) {
  const { enabled, emails } = settings;

  return (
    <Dialog
      open
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        handleClose();
      }}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="scroll-dialog-title">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body2">
            <b>Notification Configuration</b>
          </Typography>
          <IconButton
            size="small"
            color="inherit"
            onClick={handleClose}
            style={{ padding: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers
        style={{ backgroundColor: "#f3f3f3", padding: 16 }}
      >
        {isFetching ? (
          <Loading />
        ) : (
          <Formik
            initialValues={{ enabled, emails }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, handleChange, handleBlur }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Box display="flex" alignItems="center" height="100%">
                      <Typography variant="body2">Enable Tng Report Notification on Failed</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    <Switch
                      name="enabled"
                      checked={values.enabled}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
                <FieldArray name="emails">
                  {({ push, remove }) => (
                    <Box pt={1}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                          <Typography variant="body2">Recipient Email</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <>
                            {values.emails.map((email, index) => (
                              <Box display="flex" alignContent="center" mt={1}>
                                <Field
                                  as={TextField}
                                  name={`emails.${index}`}
                                  variant="outlined"
                                  size="small"
                                  error={touched.emails?.[index] && Boolean(errors.emails?.[index])}
                                  helperText={touched.emails?.[index] && errors.emails?.[index]}
                                />
                                <Box pl={1}>
                                  <Button
                                    type="button"
                                    variant="contained"
                                    color="error"
                                    onClick={() => remove(index)}
                                    disabled={values.emails.length === 1}
                                  >
                                    -
                                  </Button>
                                </Box>
                              </Box>
                            ))}
                            {values.emails.length < 10 && (
                              <Box pt={1}>
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={() => push('')}
                                >
                                  +
                                </Button>
                              </Box>
                            )}
                          </>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </FieldArray>
                <Box mt={2}>
                  <Button type="submit" color="primary" variant="contained">
                    Submit
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        )}

      </DialogContent>
    </Dialog>
  );
}
