import React from 'react';
import { Line } from 'react-chartjs-2';
import { Box, Card } from "@material-ui/core";
import Loading from 'src/components/Loading';

export default function GraphQRComponent({
  data,
  branchList,
  isLoading,
  error
}) {
  // Get the minimum and maximum date from the data
  const minDate = new Date(Math.min(...Object.keys(data).map((k) => new Date(k))));
  const maxDate = new Date(Math.max(...Object.keys(data).map((k) => new Date(k))));
  const dateDifferenceInDays = Math.ceil((maxDate - minDate) / (1000 * 60 * 60 * 24));
  const numberOfDaysToDisplay = dateDifferenceInDays > 100 ? 14 : dateDifferenceInDays;

  // prevent warning when user choose a date range that is too wide
  const slicedData = Object.fromEntries(
    // Object.entries(data).slice(-numberOfDaysToDisplay)
    Object.entries(data).slice(0)
  );

  // Define a set of 50 constant visible colors
  const constantVisibleColors = [
    'rgb(255, 99, 132)',
    'rgb(54, 162, 235)',
    'rgb(255, 205, 86)',
    'rgb(75, 192, 192)',
    'rgb(153, 102, 255)',
    'rgb(255, 159, 64)',
    'rgb(255, 102, 102)',
    'rgb(255, 204, 102)',
    'rgb(102, 255, 102)',
    'rgb(102, 102, 255)',
    'rgb(255, 51, 51)',
    'rgb(255, 153, 51)',
    'rgb(0, 255, 0)',
    'rgb(0, 0, 255)',
    'rgb(255, 51, 153)',
    'rgb(255, 153, 255)',
    'rgb(51, 153, 255)',
    'rgb(204, 0, 0)',
    'rgb(204, 102, 0)',
    'rgb(0, 204, 0)',
    'rgb(0, 0, 204)',
    'rgb(204, 51, 102)',
    'rgb(204, 102, 153)',
    'rgb(102, 204, 153)',
    'rgb(153, 0, 0)',
    'rgb(153, 76, 0)',
    'rgb(0, 153, 0)',
    'rgb(0, 0, 153)',
    'rgb(153, 38, 76)',
    'rgb(153, 76, 114)',
    'rgb(76, 153, 114)',
    'rgb(102, 0, 0)',
    'rgb(102, 51, 0)',
    'rgb(0, 102, 0)',
    'rgb(0, 0, 102)',
    'rgb(102, 26, 51)',
    'rgb(102, 51, 76)',
    'rgb(51, 102, 76)',
    'rgb(51, 0, 0)',
    'rgb(51, 25, 0)',
    'rgb(0, 51, 0)',
    'rgb(0, 0, 51)',
    'rgb(51, 0, 102)',
    'rgb(51, 0, 51)',
    'rgb(51, 51, 0)',
    'rgb(0, 51, 102)',
    'rgb(0, 51, 51)',
    'rgb(0, 102, 51)',
  ];

  // Generate the x-axis labels
  const generateXAxisLabels = () => {
    const labels = Object.keys(slicedData).map((d) => {
      const date = new Date(d);
      const day = date.getDate();
      const month = date.toLocaleString('default', { month: 'short' });
      return `${day} ${month}`;
    });
    return labels;
  };

  const generateGraphDatasets = () => {
    const line = [];
    const bar = [];

    const getNextVisibleColor = (() => {
      let index = 0;

      return () => {
        index = (index + 1) % constantVisibleColors.length;
        return constantVisibleColors[index];
      };
    })();

    const counts = {};
    Object.values(slicedData).forEach((branches) => {
      Object.entries(branches).forEach(([branchId, { generatedCount, assignedCount }]) => {
        if (!counts[branchId]) {
          counts[branchId] = { generatedCount: [], assignedCount: [] };
        }
        counts[branchId].generatedCount.push(generatedCount);
        counts[branchId].assignedCount.push(assignedCount);
      });
    });

    Object.entries(counts).forEach(([branchId, count]) => {
      const branchName = branchList.filter((b) => b.id === parseInt(branchId, 10))[0].name;
      line.push({
        type: 'line',
        label: `${branchName} Total QR Generated Count`,
        data: count.generatedCount,
        fill: false,
        borderColor: getNextVisibleColor(),
        tension: 0.1,
      });
      bar.push({
        type: 'bar',
        label: `${branchName} Total QR Assigned Count`,
        data: count.assignedCount,
        backgroundColor: getNextVisibleColor(),
        stack: 'stack1',
        yAxisID: 'suspended-y-axis',
      });
    });

    return [...line, ...bar];
  };

  const combinedChartData = {
    labels: generateXAxisLabels(),
    datasets: generateGraphDatasets(),
  };

  const chartOptions = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: 5,
        ticks: {
          precision: 0,
        },
        title: {
          display: true,
          text: 'Scan Count',
        },
        grid: {
          borderColor: 'rgb(75, 192, 192)',
        },
      },
      'suspended-y-axis': {
        position: 'right',
        beginAtZero: true,
        suggestedMax: 5,
        ticks: {
          precision: 0,
        },
        title: {
          display: true,
          text: 'Suspended Count',
        },
        grid: {
          borderColor: 'rgb(255, 99, 132)',
        },
      },
      x: {
        title: {
          min: 5,
          display: true,
          text: 'Date',
        },
        grid: {
          borderColor: 'rgba(0, 0, 0, 0.1)',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      htmlLegend: {
        containerID: 'legend-container',
      },
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        filter: (tooltipItem) => tooltipItem.raw > 0,
      },
    },
  };

  const getOrCreateLegendList = (chart, id) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer.querySelector('ul');

    if (!listContainer) {
      listContainer = document.createElement('ul');
      listContainer.style.display = 'flex';
      listContainer.style.flexDirection = 'column';
      listContainer.style.margin = 0;
      listContainer.style.marginLeft = '16px';
      listContainer.style.padding = 0;

      legendContainer.appendChild(listContainer);
    }

    return listContainer;
  };

  const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart, args, options) {
      const ul = getOrCreateLegendList(chart, `${options.containerID}-qr`);

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }

      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);
      items.forEach((item) => {
        const li = document.createElement('li');
        li.style.alignItems = 'center';
        li.style.cursor = 'pointer';
        li.style.display = 'flex';
        li.style.flexDirection = 'row';
        li.style.marginTop = '10px';

        li.onclick = () => {
          const { type } = chart.config;
          if (type === 'pie' || type === 'doughnut') {
            // Pie and doughnut charts only have a single dataset and visibility is per item
            chart.toggleDataVisibility(item.index);
          } else {
            chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
          }
          chart.update();
        };

        // Color box
        const boxSpan = document.createElement('span');
        boxSpan.style.background = item.fillStyle;
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderStyle = 'solid';
        boxSpan.style.borderWidth = '3px';
        boxSpan.style.display = 'inline-block';
        boxSpan.style.flexShrink = 0;
        boxSpan.style.height = '20px';
        boxSpan.style.marginRight = '10px';
        boxSpan.style.width = '20px';

        // Text
        const textContainer = document.createElement('p');
        textContainer.style.color = item.fontColor;
        textContainer.style.margin = 0;
        textContainer.style.padding = 0;
        textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    }
  };

  const renderContent = () => {
    if (isLoading) return <div style={{ height: '80vh', display: 'grid', placeItems: 'center' }}><Loading /></div>;
    if (error) return <div style={{ height: '80vh', display: 'grid', placeItems: 'center' }}>Error: {error}</div>;
    return (
      <Box style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '80vh' }}>
        <Box flexGrow={1} height="100%">
          <Line data={combinedChartData} options={chartOptions} plugins={[htmlLegendPlugin]} />
        </Box>
        <Box id="legend-container-qr" style={{ width: "300px", height: "100%", overflowY: "auto" }} />
      </Box>
    );
  };

  return (
    <Card
      sx={{
        width: '100%',
        marginTop: '20px',
        padding: '20px',
        borderRadius: '12px',
      }}
    >
      <h3 style={{ fontSize: '24px', fontWeight: 'bolder' }}>Total Generated QR & Total Assigned QR</h3>
      {renderContent()}
    </Card>
  );
}
