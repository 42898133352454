import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  TextField,
  Box,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/CloseRounded";
import PropTypes from "prop-types";

const CompanyDialogComponent = ({
  industries,
  selectedCompany,
  onCompanyDialogCloseClick,
}) => {
  if (!selectedCompany) {
    return null;
  }
  return (
    <Dialog
      open={!!selectedCompany}
      onClose={onCompanyDialogCloseClick}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
    >
      <DialogTitle id="scroll-dialog-title">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2">
            <b>{selectedCompany.company ? selectedCompany.company.name : ""}</b>
          </Typography>
          <IconButton
            size="small"
            color="inherit"
            onClick={onCompanyDialogCloseClick}
            style={{ padding: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Typography
          variant="subtitle2"
          sx={{
            pb: 2,
          }}
        >
          Owner Details
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="First Name"
              name="first_name"
              value={selectedCompany.owner.firstName}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Last Name"
              name="last_name"
              value={selectedCompany.owner.lastName}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Contact"
              name="owner_contact"
              value={selectedCompany.owner.contact}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              name="owner_email"
              value={selectedCompany.owner.email}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Timezone"
              name="owner_time_zone"
              value={selectedCompany.owner.timezone}
              variant="outlined"
              disabled
            />
          </Grid>
        </Grid>
        <Typography
          variant="subtitle2"
          sx={{
            pt: 2,
            pb: 2,
          }}
        >
          Company Details
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Company Name"
              name="company_name"
              value={selectedCompany.company.name}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Plan"
              name="plan"
              value={selectedCompany.company.plan}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="White Label"
              name="whiteLabel"
              value={selectedCompany.company.whiteLabel || ""}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Registration No."
              name="reg_no"
              value={selectedCompany.company.regNo}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Industry"
              name="industry"
              value={
                industries.filter(
                  (i) =>
                    Number(i.id) === Number(selectedCompany.company.industry)
                )[0].name
              }
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={selectedCompany.company.email}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Contact"
              name="contact"
              value={selectedCompany.company.contact}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Line 1"
              name="line1"
              value={selectedCompany.company.addressLine1}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Line 2"
              name="line2"
              value={selectedCompany.company.addressLine2}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="City"
              name="city"
              value={selectedCompany.company.city}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="State"
              name="state"
              value={selectedCompany.company.state}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Country"
              name="country"
              value={selectedCompany.company.country}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Postal Code"
              name="postal_code"
              value={selectedCompany.company.postcode}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Article of Assoclation"
              name="article_assoclation"
              value="Article of Assoclation"
              variant="outlined"
              type="button"
              onClick={() =>
                window.open(
                  selectedCompany.company.articleOfAssoclationUrl,
                  "_blank"
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Director's Identification"
              name="director_identification"
              value="Director's Identification"
              variant="outlined"
              type="button"
              onClick={() =>
                window.open(
                  selectedCompany.company.directorIdentificationUrl,
                  "_blank"
                )
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

CompanyDialogComponent.propTypes = {
  industries: PropTypes.array.isRequired,
  selectedCompany: PropTypes.object,
  onCompanyDialogCloseClick: PropTypes.func.isRequired,
};

export default CompanyDialogComponent;
