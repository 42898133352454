import { createSlice } from "@reduxjs/toolkit";
import { fetchMasterControl, updateControlStatus } from './master-control.action';

const MASTERCONTROL = 'masterControl';
const initialState = {
    controlStatus: [],
};
const masterControlSlice = createSlice({
    name: MASTERCONTROL,
    initialState,
    reducers: {},
    extraReducers: {
        [fetchMasterControl.fulfilled]: (state, action) => {
            const { payload } = action;

            state.recordTotal = payload.recordTotal;
            state.recordFiltered = payload.recordFiltered;
            state.controlStatus = payload.data.map((item) => ({
                name: item.name,
                status: item.is_enabled,
            }));
        }
    },
});

// export const {
// } = masterControlSlice.actions;
export const masterControlReducer = masterControlSlice.reducer;
