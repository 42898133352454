import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import qs from "query-string";
import { useNavigate } from "react-router-dom";
import MainPageComponent from "./main.page";

function MainPageContainer() {
  const [activeIndex, setActiveIndex] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const qsParams = qs.parse(location.search);
  const currentSearch = qsParams.tab || "";

  useEffect(() => {
    let tempIndex = 0;
    switch (currentSearch) {
      case "disbursement":
        tempIndex = 0;
        break;
      default:
        tempIndex = 0;
    }
    setActiveIndex(tempIndex);
  }, [currentSearch]);

  const tabLabels = ["Disbursements", "Transaction Reports"];

  const handleChange = (event, index) => {
    setActiveIndex(index);
    navigate(`/admin/consumer-engagement?tab=disbursement`);
  };

  return (
    <>
      <MainPageComponent
        handleChange={handleChange}
        activeIndex={activeIndex}
        tabLabels={tabLabels}
      />
    </>
  );
}

export default MainPageContainer;
