import { Helmet } from "react-helmet";
import React, { useState } from 'react';
import {
  Box,
  Container,
  Card,
  Typography,
  Switch,
  Skeleton,
} from "@material-ui/core";
import { Formik } from "formik";

export default function MasterControllPage({
  initialValues,
  handleChange,
  isFetching,
}) {
  return (
    <>
      <Helmet>
        <title>Master Control | Kood Asia Management Portal</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Card
            sx={{
              mb: 2,
            }}
          >
            <Typography
              variant="h4"
              sx={{
                padding: 2,
              }}
            >
              Master Control
            </Typography>
          </Card>
          <Box>
            <Card
              sx={{
                mb: 2,
              }}
            >
              {isFetching ? (
                <Card
                  sx={{
                    padding: 2,
                  }}
                >
                  <Skeleton variant="text" animation="wave" height={25} />
                  <Skeleton variant="text" animation="wave" height={25} />
                  <Skeleton variant="text" animation="wave" height={25} />
                  <Skeleton variant="text" animation="wave" height={25} />
                </Card>
              ) : (
                <Formik
                  initialValues={initialValues}
                  enableReinitialize
                >
                  {(formik) => (
                    [{
                      label: "Serial Number Generate",
                      name: "serial number generate",
                      id: 1,
                    },
                    {
                      label: "Serial Number Assign",
                      name: "serial number assign",
                      id: 2,
                    },
                    {
                      label: "Serial Number Unassign",
                      name: "serial number unassign",
                      id: 3,
                    },
                    {
                      label: "Maintenance Mode",
                      name: "maintenance mode",
                      id: 4,
                    },
                    {
                      label: "Maintenance Alert Mode",
                      name: "maintenance alert mode",
                      id: 5,
                    }
                    ].map((field) => (
                      <Box
                        mb={1}
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          my={3}
                          ml={3}
                        >
                          <Typography variant="subtitle1" style={{ width: '250px' }}>
                            {field.label}
                          </Typography>
                          <Box>
                            <Switch
                              checked={formik.values[field.name]}
                              onChange={(event) => {
                                handleChange(
                                  field.id,
                                  event.target.checked
                                );
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    )
                    )
                  )}
                </Formik>
              )}
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
}
