import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading, addAlert } from "src/modules/notification/redux";
import { getDisbursementList } from "../../redux/action/disbursement.action";
import CompaniesPage from "./disbursement.page";
import { resetDisbursementList } from "../../redux";
import UpdateCreditBalanceDialog from "../../components/dialog/update-credit-balance-dialog";
import CreateDisbursementInfoDialog from "../../components/dialog/create-disbursement-info";
import UpdateTransactionLimitDialog from "../../components/dialog/update-transaction-limit-dialog";

export default function CompaniesContainer() {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.disbursement.disbursements.list);
  const totalFiltered = useSelector(
    (state) => state.disbursement.disbursements.totalFiltered
  );
  const isError = useSelector(
    (state) => state.disbursement.disbursements.isError
  );
  const isLoading = useSelector((state) =>
    selectLoading(state, getDisbursementList.typePrefix)
  );
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    search: "",
  });
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedUpdateLimitRecord, setSelectedUpdateLimitRecord] =
    useState(null);
  const [showCreateDialog, setShowCreateDialog] = useState(false);

  const fetchData = () => {
    dispatch(
      getDisbursementList({
        page: tableFilter.page,
        length: tableFilter.rowsPerPage,
        search: tableFilter.search,
      })
    );
  };

  useEffect(() => {
    fetchData();

    return () => {
      dispatch(resetDisbursementList());
    };
  }, [dispatch, tableFilter]);

  const handleSearch = (values) => {
    setTableFilter({
      ...tableFilter,
      page: 0,
      search: values.search,
    });
  };

  const handleChangePage = (event, newPage) => {
    setTableFilter({
      ...tableFilter,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setTableFilter({
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    });
  };

  const handleReload = () => {
    setTableFilter({
      ...tableFilter,
      page: 0,
      search: "",
    });
  };

  const onUpdateCreditBalance = (company) => {
    setSelectedRecord(company);
  };

  const onUpdateTransactionLimit = (company) => {
    setSelectedUpdateLimitRecord(company);
  };

  const onAddCompany = () => {
    setShowCreateDialog(true);
  };

  return (
    <>
      <CompaniesPage
        disbursements={list}
        isLoading={isLoading}
        totalFiltered={totalFiltered}
        tableFilter={tableFilter}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSearch={handleSearch}
        onUpdateCreditBalance={onUpdateCreditBalance}
        onUpdateTransactionLimit={onUpdateTransactionLimit}
        onAddCompany={onAddCompany}
      />
      <UpdateCreditBalanceDialog
        selectedCompany={selectedRecord}
        isOpen={!!selectedRecord}
        handleClose={() => setSelectedRecord(null)}
        handleReload={handleReload}
      />
      <UpdateTransactionLimitDialog
        selectedCompany={selectedUpdateLimitRecord}
        isOpen={!!selectedUpdateLimitRecord}
        handleClose={() => setSelectedUpdateLimitRecord(null)}
        handleReload={handleReload}
      />
      <CreateDisbursementInfoDialog
        isOpen={showCreateDialog}
        handleClose={() => setShowCreateDialog(false)}
        handleReload={handleReload}
      />
    </>
  );
}
