import axios from 'axios';

export default function updateUnassign({ payload }) {
  return new Promise((resolve, reject) => axios
      .post('/distributor/system-admin/api/v1/unassign', {
        node_uuid: payload.nodeUuid,
        codes: payload.qrCodes
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.message))
  );
}
