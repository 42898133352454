import axios from 'axios';

export default function getEmailLog({
  page,
  search = '',
  types = '',
  status = '',
  startDate = '',
  endDate = '',
  length,
}) {
  const start = page * length;

  return new Promise((resolve, reject) => axios
    .get('/notification/system-admin/api/v1/emailLogListing',
      {
        params: {
          start,
          length,
          search,
          types,
          status,
          start_date: startDate,
          end_date: endDate,
        },
      })
    .then((response) => resolve(response.data))
    .catch((error) => reject(error.message)));
}
