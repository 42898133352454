import { setLoading, addAlert } from "src/modules/notification/redux";
import {
  getDisbursementList,
  updateDisbursementInfo,
  createDisbursementInfo,
  getProviderList,
  getCurrencies,
  getCompanyDropdown,
  updateTransactionLimit,
} from "../action/disbursement.action";

const getDisbursementListMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case getDisbursementList.pending.type:
        dispatch(
          setLoading({ id: getDisbursementList.typePrefix, state: true })
        );
        break;
      case getDisbursementList.fulfilled.type:
        dispatch(
          setLoading({ id: getDisbursementList.typePrefix, state: false })
        );
        break;
      case getDisbursementList.rejected.type:
        dispatch(
          setLoading({ id: getDisbursementList.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: payload.code,
          })
        );
        break;
      default:
        break;
    }
  };

const updateDisbursementInfoMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case updateDisbursementInfo.pending.type:
        dispatch(
          setLoading({ id: updateDisbursementInfo.typePrefix, state: true })
        );
        break;
      case updateDisbursementInfo.fulfilled.type:
        dispatch(
          setLoading({ id: updateDisbursementInfo.typePrefix, state: false })
        );
        break;
      case updateDisbursementInfo.rejected.type:
        dispatch(
          setLoading({ id: updateDisbursementInfo.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: payload.code,
          })
        );
        break;
      default:
        break;
    }
  };

const createDisbursementInfoMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case createDisbursementInfo.pending.type:
        dispatch(
          setLoading({ id: createDisbursementInfo.typePrefix, state: true })
        );
        break;
      case createDisbursementInfo.fulfilled.type:
        dispatch(
          setLoading({ id: createDisbursementInfo.typePrefix, state: false })
        );
        break;
      case createDisbursementInfo.rejected.type:
        dispatch(
          setLoading({ id: createDisbursementInfo.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: payload.code,
          })
        );
        break;
      default:
        break;
    }
  };

const getProviderListMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case getProviderList.pending.type:
        dispatch(setLoading({ id: getProviderList.typePrefix, state: true }));
        break;
      case getProviderList.fulfilled.type:
        dispatch(setLoading({ id: getProviderList.typePrefix, state: false }));
        break;
      case getProviderList.rejected.type:
        dispatch(setLoading({ id: getProviderList.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: payload.code,
          })
        );
        break;
      default:
        break;
    }
  };

const getCurrenciesMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case getCurrencies.pending.type:
        dispatch(setLoading({ id: getCurrencies.typePrefix, state: true }));
        break;
      case getCurrencies.fulfilled.type:
        dispatch(setLoading({ id: getCurrencies.typePrefix, state: false }));
        break;
      case getCurrencies.rejected.type:
        dispatch(setLoading({ id: getCurrencies.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: payload.code,
          })
        );
        break;
      default:
        break;
    }
  };

const getCompanyDropdownMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case getCompanyDropdown.pending.type:
        dispatch(
          setLoading({ id: getCompanyDropdown.typePrefix, state: true })
        );
        break;
      case getCompanyDropdown.fulfilled.type:
        dispatch(
          setLoading({ id: getCompanyDropdown.typePrefix, state: false })
        );
        break;
      case getCompanyDropdown.rejected.type:
        dispatch(
          setLoading({ id: getCompanyDropdown.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: payload.code,
          })
        );
        break;
      default:
        break;
    }
  };

const updateTransactionLimitMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case updateTransactionLimit.pending.type:
        dispatch(
          setLoading({ id: updateTransactionLimit.typePrefix, state: true })
        );
        break;
      case updateTransactionLimit.fulfilled.type:
        dispatch(
          setLoading({ id: updateTransactionLimit.typePrefix, state: false })
        );
        break;
      case updateTransactionLimit.rejected.type:
        dispatch(
          setLoading({ id: updateTransactionLimit.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: payload.code,
          })
        );
        break;
      default:
        break;
    }
  };

export default [
  getDisbursementListMiddleware,
  updateDisbursementInfoMiddleware,
  createDisbursementInfoMiddleware,
  getProviderListMiddleware,
  getCurrenciesMiddleware,
  getCompanyDropdownMiddleware,
  updateTransactionLimitMiddleware,
];
