import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotificationConfigurationDialogComponent from "./notificationConfigurationDialog.component";
import { selectLoading } from "../../../../notification";
import { getNotificationSettings, setNotificationSettings } from "../../../redux";

const NotificationConfigurationDialogContainer = ({ handleClose }) => {
  const dispatch = useDispatch();

  const settings = useSelector((state) => state.transactionReport.settings);
  const isFetching = useSelector((state) => selectLoading(state, getNotificationSettings.typePrefix));

  useEffect(() => {
    dispatch(getNotificationSettings());
  }, [dispatch]);

  const handleSubmit = async (values) => {
    const { enabled, emails } = values;
    await dispatch(setNotificationSettings({
      enabled: enabled ? "1" : "0",
      emails: emails.join(","),
    }));
    handleClose();
  };

  return (
    <NotificationConfigurationDialogComponent
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isFetching={isFetching}
      settings={settings}
    />
  );
};

export default NotificationConfigurationDialogContainer;
