import { Box, ButtonBase, Typography } from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon, ArrowDropUp as ArrowDropUpIcon } from "@material-ui/icons";
import { makeStyles } from '@material-ui/styles';

const useStyle = makeStyles((theme) => ({
  root: {
    marginRight: '20px',
    padding: '5px 12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '4px',
    height: '35px',
    width: '160px',
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    border: '2px solid #D0D5DD',
  },
  inputBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  inputText: {
    fontSize: '0.8rem',
    color: 'rgba(0, 0, 0, 0.6)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }
}));

export default function BranchSelectComponent({
  open,
  handleClick,
  branchList,
  branchListFilter,
  disabled,
}) {
  const classes = useStyle();

  return (
    <ButtonBase className={classes.root} disabled={disabled} onClick={handleClick}>
      <Box className={classes.inputBox} style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}>
        <Typography className={classes.inputText}>
          {
            branchListFilter.length === branchList.length ?
            'All Branches' :
            `${branchListFilter.length} Branches`
          }
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {
            open ?
              <ArrowDropUpIcon /> :
              <ArrowDropDownIcon />
          }
        </Box>
      </Box>
    </ButtonBase>
  );
}
