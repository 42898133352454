import {
  createAsyncThunk,
} from '@reduxjs/toolkit';
import authApi from 'src/configs/apis/authentication';
import Auth0Client from '../../auth0';

export const checkSession = createAsyncThunk(
  'session/checkSession',
  (payload, { rejectWithValue }) => authApi.checkSession({
    sessionId: payload.sessionId,
    sessionExpiration: payload.sessionExpiration,
  })
    .then((response) => response.data.data)
    .catch((error) => rejectWithValue(error.response.data)),
);

export const login = createAsyncThunk(
  'session/login',
  (payload, { rejectWithValue }) => authApi.signIn({
    username: payload.username,
    password: payload.password,
  })
    .then((response) => response.data.data)
    .catch((error) => rejectWithValue(error.response.data)),
);

export const logout = createAsyncThunk(
  'session/logout',
  (payload, { rejectWithValue }) => authApi.signOut({
    sessionId: payload.sessionId,
    sessionExpiration: payload.sessionExpiration,
  })
    .then((response) => response.data.data)
    .catch((error) => rejectWithValue(error.response.data)),
);

export const parseHash = createAsyncThunk(
  'session/parseHash',
  (payload, { rejectWithValue }) => new Auth0Client()
    .parseHash(payload.hash)
    .then((response) => response)
    .catch((error) => rejectWithValue(error)),
);
