import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Grid,
  TextField,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  FormHelperText,
  DialogActions,
  Typography,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const UserCreditTransferComponent = ({
  //  👇  Credit Transfer Dialog  👇  //
  selectedCompanyUser,
  onCreditTransferCloseClick,
  //  👇  Credit Transfer Confirmation Dialog  👇  //
  openCreditTransferConfirmation,
  onCreditTransferConfirmationClick,
  onCreditTransferConfirmSubmitClick,
  isSubmitting,
  onCreditTransferConfirmationCloseClick,
  //  👇  Credit Transfer Complete Dialog  👇  //
  completeDialogErrors,
  openCompleteDialog,
  onCompleteDialogClose,
}) => {
  const actions = [
    'credit',
    'debit',
  ];

  const calculateNewBalance = (amount, action) => {
    const currentBalance = selectedCompanyUser?.creditInfo?.amount || 0;
    if (action === 'credit') {
      return currentBalance + amount;
    }
    if (action === 'debit') {
      return currentBalance - amount;
    }
    return currentBalance;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      balance: selectedCompanyUser?.creditInfo ? selectedCompanyUser?.creditInfo.amount : 0,
      creditTransferAction: 'credit',
      amount: 0,
      confirmAmount: 0,
      newBalance: 0,
      remark: '',
    },
    initialErrors: {
      amount: 'Positive Amount Number is required',
      confirmAmount: 'Positive Confirm Amount Number is required',
      remark: 'Remark is required',
    },
    validationSchema: Yup.object().shape({
      creditTransferAction: Yup.string().required('Action is required'),
      amount: Yup.number().min(1).required('Positive Amount Number is required').typeError('Invalid number'),
      confirmAmount: Yup.number().oneOf([Yup.ref('amount'), null], 'Amount must match'),
      newBalance: Yup.number().min(0),
      remark: Yup.string().required('Remark is required'),
    }),
    onSubmit: (values) => {
      onCreditTransferConfirmSubmitClick(values, formik);
    },
  });

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const pattern = /^[0-9]*$/;

    if (!pattern.test(inputValue)) {
      // If the input contains non-numeric characters, prevent the input and return.
      event.preventDefault();
      return;
    }

    const parsedValue = parseInt(inputValue, 10);

    if (!Number.isNaN(parsedValue)) {
      const finalValue = parsedValue === 0 ? 1 : parsedValue;
      formik.setFieldValue(event.target.name, finalValue);
    }
  };

  useEffect(() => {
    const newBalance = calculateNewBalance(formik.values.amount, formik.values.creditTransferAction);
    formik.setFieldValue('newBalance', newBalance);
  }, [formik.values.amount, formik.values.creditTransferAction, formik.values.remark], []);

  return (
    <>
      <Dialog
        open={!!selectedCompanyUser}
        onClose={() => {
          onCreditTransferCloseClick();
          formik.resetForm();
        }}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <form>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '1rem' }}>
            <Typography id="scroll-dialog-title" sx={{ fontWeight: '500', fontSize: 'large' }}>{`${selectedCompanyUser?.username} Credit Transfer`}</Typography>
            <Typography
              id="scroll-dialog-title"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                onCreditTransferCloseClick();
                formik.resetForm();
              }}
            >
              ✖️
            </Typography>
          </Box>
          <DialogContent
            dividers
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Typography marginBottom={1} fontFamily="Roboto" display="inline">Current Balance: </Typography>
                <Typography fontFamily="Roboto" display="inline">{selectedCompanyUser?.creditInfo ? formik.values.balance : '0  (No Wallet)'}</Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormLabel style={{ color: formik.errors.creditTransferAction ? '#d32f2f' : 'inherit' }}>
                  Credit Transfer Action
                </FormLabel>
                <RadioGroup
                  name="creditTransferAction"
                  value={formik.values.creditTransferAction}
                  onChange={formik.handleChange}
                >
                  {
                    actions.map((action) => (
                      <FormControlLabel
                        key={action}
                        value={action}
                        control={<Radio disabled={formik.isSubmitting || (action === 'debit' && !selectedCompanyUser?.creditInfo)} />}
                        label={action.charAt(0).toUpperCase() + action.slice(1)}
                      />
                    ))
                  }
                </RadioGroup>
                <FormHelperText error={!!formik.errors.creditTransferAction}>{formik.errors.creditTransferAction}</FormHelperText>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  label="Amount"
                  name="amount"
                  type="text"
                  value={formik.values.amount}
                  onChange={handleInputChange}
                  error={!!formik.errors.amount}
                  helperText={formik.errors.amount}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  label="Confirm Amount"
                  name="confirmAmount"
                  type="number"
                  value={formik.values.confirmAmount}
                  onChange={handleInputChange}
                  error={!!formik.errors.confirmAmount}
                  helperText={formik.errors.confirmAmount}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} sx={{ color: formik.values.newBalance < 0 && '#d32f2f' }}>
                <Typography variant="h4" marginBottom={1} fontFamily="Roboto" display="inline">New Balance: </Typography>
                <Typography variant="h4" fontFamily="Roboto" display="inline">
                  {formik.values.newBalance}
                </Typography>
                {formik.errors.newBalance && (
                  <Typography
                    variant="h4"
                    sx={{
                      marginLeft: '1rem',
                      marginTop: '5px',
                      fontFamily: 'sans-serif',
                      color: '#d32f2f',
                      fontSize: '0.8rem',
                    }}
                  >
                    {formik.errors.newBalance}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  label="Remark"
                  name="remark"
                  multiline
                  rows={4}
                  value={formik.values.remark}
                  onChange={formik.handleChange}
                  error={!!formik.errors.remark}
                  helperText={formik.errors.remark}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ padding: 2 }}>
            <Button
              variant="contained"
              type="button"
              onClick={onCreditTransferConfirmationClick}
              disabled={!!Object.keys(formik.errors).length}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* CONFIRMATION DIALOG */}
      <Dialog
        open={openCreditTransferConfirmation}
        onClose={onCreditTransferConfirmationCloseClick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle>
          <Typography id="scroll-dialog-title" sx={{ fontWeight: '500', fontSize: 'large' }}>Credit Transfer Confirmation</Typography>
        </DialogTitle>
        <DialogContent
          dividers
        >
          <Typography variant="h3" sx={{ marginBottom: 3, color: 'red' }} align="center">
            Credit Transfer Confirmation
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 3 }}>
            <Typography variant="h4">Username:</Typography>
            <Typography variant="h4">{selectedCompanyUser?.username}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 3 }}>
            <Typography variant="h4">Current Balance:</Typography>
            <Typography variant="h4">{formik.values.balance}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 3 }}>
            <Typography variant="h4">Amount:</Typography>
            <Typography variant="h4" sx={{ color: formik.values.creditTransferAction === 'debit' ? 'red' : 'green' }}>
              {`${formik.values.creditTransferAction === 'debit' ? '-' : ''} ${formik.values.amount}`}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 3,
            }}
          >
            <Typography variant="h3">New Balance:</Typography>
            <Typography variant="h3" sx={{ color: formik.values.creditTransferAction === 'debit' ? 'red' : 'green' }}>{formik.values.newBalance}</Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: 2, justifyContent: 'center' }}>
          <Button
            sx={{ fontSize: '18px', width: '200px', height: '55px' }}
            variant="contained"
            type="button"
            color="error"
            onClick={onCreditTransferConfirmationCloseClick}
          >
            Cancel
          </Button>
          <Button
            sx={{ fontSize: '18px', width: '200px', height: '55px' }}
            variant="contained"
            type="submit"
            onClick={formik.handleSubmit}
          >
            {isSubmitting ? (
              <>
                {' Confirming...'}
                &nbsp;&nbsp;
                <CircularProgress color="inherit" size="1.5rem" />
              </>
            ) : (
              'Confirm'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* COMPLETE DIALOG */}
      <Dialog
        open={openCompleteDialog}
        onClose={onCompleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ backgroundColor: completeDialogErrors.length > 0 ? '#ff3333' : '#4BB543', color: 'white' }}>
          <Box
            align="center"
            margin={3}
            sx={{
              borderRadius: '1000px',
              width: '4rem',
              height: '4rem',
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginX: 'auto',
            }}
          >
            <Typography variant="h1" align="center" margin={3} color={completeDialogErrors.length > 0 ? '#ff3333' : '#4BB543'}>
              {completeDialogErrors.length > 0 ? '✘' : '✔'}
            </Typography>
          </Box>
          <Typography variant="h3" align="center" margin={3}>
            {`Credit Transfer ${completeDialogErrors.length > 0 ? 'Failed' : 'Complete'}`}
          </Typography>
          <Typography variant="body2" align="center" margin={3}>
            ( Click outside to close )
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UserCreditTransferComponent;
