import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { login } from '../../../../configs/redux/session/session.action';
import LoginPage from './login.page';

export default function LoginContainer() {
  const dispatch = useDispatch();
  const refCaptcha = useRef(null);
  const onLoginClick = async (values, actions) => {
    actions.setSubmitting(true);
    refCaptcha.current.values = values;
    refCaptcha.current.callbacks.execute();
  };
  const onRecaptchaError = (actions) => {
    actions.setSubmitting(false);
  };
  const onRecaptchaResolve = (actions) => {
    actions.setSubmitting(true);
    dispatch(login({
      username: refCaptcha.current.values.username,
      password: refCaptcha.current.values.password,
    }))
      .then(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <LoginPage
      refCaptcha={refCaptcha}
      onLoginClick={onLoginClick}
      onRecaptchaError={onRecaptchaError}
      onRecaptchaResolve={onRecaptchaResolve}
    />
  );
}
