import React from "react";
import {
  Box,
  Paper,
  InputBase,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  TableCell,
  Button, Input,
} from "@material-ui/core";
import DataTable from "src/components/DataTable";
import { Formik } from "formik";
import UserCreditTransferContainer from "../../forms/user-credit-transfer";
import SearchableSelect from "../../../../../components/SearchableSelect";

const CompanyUsersComponent = ({
  //  👇  Company Users Dialog  👇  //
  selectedCompany,
  companyUsers,
  totalUsers,
  currentUsersPage,
  onCompanyUsersPageClick,
  isCompanyUsersLoading,
  onCompanyUsersCloseClick,
  rowsPerPage,
  rowsPerPageOption,
  handleItemPerPageChange,
  query,
  onSearchSubmit,
  onClearFilter,
  //  👇  Credit Transfer Dialog  👇  //
  selectedCompanyUser,
  onCreditTransferClick,
  onCreditTransferCloseClick,
  //  👇  Credit Transfer Confirmation Dialog  👇  //
  openCreditTransferConfirmation,
  onCreditTransferConfirmationClick,
  onCreditTransferConfirmSubmitClick,
  isSubmitting,
  onCreditTransferConfirmationCloseClick,
  //  👇  Credit Transfer Complete Dialog  👇  //
  completeDialogErrors,
  openCompleteDialog,
  onCompleteDialogClose,
}) => {
  if (!selectedCompany) {
    return null;
  }

  const getCreditAmount = (row) => {
    if (selectedCompany.companyPlan === "Free Saas") {
      return "-";
    }
    return row.creditInfo ? row.creditInfo.amount : 0;
  };

  return (
    <>
      <Dialog
        open={!!selectedCompany}
        onClose={() => {
          onCompanyUsersCloseClick();
          handleItemPerPageChange(10);
        }}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="lg"
        fullWidth
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: "1rem",
          }}
        >
          <DialogTitle id="scroll-dialog-title">Users</DialogTitle>
          <Typography
            id="scroll-dialog-title"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              onCompanyUsersCloseClick();
              handleItemPerPageChange(10);
            }}
          >
            ✖️
          </Typography>
        </Box>
        <DialogContent dividers style={{ backgroundColor: "#f3f3f3" }}>
          <Paper
            sx={{
              p: "2px 6px",
              mb: "15px",
              display: "flex",
              alignItems: "center",
              width: "max",
            }}
          >
            <Formik
              initialValues={{
                searchColumn: query.searchColumn,
                searchValue: query.searchValue,
                status: query.status,
              }}
              enableReinitialize
              onSubmit={onSearchSubmit}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                  <Box display="flex" alignItems="center" pr={1} width="100%">
                    <Box style={{ height: "fit-content" }}>
                      <SearchableSelect
                        value={formik.values.searchColumn}
                        handleChange={(value) => {
                          formik.setFieldValue('searchColumn', value);
                        }}
                        placeholder="Column"
                        searchable={false}
                        style={{ height: '34px', borderBottomRightRadius: 0, borderTopRightRadius: 0, borderRight: 'solid 1px #000000' }}
                        panelStyle={{ width: '249px' }}
                        dropdownItem={[
                          { name: 'Username', id: 'username' },
                          { name: 'Email', id: 'email' },
                          { name: 'Name', id: 'name' }
                        ]}
                        emptyMessage="Column Not Found"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        isEmpty={formik.values.searchColumn === ''}
                        returnType="object"
                      />
                    </Box>
                    <Box style={{ height: "fit-content" }}>
                      <Input
                        style={{
                          width: '20vw',
                          borderBottomLeftRadius: 0,
                          borderTopLeftRadius: 0,
                        }}
                        hiddenLabel
                        variant="filled"
                        size="small"
                        placeholder="Search"
                        sx={{ mr: 1, mb: 1, mt: 1 }}
                        disableUnderline="true"
                        inputProps={{
                          disableUnderline: true,
                          margin: 'none',
                          style: {
                            paddingTop: '6px',
                            paddingBottom: '6px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            borderRadius: '4px',
                            backgroundColor: '#eceff0',
                          },
                        }}
                        {...formik.getFieldProps('searchValue')}
                      />
                    </Box>
                    <Box style={{ height: "fit-content" }} pr={1}>
                      <SearchableSelect
                        value={formik.values.status}
                        handleChange={(value) => {
                          formik.setFieldValue('status', value);
                        }}
                        placeholder="Status"
                        disabled={isCompanyUsersLoading}
                        searchable={false}
                        style={{ height: '34px' }}
                        panelStyle={{ width: '249px' }}
                        dropdownItem={[
                          { name: 'All Status', id: '' },
                          { name: 'Active', id: 'active' },
                          { name: 'Suspended', id: 'suspend' },
                          { name: 'Pending', id: 'pending' }
                        ]}
                        emptyMessage="Status Not Found"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        isEmpty={formik.values.status === ''}
                        returnType="object"
                      />
                    </Box>
                    <Box width="100%" flexGrow={1} />
                    <Box pr={1}>
                      <Button
                        variant="contained"
                        disableElevation
                        color="secondary"
                        size="small"
                        onClick={onClearFilter}
                        disabled={isCompanyUsersLoading}
                      >
                        Clear
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        variant="contained"
                        disableElevation
                        color="primary"
                        size="small"
                        type="submit"
                        disabled={isCompanyUsersLoading}
                      >
                        Search
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Paper>
          <DataTable
            isLoading={isCompanyUsersLoading}
            rows={companyUsers}
            rowsPerPage={rowsPerPage}
            rowsPerPageOption={rowsPerPageOption}
            total={totalUsers}
            page={currentUsersPage}
            renderHeader={() => (
              <>
                <TableCell>ID</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>User Type</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>QR Credit Balance</TableCell>
                <TableCell>Action</TableCell>
              </>
            )}
            renderRow={(row, index) => (
              <>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{row.username}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.userType}</TableCell>
                <TableCell>{row.role ? row.role : "-"}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{getCreditAmount(row)}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    onClick={() => onCreditTransferClick(row)}
                    disabled={selectedCompany.companyPlan === "Free Saas"}
                  >
                    Credit Transfer
                  </Button>
                </TableCell>
              </>
            )}
            onPageClick={onCompanyUsersPageClick}
            handleItemPerPageChange={handleItemPerPageChange}
          />
        </DialogContent>
      </Dialog>

      <UserCreditTransferContainer
        //  👇  Credit Transfer Dialog  👇  //
        selectedCompanyUser={selectedCompanyUser}
        onCreditTransferCloseClick={onCreditTransferCloseClick}
        //  👇  Credit Transfer Confirmation Dialog  👇  //
        openCreditTransferConfirmation={openCreditTransferConfirmation}
        onCreditTransferConfirmationClick={onCreditTransferConfirmationClick}
        onCreditTransferConfirmSubmitClick={onCreditTransferConfirmSubmitClick}
        isSubmitting={isSubmitting}
        onCreditTransferConfirmationCloseClick={
          onCreditTransferConfirmationCloseClick
        }
        //  👇  Credit Transfer Complete Dialog  👇  //
        completeDialogErrors={completeDialogErrors}
        openCompleteDialog={openCompleteDialog}
        onCompleteDialogClose={onCompleteDialogClose}
      />
    </>
  );
};

export default CompanyUsersComponent;
