import React, { useState } from 'react';
import CompanySelectFieldComponent from './companySelectField.component';
import CompanySelectPopoverComponent from './companySelectPopover.component';

function CompanySelectFieldContainer({
  searchable,
  value,
  placeholder,
  searchPlaceholder,
  handleChange,
  disabled,
  isFetching,
  panelStyle,
  dropdownItem,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  style,
  textStyle,
  isEmpty,
  isClearable,
  handleClear,
  emptyMessage,
  handleSelectAll,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const open = Boolean(anchorEl);

  const handleSearch = (valuee) => {
    setSearchText(valuee.toLowerCase());
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    handleSearch('');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAll = async () => {
    handleSelectAll(!selectAll);
    setSelectAll(!selectAll);
  };

  const handleTop20 = () => {
    handleSelectAll(false);
    setSelectAll(false);
  };

  return (
    <>
      <CompanySelectFieldComponent
        handleClick={handleClick}
        handleClear={handleClear}
        value={value}
        isEmpty={isEmpty}
        style={style}
        disabled={disabled}
        placeholder={placeholder}
        open={open}
        textStyle={textStyle}
        isClearable={isClearable}
      />
      <CompanySelectPopoverComponent
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        style={panelStyle}
        items={dropdownItem}
        handleSearch={handleSearch}
        isFetching={isFetching}
        searchable={searchable}
        handleChange={handleChange}
        searchText={searchText}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={PaperProps}
        value={value}
        emptyMessage={emptyMessage}
        placeholder={searchPlaceholder}
        selectAll={selectAll}
        handleAll={handleAll}
        handleTop20={handleTop20}
      />
    </>
  );
}

export default CompanySelectFieldContainer;
