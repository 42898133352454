import { getDefaultMiddleware } from "@reduxjs/toolkit";
import dashboardMdl from "src/modules/dashboard/redux/middleware/dashboard.mdl";
import companiesMdl from "src/modules/companies/redux/middleware";
import engagementMiddleware from "src/modules/consumer-engagement/redux/middleware";
import approvalMdl from "src/modules/approvals/redux/middleware";
import loadingMiddleware from "../redux/loading/loading.middleware";
import sessionMiddleware from "../redux/session/session.middleware";
import transactionReportsMdl from "../../modules/consumer-engagement/redux/middleware/transactionReports.mdl";

const middlewares = getDefaultMiddleware().concat([
  ...loadingMiddleware,
  ...sessionMiddleware,
  ...dashboardMdl,
  ...companiesMdl,
  ...approvalMdl,
  ...engagementMiddleware,
  ...transactionReportsMdl,
]);

export default middlewares;
