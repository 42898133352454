/* eslint-disable no-confusing-arrow */
import React, { Fragment } from "react";
import {
  ButtonBase,
  Popper,
  Grow,
  Box,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Typography,
  Divider,
  Tooltip,
} from "@material-ui/core";
import clsx from "clsx";
import {
  MoreHoriz as MoreHorizIcon,
  MoreVert as MoreVertIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

const style = (theme) => ({
  root: {
    // height: 21,
    // backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(0.5),
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
  },
  paper: {
    backgroundColor: "#3A4D54",
    color: theme.palette.common.white,
    borderRadius: "4px",
  },
  boxSubmenu: {
    "&::before": {
      content: '""',
      position: "absolute",
      width: "0",
      height: "0",
      top: "8px",
      right: "-15px",
      border: "8px solid",
      borderColor: "transparent transparent transparent",
      borderLeftColor: "#3A4D54",
      zIndex: 999,
    },
  },
  menuItem: {
    fontSize: "small",
    display: "block",
  },
  menuItemRequired: {
    flexFlow: "column",
    placeItems: "flex-start",
    color: "rgba(255, 255, 255, 0.33)",
  },
  divider: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    margin: "0px -16px 6px -16px",
  },
  dividerStyle2: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    margin: "0px 0px 6px 0px",
  },
  errorText: {
    color: "#FD646F",
    fontWeight: 700,
  },
  // open: {
  //   backgroundColor: theme.palette.primary.main,
  //   color: "#fff"
  // },
  // closed: {
  //   backgroundColor: theme.palette.divider
  // }
});

const useStyles = makeStyles(style);

export default function ActionDetailButtonComponent({
  variant,
  isOpen,
  handleToggleOpen,
  anchorRef,
  handleListKeyDown,
  handleClose,
  actionItems,
  popperStyle,
  disablePortal,
}) {
  const classes = useStyles();

  return (
    <Box>
      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        disablePortal={disablePortal}
        role={undefined}
        style={popperStyle}
        transition
        placement="left-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "start top" : "start bottom",
            }}
          >
            <Box className={classes.boxSubmenu}>
              <Paper className={classes.paper}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={isOpen}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {actionItems.map((item, index) =>
                      item.tooltip ? (
                        <Tooltip title={item.tooltip} key={index}>
                          <span>
                            <MenuItem
                              key={index}
                              className={clsx(classes.menuItem, {
                                [classes.menuItemRequired]: item.required,
                                [classes.errorText]: item.errorText,
                              })}
                              onClick={(e) => {
                                handleClose(e);
                                item.action();
                              }}
                              disabled={item.disabled}
                            >
                              {item.divider && (
                                <Divider className={classes.dividerStyle2} />
                              )}
                              {item.required ? (
                                <Divider className={classes.divider} />
                              ) : null}
                              {item.label}

                              {item.required ? (
                                <div style={{ display: "flex" }}>
                                  <img
                                    src="/static/images/require.svg"
                                    alt="required"
                                  />
                                  <Typography
                                    variant="caption"
                                    style={{
                                      color: "#FD646F",
                                      paddingLeft: "4px",
                                    }}
                                  >
                                    {item.requiredText}
                                  </Typography>
                                </div>
                              ) : null}
                            </MenuItem>
                          </span>
                        </Tooltip>
                      ) : (
                        <MenuItem
                          key={index}
                          className={clsx(classes.menuItem, {
                            [classes.menuItemRequired]: item.required,
                            [classes.errorText]: item.errorText,
                          })}
                          onClick={(e) => {
                            handleClose(e);
                            item.action();
                          }}
                          disabled={item.disabled}
                        >
                          {item.divider && (
                            <Divider className={classes.dividerStyle2} />
                          )}
                          {item.required ? (
                            <Divider className={classes.divider} />
                          ) : null}
                          {item.label}

                          {item.required ? (
                            <div style={{ display: "flex" }}>
                              <img
                                src="/static/images/require.svg"
                                alt="required"
                              />
                              <Typography
                                variant="caption"
                                style={{
                                  color: "#FD646F",
                                  paddingLeft: "4px",
                                }}
                              >
                                {item.requiredText}
                              </Typography>
                            </div>
                          ) : null}
                        </MenuItem>
                      )
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Box>
          </Grow>
        )}
      </Popper>

      <ButtonBase
        className={clsx(classes.root, {
          [classes.open]: isOpen,
          [classes.closed]: !isOpen,
        })}
        ref={anchorRef}
        onClick={handleToggleOpen}
      >
        {variant === "horizontal" ? <MoreHorizIcon /> : <MoreVertIcon />}
      </ButtonBase>
    </Box>
  );
}
