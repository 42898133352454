import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useJsApiLoader } from '@react-google-maps/api';
import { setMapLoaded } from 'src/modules/dashboard/redux/slice/dashboard.slice';
import MapScanCountComponent from './mapScanCount.component';

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const MapScanCountContainer = () => {
  const mapScans = useSelector((state) => state.dashboard.mapScans.data);
  const isLoading = useSelector((state) => state.dashboard.mapScans.isLoading);
  const error = useSelector((state) => state.dashboard.mapScans.error);
  const [map, setMap] = useState(null);

  const dispatch = useDispatch();
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY,
  });

  const center = { lat: 3.1319, lng: 101.6841 };

  // Async function placed here
  async function initMap(mapInstance) {
    const bounds = new window.google.maps.LatLngBounds(center);
    let hasScanHistory = false;
    if (mapScans && Array.isArray(mapScans)) {
      mapScans.forEach((item) => {
        if (item.latitude && item.longitude) {
          bounds.extend({
            lat: parseFloat(item.latitude),
            lng: parseFloat(item.longitude),
          });
        }
      });
      hasScanHistory = true;
    }
    if (hasScanHistory) {
      mapInstance.fitBounds(bounds);
      mapInstance.setZoom(5.0);
    }
  }

  useEffect(() => {
    if (map) {
      initMap(map);
    }
  }, [mapScans]);

  const onLoad = useCallback((mapInstance) => {
    mapInstance.setOptions({
      mapId: '898db239baad3210',
    });

    setMap(mapInstance);
    initMap(mapInstance);
  }, []);

  const onUnmount = React.useCallback((mapInstance) => {
    setMap(null);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      dispatch(setMapLoaded());
    }
  }, [isLoaded, dispatch, setMapLoaded]);

  useEffect(() => {
    if (loadError) {
      console.error('Error loading Google Maps:', loadError);
    }
  }, [loadError]);

  return (
    <MapScanCountComponent
      googleMapsAPIKey={API_KEY}
      onLoad={onLoad}
      isLoading={isLoading}
      error={error}
      isLoaded={isLoaded}
      center={center}
      onUnmount={onUnmount}
      data={mapScans}
    />
  );
};

export default MapScanCountContainer;
