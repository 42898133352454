import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from "@reduxjs/toolkit";
import MasterControllPage from './master-control.page';
import MasterControlDialog from '../../components/dialogs/master-control-dialog.container';
import AlertIcon from '../../assets/svg/alert.svg';
import { fetchMasterControl, updateControlStatus } from '../../redux/master-control.action';

export default function MasterControlContainer() {
  const dispatch = useDispatch();
  const isFetchingMasterControl = useSelector((state) => state.loading.inProcess.filter((p) => p.id === fetchMasterControl.typePrefix).length > 0);
  const isUpdatingMasterControl = useSelector((state) => state.loading.inProcess.filter((p) => p.id === updateControlStatus.typePrefix).length > 0);
  const controlStates = useSelector((state) => state.masterControl.controlStatus);

  const [controls, setControls] = useState([]);
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchMasterControl());
  }, [dispatch]);

  const initialValues = controlStates.reduce((accumulator, item) => {
    const newAcc = { ...accumulator };
    newAcc[item.name] = item.status;
    return newAcc;
  }, {});

  const handleChange = (id, status) => {
    setControls([{ id, is_enabled: status }]);
    if (status === true) {
      setIsActivateDialogOpen(true);
    } else {
      setIsDeactivateDialogOpen(true);
    }
  };

  const handleStatusSubmit = () => {
    dispatch(updateControlStatus(controls))
      .then(unwrapResult)
      .then(() => {
        setIsDeactivateDialogOpen(false);
        setIsActivateDialogOpen(false);
        dispatch(fetchMasterControl());
      });
  };

  return (
    <>
      <MasterControllPage
        isFetching={isFetchingMasterControl}
        initialValues={initialValues}
        controlStates={controlStates}
        handleChange={handleChange}
      />
      <MasterControlDialog
        isLoading={isUpdatingMasterControl}
        isOpen={isActivateDialogOpen}
        handleClose={() => setIsActivateDialogOpen(false)}
        handleProceed={() => handleStatusSubmit(true)}
        title="Important!"
        description="Are you sure you want to turn this on?"
        icon={<img src={AlertIcon} alt="" />}
        type="danger"
      />
      <MasterControlDialog
        isLoading={isUpdatingMasterControl}
        isOpen={isDeactivateDialogOpen}
        handleClose={() => setIsDeactivateDialogOpen(false)}
        handleProceed={() => handleStatusSubmit(false)}
        title="Important!"
        description="Are you sure you want to turn this off?"
        icon={<img src={AlertIcon} alt="" />}
        type="danger"
      />
    </>
  );
}
