import React from 'react';
import { useSelector } from 'react-redux';
import TableDailyAssignedQRComponent from './table-daily-assigned-qr.component';

function TableDailyAssignedQRContainer() {
  const data = useSelector((state) => state.companyDashboard.tableDailyAssignedQRCount.data);
  const isLoading = useSelector((state) => state.companyDashboard.tableDailyAssignedQRCount.isLoading);
  const error = useSelector((state) => state.companyDashboard.tableDailyAssignedQRCount.error);

  return (
    <TableDailyAssignedQRComponent
      isLoading={isLoading}
      data={data}
      error={error}
    />
  );
}

export default TableDailyAssignedQRContainer;
