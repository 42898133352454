import { createSlice } from '@reduxjs/toolkit';
import { getIndustries } from './constants.action';

const CONSTANTS = 'constants';
const initialState = {
  industries: [],
};

const constantsSlice = createSlice({
  name: CONSTANTS,
  initialState,
  reducers: {
    clearConstants: (state) => {
      /* eslint-disable */
      state = initialState;
      /* eslint-enable */
    },
  },
  extraReducers: {
    [getIndustries.fulfilled]: (state, action) => {
      state.industries = action.payload;
    },
  },
});

export const {
  clearConstants,
} = constantsSlice.actions;
export const constantsReducer = constantsSlice.reducer;
