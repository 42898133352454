import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from '@material-ui/core';
import Recaptcha from 'react-google-invisible-recaptcha';

const { REACT_APP_RECAPTCHA_SITEKEY } = process.env;

const LoginPage = ({
  refCaptcha,
  onLoginClick,
  onRecaptchaError,
  onRecaptchaResolve,
}) => (
  <>
    <Helmet>
      <title>Login | Kood Asia Management Portal</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
      }}
    >
      <Container maxWidth="sm">
        <Formik
          initialValues={{
            username: '',
            password: '',
          }}
          validationSchema={Yup.object().shape({
            username: Yup.string().max(255).required('Username is required'),
            password: Yup.string().max(255).required('Password is required'),
          })}
          onSubmit={onLoginClick}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ mb: 3 }}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                  Sign in
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="body2"
                >
                  Sign in on the internal platform
                </Typography>
              </Box>
              <TextField
                error={Boolean(touched.username && errors.username)}
                fullWidth
                helperText={touched.username && errors.username}
                label="Username"
                margin="normal"
                name="username"
                disabled={isSubmitting}
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.username}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="Password"
                margin="normal"
                name="password"
                disabled={isSubmitting}
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Sign in now
                </Button>
              </Box>
              <Recaptcha
                ref={refCaptcha}
                sitekey={REACT_APP_RECAPTCHA_SITEKEY}
                onError={() => onRecaptchaError({ setSubmitting })}
                onResolved={() => onRecaptchaResolve({ setSubmitting })}
              />
            </form>
          )}
        </Formik>
      </Container>
    </Box>
  </>
);

LoginPage.propTypes = {
  onLoginClick: PropTypes.func.isRequired,
  onRecaptchaError: PropTypes.func.isRequired,
  onRecaptchaResolve: PropTypes.func.isRequired,
};

export default LoginPage;
