import axios from "axios";

export default function updateAccessRights({ uuid, accessRights }) {
  const promise = new Promise((resolve, reject) =>
    axios
      .post(`/account/system-admin/api/v1/access-right/company/${uuid}`, {
        access_rights: accessRights,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.message))
  );

  return promise;
}
