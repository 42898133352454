import { createSlice } from '@reduxjs/toolkit';

const LOADING = 'loading';
const initialState = {
  inProcess: [],
};

const loadingSlice = createSlice({
  name: LOADING,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      const { payload } = action;
      if (payload.state) {
        state.inProcess = [...state.inProcess, payload];
      } else {
        state.inProcess = state.inProcess.filter(({ id }) => id !== payload.id);
      }
    },
  },
  extraReducers: {
  },
});

export const {
  setLoading,
} = loadingSlice.actions;
export const loadingReducer = loadingSlice.reducer;
