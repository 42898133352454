import axios from 'axios';

const { REACT_APP_S3_BUCKET_URL } = process.env;

export default function getIndustries() {
  return new Promise((resolve, reject) => axios
    .get(`${REACT_APP_S3_BUCKET_URL}/constants/industries.json`)
    .then((response) => resolve(response.data))
    .catch((error) => reject(error.message)));
}
