import React from 'react';
import DashboardSidebarComponent from './DashboardSidebar.component';

export default function DashboardSidebarContainer({
  items,
  isDrawerOpen,
  onDrawerToggle,
}) {
  return (
    <DashboardSidebarComponent
      items={items}
      isDrawerOpen={isDrawerOpen}
      onDrawerToggle={onDrawerToggle}
    />
  );
}
