import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useJsApiLoader } from '@react-google-maps/api';
import { addAlert } from 'src/modules/notification';
import MapScanCountComponent from './map-scan-count.component';

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

export default function MapScanCountContainer() {
  const dispatch = useDispatch();
  const mapScans = useSelector((state) => state.companyDashboard.mapScanCount.data);
  const isLoading = useSelector((state) => state.companyDashboard.mapScanCount.isLoading);
  const error = useSelector((state) => state.companyDashboard.mapScanCount.error);
  const [map, setMap] = useState(null);

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY,
  });

  const center = { lat: 3.1319, lng: 101.6841 };

  async function initMap(mapInstance) {
    const bounds = new window.google.maps.LatLngBounds(center);
    let hasScanHistory = false;
    if (mapScans) {
      mapScans.forEach((item) => {
        if (item.latitude && item.longitude) {
          bounds.extend({
            lat: parseFloat(item.latitude),
            lng: parseFloat(item.longitude),
          });
        }
      });
      hasScanHistory = true;
    }
    if (hasScanHistory) {
      mapInstance.fitBounds(bounds);
      mapInstance.setZoom(5.0);
    }
  }

  useEffect(() => {
    if (map) {
      initMap(map);
    }
  }, [mapScans, map]);

  const onLoad = useCallback((mapInstance) => {
    mapInstance.setOptions({
      mapId: '898db239baad3210',
    });

    setMap(mapInstance);
    initMap(mapInstance);
  }, []);

  const onUnmount = React.useCallback((mapInstance) => {
    setMap(null);
  }, []);

  useEffect(() => {
    if (loadError) {
      dispatch(
        addAlert({
          severity: 'error',
          message: `Error loading Google Maps: ${loadError.message}`,
        })
      );
    }
  }, [loadError]);

  return (
    <MapScanCountComponent
      googleMapsAPIKey={API_KEY}
      onLoad={onLoad}
      isLoading={isLoading}
      error={error}
      isLoaded={isLoaded}
      center={center}
      onUnmount={onUnmount}
      data={mapScans}
    />
  );
}
