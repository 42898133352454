import { Box, TableCell } from "@material-ui/core";
import ActionDetailButton from "src/components/ActionButton";
import DataTable from "../../../../../components/DataTable";

export default function TngTransactionReportTableComponent({
  data,
  totalRow,
  handleChangePage,
  handleChangeRowsPerPage,
  tableFilter,
  isFetching,
  setSelected,
}) {
  return (
    <DataTable
      isLoading={isFetching}
      rows={data}
      rowsPerPage={tableFilter.rowsPerPage}
      total={totalRow}
      page={tableFilter.page}
      renderHeader={() => (
        <>
          <TableCell>No.</TableCell>
          <TableCell>File Name</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Total Amount</TableCell>
          <TableCell>Transaction Count</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Action</TableCell>
        </>
      )}
      renderRow={(row, index) => {
        const actionItems = [
          {
            label: "Show Detail",
            action: () => {
              setSelected(row.uuid);
            },
          },
          {
            label: "Download",
            action: () => {
              const link = document.createElement('a');
              link.href = row.url;
              link.download = row.filename;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            },
          },
        ];

        return (
          <>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{row.filename}</TableCell>
            <TableCell>
              <Box display="flex" alignItems="center">
                {row.date}
              </Box>
            </TableCell>
            <TableCell>{row.transactionAmount}</TableCell>
            <TableCell>{row.transactionInitializedCount === null ? "NA" : `${row.transactionInitializedCount}/${row.transactionCompletedCount}`}</TableCell>
            <TableCell>
              <Box
                color={{
                  Pass: "green",
                  Failed: "red",
                }[row.status] ?? "orange"}
              >
                {row.status}
              </Box>
            </TableCell>
            <TableCell>
              <ActionDetailButton
                actionItems={actionItems}
                popperStyle={{ zIndex: 999 }}
              />
            </TableCell>
          </>
        );
      }}
      onPageClick={handleChangePage}
      handleItemPerPageChange={handleChangeRowsPerPage}
    />
  );
}
