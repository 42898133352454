import { createSlice } from '@reduxjs/toolkit';
import { getEmailLog, getEmailTypeFilter, setSelectedAll, setSelectedAllFailed } from './email-log.action';

const EMAIL_LOG = 'emailLog';
const initialState = {
  totalEmailLogs: 0,
  emailLogsPage: 0,
  logs: [],
  isResending: false,
  searchValue: '',
  filterType: '',
  filterStatus: '',
  filterDateRange: [],
  itemPerPage: 25,
  selected: [],
  emailTypeDropdown: [],
};
const emailLogsSlice = createSlice({
  name: EMAIL_LOG,
  initialState,
  reducers: {
    resetFilter: (state, action) => {
      state.searchValue = initialState.searchValue;
      state.filterType = initialState.filterType;
      state.filterStatus = initialState.filterStatus;
      state.filterDateRange = initialState.filterDateRange;
    },
    clearEmailLogs: (state, action) => {
      state.totalEmailLogs = 0;
      state.logs = [];
    },
    setPage: (state, action) => {
      state.emailLogsPage = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setTypeFilter: (state, action) => {
      state.filterType = action.payload;
    },
    setStatusFilter: (state, action) => {
      state.filterStatus = action.payload;
    },
    setDateRangeFilter: (state, action) => {
      state.filterDateRange = action.payload;
    },
    setItemPerPage: (state, action) => {
      state.itemPerPage = action.payload;
    },
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
  },
  extraReducers: {
    [getEmailLog.fulfilled]: (state, action) => {
      state.totalEmailLogs = action.payload.recordsFiltered;
      state.emailLogsPage = action.meta.arg.page;

      const processLogs = action.payload.data.map(
        (log) => {
          const processLog = {};

          processLog.id = log.id;
          processLog.email_type = log.email_type;
          processLog.recipient_email = log.recipient_email;
          processLog.status = log.status;
          processLog.lastMessage = log.last_message;
          processLog.lastAttempted = log.sent_at;
          processLog.createdAt = log.created_at;

          return processLog;
        },
      );
      state.logs = processLogs;
    },
    [setSelectedAll.fulfilled]: (state, action) => {
      const processLogs = action.payload.data.map(
        (log) => {
          const processLog = {};

          processLog.id = log.id;
          processLog.email_type = log.email_type;
          processLog.recipient_email = log.recipient_email;
          processLog.status = log.status;
          processLog.lastMessage = log.last_message;
          processLog.lastAttempted = log.sent_at;
          processLog.createdAt = log.created_at;

          return processLog;
        },
      );

      state.selected = processLogs;
    },
    [setSelectedAllFailed.fulfilled]: (state, action) => {
      const processLogs = action.payload.data.map(
        (log) => {
          const processLog = {};

          processLog.id = log.id;
          processLog.email_type = log.email_type;
          processLog.recipient_email = log.recipient_email;
          processLog.status = log.status;
          processLog.lastMessage = log.last_message;
          processLog.lastAttempted = log.sent_at;
          processLog.createdAt = log.created_at;

          return processLog;
        },
      );

      state.selected = processLogs.filter((e) => e.status === 'failed');
    },
    [getEmailTypeFilter.fulfilled]: (state, action) => {
      const { payload } = action;
      state.emailTypeDropdown = Object.values(payload);
    },
  },
});

export const {
  resetFilter,
  clearEmailLogs,
  setPage,
  setSearchValue,
  setTypeFilter,
  setStatusFilter,
  setDateRangeFilter,
  setItemPerPage,
  setSelected,
} = emailLogsSlice.actions;
export const emailLogsReducer = emailLogsSlice.reducer;
