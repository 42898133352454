import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  TextField,
  DialogActions,
  FormControl,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  Skeleton,
  IconButton,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import { Formik } from "formik";
import CloseIcon from "@material-ui/icons/CloseRounded";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Masonry from "../../masonry/Masonry";
import AccessRightAccordion from "../../accordion/accessRightAccordion";

const useStyles = makeStyles((theme) => ({
  accessBox: {
    display: "flex",
    flexFlow: "column",
  },
  publishButton: {
    color: theme.palette.common.white,
  },
  scrollContainer: {
    maxHeight: "70vh",
    overflowY: "auto",
    backgroundColor: "#f3f3f3",
  },
}));

const Accordion = withStyles({
  root: {
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    margin: "0px 0",
    "&$expanded": {
      margin: "0px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: "unset",
  },
}))(MuiAccordionDetails);

export default function CompanyAccessRightDialogComponent({
  selectedCompany,
  handleSubmit,
  onCompanyAccessRightDialogCloseClick,
  accessRights,
  isError,
  selectedIds,
  isLoading,
  isUpdating,
}) {
  const classes = useStyles();

  if (!selectedCompany) {
    return null;
  }
  return (
    <Dialog
      open={!!selectedCompany}
      onClose={onCompanyAccessRightDialogCloseClick}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
    >
      <Formik
        initialValues={{
          selectedIds,
        }}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle id="scroll-dialog-title">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body2">
                  <b>{selectedCompany ? selectedCompany.name : ""}</b>
                </Typography>
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={onCompanyAccessRightDialogCloseClick}
                  style={{ padding: 0 }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent dividers className={classes.scrollContainer}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box flexGrow={1}>
                  <Typography variant="body2" className={classes.labelText}>
                    Assign access right
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box>
                    <Button
                      color="secondary"
                      variant="text"
                      onClick={() => {
                        const allRole = [];
                        accessRights.forEach((groups) =>
                          groups.subgroup.forEach((subgroups) =>
                            subgroups.details.forEach((detail) => {
                              allRole.push(detail.id);
                            })
                          )
                        );
                        formik.setFieldValue("selectedIds", allRole);
                      }}
                      style={{ textTransform: "none", color: "#6AAF68" }}
                    >
                      Select All
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      color="secondary"
                      variant="text"
                      onClick={() => {
                        formik.setFieldValue("selectedIds", []);
                      }}
                      style={{ textTransform: "none", color: "red" }}
                    >
                      Deselect All
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.accessBox} mt={2}>
                {isLoading ? (
                  <>
                    <Skeleton variant="text" animation="wave" height={25} />
                    <Skeleton variant="text" animation="wave" height={25} />
                    <Skeleton variant="text" animation="wave" height={25} />
                    <Skeleton variant="text" animation="wave" height={25} />
                  </>
                ) : (
                  <Masonry container>
                    {accessRights.map((field, index) => {
                      const disableSummaryAccordion = field.subgroup.some(
                        (element) =>
                          !formik.values.selectedIds.includes(
                            element.details[0].action
                          )
                      );
                      return (
                        <AccessRightAccordion
                          key={index}
                          label={field.group}
                          subgroup={field.subgroup}
                          selectedIds={formik.values.selectedIds}
                          disableSummaryAccordion={disableSummaryAccordion}
                          disabledCheckbox={isUpdating}
                          selectAccessRight={(info, isChecked) => {
                            let temp = [...formik.values.selectedIds];
                            if (isChecked) {
                              temp.push(info.id);
                            } else {
                              temp = temp.filter((t) => t !== info.id);
                            }
                            formik.setFieldValue("selectedIds", temp);
                          }}
                          selectGroup={(ids, isChecked) => {
                            let temp = [...formik.values.selectedIds];
                            if (isChecked) {
                              temp = temp.concat(ids);
                            } else {
                              temp = temp.filter((t) => !ids.includes(t));
                            }
                            temp = [...new Set(temp)];
                            formik.setFieldValue("selectedIds", temp);
                          }}
                        />
                      );
                    })}
                  </Masonry>
                )}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                variant="text"
                type="button"
                disabled={isUpdating}
                onClick={onCompanyAccessRightDialogCloseClick}
                style={{ color: "black" }}
              >
                Cancel
              </Button>
              <Button variant="contained" type="submit" disabled={isUpdating}>
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}
