const LogoComponent = () => (
  <img
    alt="Logo"
    src="/static/logo.png"
    height={50}
  />
);

LogoComponent.propTypes = {
};

export default LogoComponent;
