import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProviderList } from "src/modules/consumer-engagement/redux";
import { selectLoading } from "src/modules/notification";
import ProviderSelectComponent from "./providerSelect.component";
import ProviderSelectPopoverComponent from "./providerSelectPopover.component";

function ProviderSelectContainer({
  value,
  placeholder,
  handleChange,
  disabled,
  panelStyle,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  style,
  textStyle,
}) {
  const dispatch = useDispatch();

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const prevOpen = useRef(open);

  const providers = useSelector((state) => state.disbursement.providers);
  const isLoading = useSelector((state) =>
    selectLoading(state, getProviderList.typePrefix)
  );
  const [list, setList] = useState([]);

  useEffect(() => {
    dispatch(getProviderList());
  }, []);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    setList(providers);
  }, [providers]);

  const handleClick = (event) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setList(providers);
  };

  const handleSearch = (val) => {
    if (val) {
      const text = val.toLowerCase();

      const temp = providers.filter(
        (i) => !!i.name.toLowerCase().includes(text)
      );
      setList(temp);
    } else {
      setList(providers);
    }
  };

  return (
    <>
      <ProviderSelectComponent
        anchorRef={anchorRef}
        handleClick={handleClick}
        value={value}
        style={style}
        disabled={disabled}
        placeholder={placeholder}
        open={open}
        textStyle={textStyle}
        items={list}
      />
      <ProviderSelectPopoverComponent
        anchorRef={anchorRef}
        open={open}
        handleClose={handleClose}
        items={list}
        handleSearch={handleSearch}
        isFetching={isLoading}
        handleChange={handleChange}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={PaperProps}
        value={value}
      />
    </>
  );
}

export default ProviderSelectContainer;
