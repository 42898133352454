/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  TextField,
  DialogActions,
  Button,
  IconButton,
  Box,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/CloseRounded";
import { Formik } from "formik";
import CurrencySelect from "../../select/currencySelect";
import ProviderSelect from "../../select/providerSelect";
import CompanySelect from "../../select/companySelect";

const CreateDisbursementInfoDialogComponent = ({
  isOpen,
  handleClose,
  isSubmitting,
  handleSubmit,
  handleValidateBalance,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        handleClose();
      }}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <Formik
        initialValues={{
          company: null,
          provider: null,
          currency: null,
          balance: "",
          confirmBalance: "",
          remark: "",
        }}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle id="scroll-dialog-title">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body2">
                  <b>Create company disbursement info</b>
                </Typography>
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={handleClose}
                  style={{ padding: 0 }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent
              dividers
              style={{ backgroundColor: "#f3f3f3", padding: 16 }}
            >
              <Paper>
                <Box p={2}>
                  <Box>
                    <Grid
                      container
                      style={{
                        alignItems: "center",
                      }}
                    >
                      <Grid item md={3} style={{ width: "100%" }}>
                        <Typography variant="body1" color="textSecondary">
                          Company <span style={{ color: "red" }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item md={9} style={{ width: "100%" }}>
                        <CompanySelect
                          placeholder="Company"
                          handleChange={(val) =>
                            formik.setFieldValue("company", val)
                          }
                          value={formik.values.company}
                          disabled={isSubmitting}
                          style={{
                            border: "1px solid #c4c4c4",
                            backgroundColor: "white",
                            height: 38,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={2}>
                    <Grid
                      container
                      style={{
                        alignItems: "center",
                      }}
                    >
                      <Grid item md={3} style={{ width: "100%" }}>
                        <Typography variant="body1" color="textSecondary">
                          Provider <span style={{ color: "red" }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item md={9} style={{ width: "100%" }}>
                        <ProviderSelect
                          value={formik.values.provider}
                          placeholder="Provider"
                          handleChange={(val) =>
                            formik.setFieldValue("provider", val)
                          }
                          disabled={isSubmitting}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          style={{
                            border: "1px solid #c4c4c4",
                            backgroundColor: "white",
                            height: 38,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={2}>
                    <Grid
                      container
                      style={{
                        alignItems: "center",
                      }}
                    >
                      <Grid item md={3} style={{ width: "100%" }}>
                        <Typography variant="body1" color="textSecondary">
                          Currency <span style={{ color: "red" }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item md={9} style={{ width: "100%" }}>
                        <CurrencySelect
                          textFieldProps={{
                            fullWidth: true,
                            variant: "outlined",
                            size: "small",
                            margin: "dense",
                          }}
                          formProps={{
                            ...formik.getFieldProps("currency"),
                            value: formik.values.currency,
                            onChange: (e, value) => {
                              formik.setFieldValue("currency", value);
                            },
                            disabled: isSubmitting,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={2}>
                    <Grid
                      container
                      style={{
                        alignItems: "center",
                      }}
                    >
                      <Grid item md={3} style={{ width: "100%" }}>
                        <Typography variant="body1" color="textSecondary">
                          Balance <span style={{ color: "red" }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item md={9} style={{ width: "100%" }}>
                        <TextField
                          variant="outlined"
                          name="balance"
                          fullWidth
                          type="number"
                          placeholder="Balance"
                          value={formik.values.balance}
                          required
                          onKeyDown={(event) => {
                            if (event.key === "e" || event.key === "E" || event.key === ".") {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            handleValidateBalance(
                              e.target.value,
                              "balance",
                              formik
                            );
                          }}
                          disabled={isSubmitting}
                          inputProps={{
                            style: {
                              backgroundColor: "white",
                              padding: "8px 12px",
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={2}>
                    <Grid
                      container
                      style={{
                        alignItems: "center",
                      }}
                    >
                      <Grid item md={3} style={{ width: "100%" }}>
                        <Typography variant="body1" color="textSecondary">
                          Confirm Balance{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item md={9} style={{ width: "100%" }}>
                        <TextField
                          variant="outlined"
                          name="balance"
                          fullWidth
                          type="number"
                          value={formik.values.confirmBalance}
                          placeholder="Confirm Balance"
                          required
                          onKeyDown={(event) => {
                            if (event.key === "e" || event.key === "E" || event.key === ".") {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            handleValidateBalance(
                              e.target.value,
                              "confirmBalance",
                              formik
                            );
                          }}
                          disabled={isSubmitting}
                          inputProps={{
                            style: {
                              backgroundColor: "white",
                              padding: "8px 12px",
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={2}>
                    <Grid container>
                      <Grid item md={3} style={{ width: "100%", marginTop: 8 }}>
                        <Typography variant="body1" color="textSecondary">
                          Remark
                        </Typography>
                      </Grid>
                      <Grid item md={9} style={{ width: "100%" }}>
                        <TextField
                          variant="outlined"
                          name="balance"
                          fullWidth
                          multiline
                          minRows={5}
                          maxRows={5}
                          placeholder="Remark"
                          value={formik.values.remark}
                          onChange={(e) => {
                            formik.setFieldValue("remark", e.target.value);
                          }}
                          disabled={isSubmitting}
                          inputProps={{
                            style: {
                              backgroundColor: "white",
                              padding: "0px 4px",
                            },
                          }}
                          InputProps={{
                            style: {
                              padding: 8,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Paper>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                type="submit"
                disabled={
                  isSubmitting ||
                  !formik.values.company ||
                  !formik.values.provider ||
                  !formik.values.currency ||
                  formik.values.balance === "" ||
                  formik.values.balance.toString() !==
                    formik.values.confirmBalance
                }
                startIcon={
                  isSubmitting ? (
                    <CircularProgress style={{ width: 10, height: 10 }} />
                  ) : null
                }
              >
                Submit
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateDisbursementInfoDialogComponent;
