import React from 'react';
import { useSelector } from 'react-redux';
import TableBranchScanCountComponent from './table-branch-scan-count.component';

export default function TableBranchScanCountContainer() {
  const branchList = useSelector((state) => state.companyDashboard.branchList.data);
  const data = useSelector((state) => state.companyDashboard.tableBranchScanCount.data);
  const isLoading = useSelector((state) => state.companyDashboard.tableBranchScanCount.isLoading);
  const error = useSelector((state) => state.companyDashboard.tableBranchScanCount.error);

  return (
    <TableBranchScanCountComponent
      branchList={branchList}
      data={data}
      isLoading={isLoading}
      error={error}
    />
  );
}
