import React, { Fragment, useState } from "react";
import {
  FormControlLabel,
  Checkbox,
  FormControl,
  FormGroup,
  Box,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import Masonry from "../../masonry/Masonry";

const useStyle = makeStyles((theme) => ({
  body: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 3),
    columnGap: theme.spacing(4),
  },
  formControl: {
    marginLeft: theme.spacing(6),
  },
  formControlLabel: {
    "& .MuiFormControlLabel-label": {
      fontWeight: "bold",
    },
  },
}));

const Accordion = withStyles({
  root: {
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    margin: "0px 0",
    "&$expanded": {
      margin: "0px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: "unset",
  },
}))(MuiAccordionDetails);

export default function AccessRightAccordionComponent({
  label,
  subgroup,
  selectedIds,
  disabledCheckbox,
  disableSummaryAccordion,
  selectAccessRight,
  groupIndex,
  selectGroup,
  ...props
}) {
  const classes = useStyle();

  const groupCheck = (subgrp, isChecked) => {
    const groupIds = [];
    subgrp.forEach((field) =>
      field.details.forEach((info) => groupIds.push(info.id))
    );
    selectGroup(groupIds, isChecked);
  };

  const subgroupCheck = (details, isChecked) => {
    const groupIds = details.map((info) => info.id);
    selectGroup(groupIds, isChecked);
  };

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const groups = [];

  subgroup.map((field) =>
    field.details.forEach((info) => groups.push(info.id))
  );

  return (
    <Masonry item style={{ padding: "5px" }}>
      <Accordion expanded={expanded === label} onChange={handleChange(label)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <FormControlLabel
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={
              <Checkbox
                indeterminateIcon={
                  <IndeterminateCheckBoxIcon
                    color={disabledCheckbox ? "disabled" : "primary"}
                  />
                }
                checked={groups.every((group) => selectedIds.includes(group))}
                indeterminate={
                  groups.some((group) => selectedIds.includes(group)) &&
                  groups.every((group) => selectedIds.includes(group)) === false
                }
              />
            }
            onChange={(event) => {
              groupCheck(subgroup, event.target.checked);
            }}
            label={label}
            className={classes.formControlLabel}
            disabled={disabledCheckbox}
          />
        </AccordionSummary>
        <AccordionDetails>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
              {subgroup.map((field, subGroupIdx) => {
                const ids = [];
                const hasInheritAccessRights = true;

                field.details.map((info) => ids.push(info.id));
                return (
                  <Fragment key={subGroupIdx}>
                    {field.name && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            indeterminateIcon={
                              <IndeterminateCheckBoxIcon color="primary" />
                            }
                            checked={ids.every((id) =>
                              selectedIds.includes(id)
                            )}
                            indeterminate={
                              ids.some((id) => selectedIds.includes(id)) &&
                              ids.every((id) => selectedIds.includes(id)) ===
                                false
                            }
                          />
                        }
                        label={field.name}
                        onChange={(event) =>
                          subgroupCheck(field.details, event.target.checked)
                        }
                        disabled={disabledCheckbox}
                      />
                    )}
                    <Box
                      display="flex"
                      flexDirection="column"
                      ml={field.name ? 3 : 0}
                    >
                      {field.details.map((info, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              checked={!!selectedIds.includes(info.id)}
                            />
                          }
                          label={info.title}
                          onChange={(event) => {
                            selectAccessRight(info, event.target.checked);
                          }}
                          disabled={disabledCheckbox || !hasInheritAccessRights}
                        />
                      ))}
                    </Box>
                  </Fragment>
                );
              })}
            </FormGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>
    </Masonry>
  );
}
