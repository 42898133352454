import { createAsyncThunk } from "@reduxjs/toolkit";
import companiesApi from "../../../../configs/apis/companies";

export const getCompanies = createAsyncThunk(
  "companies/getCompanies",
  async (payload, { rejectWithValue }) =>
    companiesApi
      .getListing({
        page: payload.page,
        length: payload.length,
        companyName: payload.companyName,
        regNo: payload.regNo,
        plan: payload.plan,
        status: payload.status,
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const putWhiteLabel = createAsyncThunk(
  "companies/putWhiteLabel",
  async (payload, { rejectWithValue }) =>
    companiesApi
      .putWhiteLabel({ id: payload.id, whiteLabel: payload.whiteLabel })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const removeCompanyWhiteLabel = createAsyncThunk(
  "companies/removeCompanyWhiteLabel",
  async (payload, { rejectWithValue }) =>
    companiesApi
      .removeCompanyWhiteLabel({ id: payload.id })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const getAccessRights = createAsyncThunk(
  "companies/getAccessRights",
  async (payload, { rejectWithValue }) =>
    companiesApi
      .getAccessRights(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const updateAccessRights = createAsyncThunk(
  "companies/updateAccessRights",
  async (payload, { rejectWithValue }) =>
    companiesApi
      .updateAccessRights(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const getCompanyPlanInfo = createAsyncThunk(
  "companies/getCompanyPlanInfo",
  async (payload, { rejectWithValue }) =>
    companiesApi
      .getCompanyPlanInfo(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const updateCompanyPlan = createAsyncThunk(
  "companies/updateCompanyPlan",
  async (payload, { rejectWithValue }) =>
    companiesApi
      .updateCompanyPlan(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const getNodesWithWhiteLabel = createAsyncThunk(
  "companies/getNodesWithWhiteLabel",
  async (payload, { rejectWithValue }) =>
    companiesApi
      .getNodesWithWhiteLabel(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const putBranchWhiteLabel = createAsyncThunk(
  "companies/putBranchWhiteLabel",
  async (payload, { rejectWithValue }) =>
    companiesApi
      .putBranchWhiteLabel({ id: payload.id, whiteLabel: payload.whiteLabel })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const removeBranchWhiteLabel = createAsyncThunk(
  "companies/removeBranchWhiteLabel",
  async (payload, { rejectWithValue }) =>
    companiesApi
      .removeBranchWhiteLabel({ id: payload.id })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const getBranchList = createAsyncThunk(
  "companies/getBranchList",
  async (payload, { rejectWithValue }) =>
    companiesApi
      .getBranchList({ payload })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const getProductList = createAsyncThunk(
  "companies/getProductList",
  async (payload, { rejectWithValue }) =>
    companiesApi
      .getProductList({ payload })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const updateAssign = createAsyncThunk(
  "companies/updateAssign",
  async (payload, { rejectWithValue }) =>
    companiesApi
    .updateAssign({ payload })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error))
);

export const updateUnassign = createAsyncThunk(
  "companies/updateUnassign",
  async (payload, { rejectWithValue }) =>
    companiesApi
    .updateUnassign({ payload })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error))
);

export const setCompanyStatus = createAsyncThunk(
  "companies/setCompanyStatus",
  async (payload, { rejectWithValue }) =>
    companiesApi
      .setCompanyStatus({ id: payload.id, status: payload.status, remarks: payload.remarks })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const getNodeSnRange = createAsyncThunk(
  "companies/getNodeSnRange",
  async (payload, { rejectWithValue }) =>
    companiesApi
      .getNodeSnRange({ id: payload })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);
