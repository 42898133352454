import getBranchList from "./getBranchList.api";
import getMapScanCount from './getMapScanCount';
import getGraphScanCount from './getGraphScanCount';
import getPieChartScanValidity from './getPieChartScanValidity';
import getTableCountryScanCount from './getTableCountryScanCount';
import getTableBranchScanCount from './getTableBranchScanCount';
import getGraphQR from './getGraphQR';
import getTableDailyGeneratedQRCount from './getTableDailyGeneratedQRCount';
import getTableDailyAssignedQRCount from './getTableDailyAssignedQRCount';

const companyDashboardApi = {
  getBranchList,
  getMapScanCount,
  getGraphScanCount,
  getPieChartScanValidity,
  getTableCountryScanCount,
  getTableBranchScanCount,
  getGraphQR,
  getTableDailyGeneratedQRCount,
  getTableDailyAssignedQRCount,
};

export default companyDashboardApi;
