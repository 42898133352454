import axios from 'axios';

const getLineChartScanCount = ({ companyIds, startDate, endDate }) => new Promise((resolve, reject) => {
  axios
    .get('/analytic/admin/api/v1/managementportal/get-line-chart-scan-count', {
      params: {
        company_ids: companyIds.toString(),
        start_date: startDate,
        end_date: endDate,
      },
    })
    .then((response) => resolve(response.data))
    .catch((error) => {
      if (error.response && error.response.data) {
        reject(error.response.data);
      } else {
        reject(error);
      }
    });
});

export default getLineChartScanCount;
