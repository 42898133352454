import React, { useRef } from 'react';
import {
  Popover,
  Box,
  Divider,
  InputAdornment,
  Button,
  MenuItem,
  Checkbox,
  IconButton,
  MenuList,
  Typography,
  Skeleton,
  Radio,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
import InputTextField from 'src/components/Input/InputTextField';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: 0,
    minWidth: 0,
  },
  perfectScrollBar: {
    height: 200,
  },
  list: {
    paddingTop: 3,
    paddingBottom: 3,
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    whiteSpace: 'normal',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    'line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
}));

export default function CompanySelectPopoverComponent({
  open,
  anchorEl,
  handleClose,
  id,
  style,
  searchable,
  handleSearch,
  placeholder,
  items,
  isFetching,
  emptyMessage,
  handleChange,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  searchText,
  value,
  selectAll,
  handleAll,
  top20Checked,
  handleTop20,
}) {
  const classes = useStyles();
  const textRef = useRef();

  const handleClearClick = () => {
    textRef.current.value = '';
  };

  return (
    <Popover
      id={id}
      open={open}
      style={style}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      elevation={2}
      PaperProps={{
        style: { minWidth: '20em', maxWidth: 400 },
        ...PaperProps,
      }}
    >
      {searchable && (
        <Box>
          <InputTextField
            variant="filled"
            size="small"
            fullWidth
            inputRef={textRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ paddingRight: 6 }}>
                  <Button
                    onClick={() => handleSearch(textRef.current.value)}
                    className={classes.button}
                  >
                    <IconButton
                      onClick={handleClearClick}
                    >
                      <Clear />
                    </IconButton>
                    <Search />
                  </Button>
                </InputAdornment>
              ),
              style: {
                backgroundColor: '#fff',
                paddingRight: 0,
              },
            }}
            inputPropss={{
              onKeyDown: (e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSearch(textRef.current.value);
                }
              },
              style: {
                padding: 8,
              },
            }}
            placeholder={placeholder}
          />
        </Box>
      )}
      <Divider />
      <Box style={{ maxHeight: '250px', overflow: 'auto' }}>
        {isFetching ? (
          <>
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
          </>
        ) : (
          <Box>
            {items && items.length > 0 && (
              <MenuList
                id="menu-list-grow"
                style={{ padding: 0 }}
              >
                <MenuItem style={{ padding: 0 }}>
                  <Radio
                    checked={value.length === items.length}
                    onClick={() => {
                      handleAll();
                    }}
                  />
                  <Typography
                    variant="inherit"
                    noWrap
                    style={{ paddingLeft: '8px' }}
                  >
                    All
                  </Typography>
                </MenuItem>
                <MenuItem style={{ padding: 0 }}>
                  <Radio
                    checked={value.length === 0}
                    onClick={() => {
                      handleTop20();
                    }}
                  />
                  <Typography
                    variant="inherit"
                    noWrap
                    style={{ paddingLeft: '8px' }}
                  >
                    Top 20
                  </Typography>
                </MenuItem>
              </MenuList>
            )}
            <Divider />
            {items &&
              items.length > 0 &&
              items.map((item) => {
                if (
                  searchText === '' ||
                  (searchText !== '' &&
                    item.name.toLowerCase().includes(searchText))
                ) {
                  return (
                    <Box key={item.id}>
                      <MenuItem
                        style={{ padding: '4px 0px' }}
                        onClick={() => handleChange(item)}
                      >
                        <Checkbox
                          checked={!!(Array.isArray(value) && value.find((v) => v.id === item.id))}
                        />
                        <Box className={classes.name}>{item.name}</Box>
                      </MenuItem>
                    </Box>
                  );
                }
                return null;
              })}
          </Box>
        )}
      </Box>
    </Popover>
  );
}
