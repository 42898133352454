import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
  CircularProgress,
} from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    padding: "30px 25px",
    minWidth: 400,
    borderRadius: 12,
    boxShadow:
      "0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)",
  },
  customDialogActionRoot: {
    justifyContent: "center",
  },
  customDialogTitle: {
    paddingTop: 0,
    paddingBottom: 10,
    color: "#101828",
    fontWeight: "500",
    fontSize: "18px !important",
  },
  customDialogPaperWithXs: {
    maxWidth: 360,
  },
  dialogRemarksCaption: {
    fontWeight: 200,
    marginLeft: 5,
  },
  dialogRemarks: {
    color: "#FD646F",
  },
  cancelButton: {
    border: "1px solid #D0D5DD",
    color: "#344054",
  },
  dangerProccedButton: {
    color: "white",
    background: "#F04438",
    "&:hover": {
      background: "#F04438",
    },
  },
  successProceedButton: {
    color: "white",
    background: "#6AAF68",
    "&:hover": {
      background: "#6AAF68",
    },
  },
  iconContainer: {
    display: "flex",
    marginTop: 12,
    marginBottom: 12,
    alignItems: "center",
    justifyContent: "center",
  },
  description: {
    color: "#667085",
    fontWeight: "400",
    fontSize: "14px",
  },
}));

export default function GeneralDialogComponent({
  isLoading,
  isOpen,
  handleClose,
  handleProceed,
  title,
  description,
  proceedLabel,
  rejectLabel,
  rejectOption = true,
  type,
  icon,
  iconStyle,
}) {
  const classes = useStyle();

  return (
    <Dialog
      classes={{
        paper: classes.root,
        paperWidthXs: classes.customDialogPaperWithXs,
      }}
      open={isOpen}
      disableEscapeKeyDown
      aria-labelledby='="general-dialog'
      maxWidth="sm"
    >
      {!!icon && (
        <Box className={classes.iconContainer} style={iconStyle}>
          {icon}
        </Box>
      )}
      <DialogTitle classes={{ root: classes.customDialogTitle }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText classes={{ root: classes.description }}>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.customDialogActionRoot }}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {rejectOption && (
              <Button
                style={{ height: 44 }}
                fullWidth
                variant="outlined"
                onClick={handleClose}
                classes={{
                  root: classes.cancelButton,
                }}
              >
                {rejectLabel}
              </Button>
            )}
            <Button
              style={{ height: 44 }}
              fullWidth
              onClick={handleProceed}
              variant="contained"
              // autoFocus
              className={clsx({
                [classes.dangerProccedButton]: type === "danger",
                [classes.successProceedButton]: type === "success",
              })}
            >
              {proceedLabel}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
