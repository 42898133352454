import CompanyWhiteLabelDialogComponent from './company-white-label-dialog.component';

export default function CompanyWhiteLabelDialogContainer({
  selectedCompany,
  onCompanyWhiteLabelDialogSubmitClick,
  onCompanyWhiteLabelDialogCloseClick,
  onRemoveBranchWhiteLabelClick,
  onRemoveCompanyWhiteLabelClick,
  isRemoving,
}) {
  return (
    <CompanyWhiteLabelDialogComponent
      selectedCompany={selectedCompany}
      onCompanyWhiteLabelDialogSubmitClick={onCompanyWhiteLabelDialogSubmitClick}
      onCompanyWhiteLabelDialogCloseClick={onCompanyWhiteLabelDialogCloseClick}
      onRemoveBranchWhiteLabelClick={onRemoveBranchWhiteLabelClick}
      onRemoveCompanyWhiteLabelClick={onRemoveCompanyWhiteLabelClick}
      isRemoving={isRemoving}
    />
  );
}
