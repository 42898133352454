import { setLoading } from './loading.slice';
import { popAlerts, pushAlerts } from '../alerts/alerts.slice';
import { checkSession, logout } from '../session/session.action';

const loadingMiddleware = ({ dispatch }) => (next) => async (action) => {
  next(action);
  const { type, meta } = action;
  let isRequest = false;
  if (meta && meta.requestId && meta.requestStatus) {
    isRequest = true;
  }
  if (isRequest) {
    if (type.includes('/pending')) {
      dispatch(setLoading({ id: type.replace('/pending', ''), state: true }));
    } else if (type.includes('/fulfilled')) {
      dispatch(setLoading({ id: type.replace('/fulfilled', ''), state: false }));
    } else if (type.includes('/rejected')) {
      const ignoreRejectActions = [
        checkSession.rejected.type,
        logout.rejected.type,
      ];
      if (!ignoreRejectActions.includes(type)) {
        const currentTimestamps = new Date().getTime();
        // Set Alert Message
        const alertMessage = {
          message: type,
          timestamps: currentTimestamps,
        };
        dispatch(pushAlerts(alertMessage));
        setTimeout(
          () => {
            dispatch(popAlerts(alertMessage));
          },
          3000,
        );
      }
      dispatch(setLoading({ id: type.replace('/rejected', ''), state: false }));
    }
  }
};

const middlewares = [loadingMiddleware];

export default middlewares;
