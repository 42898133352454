import axios from 'axios';

export default function checkSession({
  sessionId,
  sessionExpiration,
}) {
  const headers = {};

  headers['Session-Id'] = sessionId;
  headers['Session-Expiration'] = sessionExpiration;

  return axios.get('/authentication/api/session/check-session',
    {
      headers,
    });
}
