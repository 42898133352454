import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  TextField,
  DialogActions,
  Button,
  IconButton,
  Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/CloseRounded";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from 'yup';

const CompanyDialogComponent = ({
  selectedCompany,
  onCompanyWhiteLabelDialogSubmitClick,
  onCompanyWhiteLabelDialogCloseClick,
  onRemoveBranchWhiteLabelClick,
  onRemoveCompanyWhiteLabelClick,
  isRemoving,
}) => {
  if (!selectedCompany) {
    return null;
  }
  return (
    <Dialog
      open={!!selectedCompany}
      onClose={onCompanyWhiteLabelDialogCloseClick}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
    >
      <Formik
        initialValues={{
          whiteLabel: selectedCompany?.company?.whiteLabel || selectedCompany?.white_label || "https://"
        }}
        validationSchema={Yup.object().shape({
          whiteLabel: Yup.string()
            .matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,4}(?![^\s])\b(?:$|(?=\/))/, 'Invalid white label')
        })}
        onSubmit={onCompanyWhiteLabelDialogSubmitClick}
      >
        {({
          values,
          errors,
          touched,
          setFieldTouched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="scroll-dialog-title">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body2">
                  <b>
                    {(selectedCompany?.company?.name || selectedCompany?.name || "")}
                    &nbsp;-&nbsp;Update White Label
                  </b>
                </Typography>
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={onCompanyWhiteLabelDialogCloseClick}
                  style={{ padding: 0 }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent dividers style={{ backgroundColor: "#f3f3f3" }}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="White Label"
                  name="whiteLabel"
                  fullWidth
                  value={values.whiteLabel}
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting || isRemoving}
                  error={touched.whiteLabel && errors.whiteLabel}
                  helperText={touched.whiteLabel && errors.whiteLabel}
                  inputProps={{
                    style: {
                      backgroundColor: "white",
                    },
                  }}
                />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" type="submit" disabled={isSubmitting || isRemoving}>
                Save
              </Button>
              {(selectedCompany?.white_label) ? (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "red",
                  ":hover": {
                    bgcolor: "#AA4A44",
                    color: "white",
                  },
                }}
                onClick={onRemoveBranchWhiteLabelClick}
                disabled={isSubmitting || isRemoving}
              >
                Delete
              </Button>) : ""}
              {(selectedCompany?.company?.whiteLabel) ? (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "red",
                    ":hover": {
                      bgcolor: "#AA4A44",
                      color: "white",
                    },
                  }}
                  onClick={onRemoveCompanyWhiteLabelClick}
                  disabled={isSubmitting || isRemoving}
                >
                  Delete
                </Button>) : ""}
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

CompanyDialogComponent.propTypes = {
  selectedCompany: PropTypes.object,
  onCompanyWhiteLabelDialogCloseClick: PropTypes.func.isRequired,
};

export default CompanyDialogComponent;
