import { createSlice } from "@reduxjs/toolkit";
import {
  getBranchList,
  getMapScanCount,
  getGraphScanCount,
  getPieChartScanValidity,
  getTableCountryScanCount,
  getTableBranchScanCount,
  getGraphQR,
  getTableDailyGeneratedQRCount,
  getTableDailyAssignedQRCount,
} from "../action/company-dashboard.action";

const COMPANY_DASHBOARD = "companyDashboard";
const initialState = {
  branchList: {
    isLoading: true,
    data: [],
    error: null
  },
  mapScanCount: {
    data: [],
    isLoading: true,
    error: null,
  },
  graphScanCount: {
    data: [],
    isLoading: true,
    error: null,
  },
  pieChartData: {
    data: {
      totalScanCount: 0,
      totalSuspendedScanCount: 0,
    },
    isLoading: true,
    error: null,
  },
  tableCountryScanCount: {
    data: {
      totalScanCount: 0,
      totalSuspendedScanCount: 0,
      data: [],
    },
    isLoading: true,
    error: null
  },
  tableBranchScanCount: {
    data: {
      totalScanCount: 0,
      totalSuspendedScanCount: 0,
      data: [],
    },
    isLoading: true,
    error: null
  },
  graphQR: {
    data: [],
    isLoading: true,
    error: null,
  },
  tableDailyGeneratedQRCount: {
    data: {
      totalScanCount: 0,
      totalSuspendedScanCount: 0,
      data: [],
    },
    isLoading: true,
    error: null
  },
  tableDailyAssignedQRCount: {
    data: {
      totalScanCount: 0,
      totalSuspendedScanCount: 0,
      data: [],
    },
    isLoading: true,
    error: null
  },
};

const companyDashboardSlice = createSlice({
  name: COMPANY_DASHBOARD,
  initialState,
  extraReducers: {
    // 👇      branch list      👇 //
    [getBranchList.pending]: (state, action) => {
      state.branchList.isLoading = true;
    },
    [getBranchList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.branchList.data = payload.data;
      state.branchList.isLoading = false;
      state.branchList.error = null;
    },
    [getBranchList.rejected]: (state, action) => {
      state.branchList.error = action.error.message;
    },
    // 👇      map scan count      👇 //
    [getMapScanCount.pending]: (state, action) => {
      state.mapScanCount.isLoading = true;
    },
    [getMapScanCount.fulfilled]: (state, action) => {
      const { payload } = action;
      state.mapScanCount.isLoading = false;
      state.mapScanCount.error = null;
      state.mapScanCount.data = [...payload.active.data, ...payload.suspended.data];
    },
    [getMapScanCount.rejected]: (state, action) => {
      state.mapScanCount.error = action.error.message;
    },
    // 👇      graph scan count      👇 //
    [getGraphScanCount.pending]: (state, action) => {
      state.graphScanCount.isLoading = true;
    },
    [getGraphScanCount.fulfilled]: (state, action) => {
      const { payload } = action;
      state.graphScanCount.data = payload;
      state.graphScanCount.isLoading = false;
      state.graphScanCount.error = null;
    },
    [getGraphScanCount.rejected]: (state, action) => {
      state.graphScanCount.error = action.error.message;
    },
    // 👇      pie chart scan validity      👇 //
    [getPieChartScanValidity.pending]: (state, action) => {
      state.pieChartData.isLoading = true;
    },
    [getPieChartScanValidity.fulfilled]: (state, action) => {
      const { payload } = action;
      state.pieChartData.data = payload;
      state.pieChartData.isLoading = false;
      state.pieChartData.error = null;
    },
    [getPieChartScanValidity.rejected]: (state, action) => {
      state.pieChartData.error = action.error.message;
    },
    // 👇      table country scan count      👇 //
    [getTableCountryScanCount.pending]: (state, action) => {
      state.tableCountryScanCount.isLoading = true;
    },
    [getTableCountryScanCount.fulfilled]: (state, action) => {
      const { payload } = action;
      state.tableCountryScanCount.data = payload;
      state.tableCountryScanCount.isLoading = false;
      state.tableCountryScanCount.error = null;
    },
    [getTableCountryScanCount.rejected]: (state, action) => {
      state.tableCountryScanCount.error = action.error.message;
    },
    // 👇     table branch scan count      👇 //
    [getTableBranchScanCount.pending]: (state, action) => {
      state.tableBranchScanCount.isLoading = true;
    },
    [getTableBranchScanCount.fulfilled]: (state, action) => {
      const { payload } = action;
      state.tableBranchScanCount.data = payload;
      state.tableBranchScanCount.isLoading = false;
      state.tableBranchScanCount.error = null;
    },
    [getTableBranchScanCount.rejected]: (state, action) => {
      state.tableBranchScanCount.error = action.error.message;
    },
    // 👇      graph qr      👇 //
    [getGraphQR.pending]: (state, action) => {
      state.graphQR.isLoading = true;
    },
    [getGraphQR.fulfilled]: (state, action) => {
      const { payload } = action;
      state.graphQR.data = payload;
      state.graphQR.isLoading = false;
      state.graphQR.error = null;
    },
    [getGraphQR.rejected]: (state, action) => {
      state.graphQR.error = action.error.message;
    },
    // 👇      table daily generated qr      👇 //
    [getTableDailyGeneratedQRCount.pending]: (state, action) => {
      state.tableDailyGeneratedQRCount.isLoading = true;
    },
    [getTableDailyGeneratedQRCount.fulfilled]: (state, action) => {
      const { payload } = action;
      state.tableDailyGeneratedQRCount.data = payload;
      state.tableDailyGeneratedQRCount.isLoading = false;
      state.tableDailyGeneratedQRCount.error = null;
    },
    [getTableDailyGeneratedQRCount.rejected]: (state, action) => {
      state.tableDailyGeneratedQRCount.error = action.error.message;
    },
    // 👇      table daily assigned qr      👇 //
    [getTableDailyAssignedQRCount.pending]: (state, action) => {
      state.tableDailyAssignedQRCount.isLoading = true;
    },
    [getTableDailyAssignedQRCount.fulfilled]: (state, action) => {
      const { payload } = action;
      state.tableDailyAssignedQRCount.data = payload;
      state.tableDailyAssignedQRCount.isLoading = false;
      state.tableDailyAssignedQRCount.error = null;
    },
    [getTableDailyAssignedQRCount.rejected]: (state, action) => {
      state.tableDailyAssignedQRCount.error = action.error.message;
    },
  },
});

export const companyDashboardReducer = companyDashboardSlice.reducer;
