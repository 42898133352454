import React from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle, Collapse, IconButton, Paper,
} from "@material-ui/core";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";

const ReassignResultComponent = ({
  result,
  onCollapseClick,
  onClose,
}) => (
  <>
    <Dialog
      open={!!result}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="lg"
      fullWidth
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingRight: "1rem",
        }}
      >
        <DialogTitle id="scroll-dialog-title">Reassign Result</DialogTitle>
        <Typography
          id="scroll-dialog-title"
          sx={{ cursor: "pointer" }}
          onClick={onClose}
        >
          ✖️
        </Typography>
      </Box>
      <DialogContent dividers style={{ backgroundColor: "#f3f3f3" }}>
        {result.map((e, i) => (
          <Box pb={1}>
            <Paper>
              <Box p={1}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography fontSize={18} fontWeight="bold">
                    {{
                      invalid: "Invalid Range/SN/Enc",
                      assigned: "Code has been scanned by Consumer",
                      denied: "Code has Stock Activity",
                    }[e.status]} ({e.codes.length})
                  </Typography>
                  <IconButton
                    onClick={() => onCollapseClick(i)}
                  >
                    {e.open ? (
                      <ArrowDropUpIcon fontSize="small" />
                    ) : (
                      <ArrowDropDownIcon fontSize="small" />
                    )}
                  </IconButton>
                </Box>
                <Collapse in={e.open}>
                  {e.codes.map((code) => (
                    <Box pb={0.5}>
                      <Typography>
                        {code}
                      </Typography>
                    </Box>
                  ))}
                </Collapse>
              </Box>
            </Paper>
          </Box>
        ))}
      </DialogContent>
    </Dialog>
  </>
);

export default ReassignResultComponent;
