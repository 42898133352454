/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-nested-ternary */
import React, { useRef } from 'react';
import {
  Typography,
  Popover,
  Box,
  Divider,
  InputAdornment,
  Button,
  MenuItem,
  MenuList,
  Skeleton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import SearchIcon from '@material-ui/icons/Search';
import InputTextField from 'src/components/Input/InputTextField';
import LoadingComponent from 'src/components/Loading/Loading.component';

const useStyle = makeStyles((theme) => ({
  button: {
    padding: 0,
    minWidth: 0,
  },
  perfectScrollBar: {
    maxHeight: 200,
    overflow: 'auto',
  },
  list: {
    paddingTop: 6,
    paddingBottom: 6,
  },
}));

function SearchableSelectPanelComponent({
  open,
  anchorEl,
  handleClose,
  id,
  style,
  popoverBoxStyle,
  searchable,
  handleSearch,
  placeholder,
  items,
  isFetching,
  emptyMessage,
  handleListKeyDown,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  type,
  handleFetchMoreItems,
  totalFilter,
  selected,
}) {
  const classes = useStyle();
  const textRef = useRef();

  const displayMenuItem = () => (
    <>
      {isFetching ? (
        <>
          <Skeleton variant="text" animation="wave" height={25} />
          <Skeleton variant="text" animation="wave" height={25} />
          <Skeleton variant="text" animation="wave" height={25} />
          <Skeleton variant="text" animation="wave" height={25} />
        </>
      ) : items.length > 0 ? items.map((item) => (
        <Box key={item.name ? item.name : item}>
          <MenuList
            id="menu-list-grow"
            className={classes.list}
          >
            <MenuItem
              selected={selected(item)}
              onClick={() => handleListKeyDown(item)}
            >
              <Typography variant="body1" noWrap style={{ paddingLeft: 8, paddingRight: 8 }}>
                {item.name ? item.name : item}
              </Typography>
            </MenuItem>
          </MenuList>
        </Box>
      )) : (
        <Box py={9} style={{ textAlign: 'center' }}>
          <Typography variant="body1">{emptyMessage}</Typography>
        </Box>
      )}
    </>
  );

  return (
    <Popover
      id={id}
      open={open}
      style={style}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      elevation={2}
      PaperProps={PaperProps}
    >
      {searchable && (
        <Box
          style={popoverBoxStyle}
        >
          <InputTextField
            variant="filled"
            size="small"
            fullWidth
            inputRef={textRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ paddingRight: 6 }}>
                  <Button
                    onClick={() => handleSearch(textRef.current.value)}
                    className={classes.button}
                  >
                    <SearchIcon />
                  </Button>
                </InputAdornment>
              ),
              style: {
                backgroundColor: '#fff',
                paddingRight: 0,
              },
            }}
            inputProps={{
              onKeyDown: (e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSearch(textRef.current.value);
                  return false;
                }
              },
              style: {
                padding: 8,
              },
            }}
            placeholder={placeholder}
          />
        </Box>
      )}
      <Divider />
      {type === 'InfiniteScroll' ? (
        <>
          <InfiniteScroll
            height={400}
            dataLength={items.length}
            next={handleFetchMoreItems}
            hasMore={totalFilter > items.length}
            loader={<LoadingComponent height={50} />}
          >
            {displayMenuItem()}
          </InfiniteScroll>
        </>
      ) : (
        <>
          <Box className={classes.perfectScrollBar}>
            {displayMenuItem()}
          </Box>
        </>
      )}
    </Popover>
  );
}

export default SearchableSelectPanelComponent;
