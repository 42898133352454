import { createSlice } from '@reduxjs/toolkit';

const ALERTS = 'alerts';
const initialState = {
  alerts: [],
};

const alertsSlice = createSlice({
  name: ALERTS,
  initialState,
  reducers: {
    pushAlerts: (state, action) => {
      const alerts = JSON.parse(JSON.stringify(state.alerts));
      if (alerts.length > 0) {
        alerts.pop();
      }
      alerts.push(action.payload);
      state.alerts = alerts;
    },
    popAlerts: (state, action) => {
      const alerts = JSON.parse(JSON.stringify(state.alerts));
      const spliceIndex = alerts.findIndex((alert) => alert.timestamps === action.payload.timestamps);
      if (spliceIndex !== -1) {
        alerts.splice(spliceIndex, 1);
        state.alerts = alerts;
      }
    },
  },
  extraReducers: {
  },
});

export const {
  pushAlerts,
  popAlerts,
} = alertsSlice.actions;
export const alertsReducer = alertsSlice.reducer;
