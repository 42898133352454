import React from 'react';
import { useSelector } from 'react-redux';
import ListingCountryTableComponent from './listingCountryTable.component';

const ListingCountryTableContainer = () => {
  const table = useSelector((state) => state.dashboard.table.data);
  const isLoading = useSelector((state) => state.dashboard.table.isLoading);
  const error = useSelector((state) => state.dashboard.table.error);

  return (
    <ListingCountryTableComponent
      data={table}
      isLoading={isLoading}
      error={error}
    />
  );
};

export default ListingCountryTableContainer;
