import React from "react";
import {
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Box,
  TextField,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Backdrop } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import BranchSelect from "../../Select/branchSelect";
import ProductSelect from "../../Select/productSelect";
import BatchSelect from "../../Select/batchSelect";

const useStyles = makeStyles((theme) => ({
  accessBox: {
    display: "flex",
    flexFlow: "column",
  },
  scrollContainer: {
    maxHeight: "70vh",
    overflowY: "auto",
    backgroundColor: "#f3f3f3",
  },
  root: {
    backgroundColor: "#ffffff",
  },
  backdrop: {
    zIndex: "1500",
    color: "#fff"
  }
}));

export default function AssignPanelComponent({
  isUpdating,
  selectedCompany,
  onSerialNumberAssignDialogCloseClick,
  handleSubmit,
  isBackdropOpen,
}) {
  const classes = useStyles();

  if (!selectedCompany) {
    return null;
  }
  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          branch: null,
          product: null,
          batch: null,
          to: null,
          from: null,
        }}
        validationSchema={Yup.object().shape({
          branch: Yup.mixed().required('Required'),
          product: Yup.mixed().required('Required'),
          batch: Yup.mixed().required('Required'),
          to: Yup.number()
            .when('batch', {
              is: (batch) => batch !== null && batch !== undefined,
              then: Yup.number()
                .max(Yup.ref('batch.toSeqNum'), 'Out of Range')
                .min(Yup.ref('batch.fromSeqNum'), 'Out of Range')
            })
            .min(Yup.ref('from'), 'Starting Sequence Number must be higher or equal to Ending Sequence Number')
            .required('Required'),
          from: Yup.number()
            .when('batch', {
              is: (batch) => batch !== null && batch !== undefined,
              then: Yup.number()
                .max(Yup.ref('batch.toSeqNum'), 'Out of Range')
                .min(Yup.ref('batch.fromSeqNum'), 'Out of Range')
            })
            .required('Required'),
        })}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <DialogContent dividers className={classes.scrollContainer}>
              <Box className={classes.accessBox}>
                <Grid container spacing={3}>
                  <Grid item md={3} style={{ width: "100%" }}>
                    <Typography variant="body2" color="textSecondary">
                      Branch
                    </Typography>
                  </Grid>
                  <Grid item md={9}>
                    <BranchSelect
                      placeholder="Select Branch"
                      selectedCompany={selectedCompany}
                      handleBranchChange={(value) => {
                        formik.setFieldValue('from', null);
                        formik.setFieldValue('to', null);
                        formik.setFieldValue('product', null);
                        formik.setFieldValue('batch', null);
                        formik.setFieldValue('branch', value);
                      }}
                      selectedBranch={formik.values.branch ?? ''}
                    />
                  </Grid>
                  <Grid item md={3} style={{ width: "100%" }}>
                    <Typography variant="body2" color="textSecondary">
                      Product
                    </Typography>
                  </Grid>
                  <Grid item md={9}>
                    <ProductSelect
                      placeholder="Select Product"
                      isBranchSelected={!!formik.values.branch}
                      selectedBranch={formik.values.branch ?? ''}
                      handleProductChange={(value) => {
                        formik.setFieldValue('product', value);
                      }}
                      selectedProduct={formik.values.product ?? []}
                      fetchAllProduct
                    />
                  </Grid>
                  <Grid item md={12} style={{ width: "100%" }}>
                    <Typography variant="body2" color="textSecondary">
                      SN Range
                    </Typography>
                  </Grid>
                  <Grid item md={12}>
                    <BatchSelect
                      branch={formik.values.branch ?? ''}
                      selectedBatch={formik.values.batch}
                      placeholder="Select SN Range"
                      handleSelect={(value) => {
                        formik.setFieldValue('from', value.fromSeqNum);
                        formik.setFieldValue('to', value.toSeqNum);
                        formik.setFieldValue('batch', value);
                        formik.validateForm();
                      }}
                    />
                  </Grid>
                  <Grid item md={6} style={{ width: "100%" }}>
                    <TextField
                      label="Reassign Start At"
                      InputLabelProps={{ shrink: true }}
                      type="number"
                      InputProps={{ inputProps: { min: formik.values.batch?.fromSeqNum, max: formik.values.batch?.toSeqNum } }}
                      style={{ width: "100%" }}
                      className={classes.root}
                      required
                      disabled={!formik.values.batch}
                      error={formik.touched.from && Boolean(formik.errors.from)}
                      helperText={formik.touched.from && formik.errors.from}
                      {...formik.getFieldProps('from')}
                    />
                  </Grid>
                  <Grid item md={6} style={{ width: "100%" }}>
                    <TextField
                      label="Reassign End At"
                      InputLabelProps={{ shrink: true }}
                      type="number"
                      InputProps={{ inputProps: { min: formik.values.batch?.fromSeqNum, max: formik.values.batch?.toSeqNum } }}
                      style={{ width: "100%" }}
                      className={classes.root}
                      required
                      disabled={!formik.values.batch}
                      error={formik.touched.to && Boolean(formik.errors.to)}
                      helperText={formik.touched.to && formik.errors.to}
                      {...formik.getFieldProps('to')}
                    />
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                variant="text"
                type="button"
                disabled={isUpdating}
                onClick={onSerialNumberAssignDialogCloseClick}
                style={{ color: "black" }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={isUpdating || !(formik.isValid)}
              >
                Update
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
      <Backdrop open={isBackdropOpen} className={classes.backdrop}>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <Box>
            <CircularProgress color="inherit" />
          </Box>
          <Box pt={1}>
            <Typography>The reassign is processing, it might take a while.</Typography>
          </Box>
        </Box>
      </Backdrop>
    </>
  );
}
