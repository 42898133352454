import { Container, FormControlLabel, Checkbox, Divider, Typography } from '@material-ui/core';
import { Popover } from '@mui/material';
import { useDispatch } from 'react-redux';
import Loading from 'src/components/Loading';
import { addAlert } from 'src/modules/notification';

export default function branchSelectPanelComponent({
  anchorEl,
  handleClose,
  isLoading,
  branchList,
  branchListFilter,
  setBranchListFilter,
}) {
  const dispatch = useDispatch();

  const branchesCheckbox = branchList.map((v, i) => (
    <FormControlLabel
      sx={{
        ml: 0,
        mt: 1,
        fontSize: '12px',
        width: '100%',
      }}
      key={i}
      checked={branchListFilter.some((b) => b === v.id)}
      label={
        <Typography
          sx={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          { v.name }
        </Typography>
      }
      title={v.name}
      control={<Checkbox size="small" />}
      onChange={(e) => {
        const { checked } = e.target;
        setBranchListFilter((blf) => {
          if (checked) {
            return [...blf, v.id];
          }
          if (blf.length === 1) {
            e.preventDefault();
            dispatch(
              addAlert({
                severity: 'warning',
                message: 'Cannot select zero branches'
              })
            );
            return blf;
          }
          return blf.filter((b) => b !== v.id);
        });
      }}
    />
  ));

  return (
    <Popover
      slotProps={{ paper: {
        style: {
          width: '250px',
          maxHeight: '300px',
          marginTop: '10px',
          padding: '8px',
        }
      } }}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {
        isLoading ?
          <Container>
            <Loading />
          </Container> :
          <>
          <FormControlLabel
            sx={{
              ml: 0,
              mb: 1,
              fontSize: '12px'
            }}
            checked={branchListFilter.length === branchList.length}
            label="All Branches"
            control={<Checkbox size="small" />}
            onChange={() => setBranchListFilter(branchList.map((b) => b.id))}
          />
          <Divider />
          { branchesCheckbox }
          </>
      }
    </Popover>
  );
}
