import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BatchSelectComponent from "./batchSelect.component";
import { selectLoading } from "../../../../notification";
import { getNodeSnRange } from "../../../redux";

export default function BatchSelectContainer({
  branch,
  selectedBatch,
  placeholder,
  handleSelect,
}) {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const batches = useSelector(
    (state) => state.companies.nodeSnRange.list
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, getNodeSnRange.typePrefix)
  );
  const isError = useSelector(
    (state) => state.companies.nodeSnRange.isError
  );

  useEffect(() => {
    if (branch) {
      dispatch(getNodeSnRange(branch.node_id));
    }
  }, [branch]);

  const handleOpen = (event) => {
    if (isFetching) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBatchSelect = (batch) => {
    handleSelect(batch);
    handleClose();
  };

  return (
    <>
      <BatchSelectComponent
        open={open}
        handleClick={handleOpen}
        isBranchSelected={!!branch}
        selectedBatch={selectedBatch}
        placeholder={placeholder}
        handleSelect={handleBatchSelect}
        isFetching={isFetching}
        isError={isError}
        batches={batches}
      />
    </>
  );
}
