import axios from "axios";

export default function getTransactionReports({ page, length }) {
  return new Promise((resolve, reject) =>
    axios
      .get(`/campaignV2/transactionReport/getReports?page=${page}&length=${length}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.message))
  );
}
