import axios from "axios";

export default function updateDisbursementInfo({
  id,
  balance,
  remark,
  debit,
  credit,
}) {
  const promise = new Promise((resolve, reject) =>
    axios
      .post(`/campaignV2/adminDisbursement/updateCompany`, {
        disbursement_company_id: id,
        balance,
        remark,
        debit,
        credit,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.message))
  );

  return promise;
}
