import React from 'react';
import {
  Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Skeleton
} from '@material-ui/core';
import Loading from 'src/components/Loading';

export default function CompanyGenerateTableComponent({
  data,
  isLoading,
  error
}) {
  const renderContent = () => {
    if (isLoading) return <div style={{ height: '300px', display: 'grid', placeItems: 'center' }}><Loading /></div>;
    if (error) return <div style={{ height: '300px', display: 'grid', placeItems: 'center' }}>Error: {error}</div>;
    if (!data.length) return <div style={{ height: '300px', display: 'grid', placeItems: 'center' }}>No data found</div>;
    return (
      <TableContainer component={Box} border={1} borderColor="grey.500" borderRadius={1} p={1} mt={2}>
        {isLoading ? (
          <>
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
          </>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Company</TableCell>
                <TableCell align="center">QR Generated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((record, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{record.name}</TableCell>
                  <TableCell align="center">{record.recordsTotal}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: '#ffffff',
        maxHeight: '500px',
        overflow: 'auto',
        p: 2,
        borderRadius: '12px',
      }}
    >
      <Typography variant="h6" style={{ fontSize: '24px', fontWeight: 'bolder' }}>Company Total Generated QR Count</Typography>
      {renderContent()}
    </Box>
  );
}
