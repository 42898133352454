import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Card,
  Typography,
  TableCell,
  Button,
  Grid,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Checkbox,
  TextareaAutosize,
  MenuItem,
  Menu,
  Divider,
} from '@material-ui/core';
import {
  Done as DoneIcon,
  Clear as ClearIcon,
  Pending as PendingIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import DataTable from '../../../../components/DataTable';
import EmailLogSearch from '../../components/email-log-search';
import EmailLogFilter from '../../components/email-log-filter';
import EmailLogSelectionBar from '../../components/email-log-selection-bar';

const EmailLogPage = ({
  isEmailLogLoading,
  emailLog,
  emailLogPage,
  totalEmailLog,
  isResending,
  isFilterOpen,
  isResendConfirmDialogOpen,
  selected,
  resendSelected,
  onEmailLogPageClick,
  onEmailResendClick,
  onSearchClick,
  toggleFilterOpen,
  handleClose,
  onConfirmClick,
  onCheckboxClick,
  onBulkResendClick,
  onDeselectedAllClick,
  enableItemPerPageOption,
  itemPerPage,
  handleItemPerPageChange,
  selectionOptionAnchorEl,
  handleOpenSelectionMenu,
  handleCloseSelectionMenu,
  handleSelectionOption,
}) => (
  <>
    <Helmet>
      <title>Email Logs | Kood Asia Management Portal</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Card
          sx={{
            mb: 2,
          }}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={2}>
              <Typography
                variant="h4"
                sx={{
                  padding: 2,
                }}
              >
                Email Logs
              </Typography>
            </Grid>
            <Grid item>
              <EmailLogSearch
                isFilterOpen={isFilterOpen}
                toggleFilterOpen={toggleFilterOpen}
              />
            </Grid>
          </Grid>
        </Card>
        <Card
          sx={{
            mb: 2,
          }}
        >
          {isFilterOpen && (
            <EmailLogFilter />
          )}
        </Card>
        {(selected.length > 0) && (
          <EmailLogSelectionBar
            selected={selected}
            onBulkResendClick={onBulkResendClick}
          />
        )}
        <DataTable
          isLoading={isEmailLogLoading}
          rows={emailLog}
          rowsPerPage={itemPerPage}
          total={totalEmailLog}
          page={emailLogPage}
          renderHeader={() => (
            <>
              <TableCell>
                <Checkbox
                  onClick={handleOpenSelectionMenu}
                  checked={selected.length === totalEmailLog}
                />
                <Menu
                  id="selection-option-menu"
                  anchorEl={selectionOptionAnchorEl}
                  open={Boolean(selectionOptionAnchorEl)}
                  onClose={handleCloseSelectionMenu}
                  MenuListProps={{
                    'aria-labelledby': 'selection-option-checkbox',
                  }}
                >
                  <MenuItem onClick={() => handleSelectionOption('all')}>Select all</MenuItem>
                  <MenuItem onClick={() => handleSelectionOption('all-page')}>Select all on this page</MenuItem>
                  <MenuItem onClick={() => handleSelectionOption('all-failed')}>Select all failed</MenuItem>
                  <MenuItem onClick={() => handleSelectionOption('all-page-failed')}>Select all failed on this page</MenuItem>
                  <Divider />
                  <MenuItem onClick={onDeselectedAllClick}>Deselected All</MenuItem>
                </Menu>
              </TableCell>
              <TableCell>
                ID
              </TableCell>
              <TableCell>
                Email Type
              </TableCell>
              <TableCell>
                Recipients
              </TableCell>
              <TableCell>
                Status
              </TableCell>
              <TableCell>
                Last Attempt
              </TableCell>
              <TableCell>
                Created At
              </TableCell>
              {!(selected.length) > 0 && (
                <TableCell>
                  Action
                </TableCell>
              )}
            </>
          )}
          renderRow={(row, index) => (
            <>
              <TableCell>
                <Checkbox
                  checked={selected.some((e) => e.id === row.id)}
                  onChange={() => onCheckboxClick(row)}
                />
              </TableCell>
              <TableCell>
                {row.id}
              </TableCell>
              <TableCell>
                {row.email_type}
              </TableCell>
              <TableCell>
                {row.recipient_email}
              </TableCell>
              <TableCell>
                {row.status === 'success' && <DoneIcon color="success" />}
                {row.status === 'failed' && (
                  <Tooltip
                    arrow
                    title={(
                      <>
                        <Typography>
                          {`Error: ${row.lastMessage}`}
                        </Typography>
                        <br />
                        <Typography>
                          {`Last Attempted: ${row.lastAttempted}`}
                        </Typography>
                      </>
                    )}
                  >
                    <ClearIcon color="warning" />
                  </Tooltip>
                )}
                {row.status === 'pending' && <PendingIcon color="disabled" />}
                {row.status === 'sending' && <PendingIcon color="disabled" />}
              </TableCell>
              <TableCell>
                {row.lastAttempted}
              </TableCell>
              <TableCell>
                {row.createdAt}
              </TableCell>
              {!(selected.length > 0) && (
                <TableCell>
                  <Button
                    disabled={isResending}
                    variant="contained"
                    onClick={() => onEmailResendClick(row, emailLogPage)}
                  >
                    Resend
                  </Button>
                </TableCell>
              )}
            </>
          )}
          onPageClick={onEmailLogPageClick}
          enableItemPerPageOption={enableItemPerPageOption}
          handleItemPerPageChange={handleItemPerPageChange}
        />
      </Container>
    </Box>
    <Dialog
      open={isResendConfirmDialogOpen}
      onClose={handleClose}
      disableEscapeKeyDown
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Confirm Resend Email
      </DialogTitle>
      {!isResending && (
        <>
          <DialogContent>
            <TextareaAutosize
              disabled="true"
              maxRows={10}
              cols={60}
              value={[...selected, resendSelected].map((item) => {
                if (item === null) {
                  return '';
                }

                return ` - Send ${item.email_type} email to ${item.recipient_email}`;
              }).join().replaceAll(',', '\n')}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>No</Button>
            <Button onClick={onConfirmClick} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </>
      )}
      {isResending && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Resending...
          </DialogContentText>
        </DialogContent>
      )}
    </Dialog>
  </>
);

EmailLogPage.propTypes = {
  isEmailLogLoading: PropTypes.bool.isRequired,
  emailLog: PropTypes.array.isRequired,
  emailLogPage: PropTypes.number.isRequired,
  totalEmailLog: PropTypes.number.isRequired,
  isResending: PropTypes.bool.isRequired,
  isFilterOpen: PropTypes.bool.isRequired,
  isResendConfirmDialogOpen: PropTypes.bool.isRequired,
  selected: PropTypes.array.isRequired,
  resendSelected: PropTypes.object,
  onEmailLogPageClick: PropTypes.func.isRequired,
  onEmailResendClick: PropTypes.func.isRequired,
  toggleFilterOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  onConfirmClick: PropTypes.func,
  onCheckboxClick: PropTypes.func.isRequired,
  onBulkResendClick: PropTypes.func.isRequired,
  onDeselectedAllClick: PropTypes.func.isRequired,
  enableItemPerPageOption: PropTypes.bool.isRequired,
  itemPerPage: PropTypes.number.isRequired,
  handleItemPerPageChange: PropTypes.func.isRequired,
};

export default EmailLogPage;
