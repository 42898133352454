import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import EmailLogSelectionBarComponent from './email-log-selection-bar.component';

export default function EmailLogSelectionBarContainer({
  selected,
  onBulkResendClick,
  onDeselectedAllClick,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <EmailLogSelectionBarComponent
      selectedNum={selected.length}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
      onBulkResendClick={onBulkResendClick}
      onDeselectedAllClick={onDeselectedAllClick}
    />
  );
}

EmailLogSelectionBarContainer.propTypes = {
  selected: PropTypes.array.isRequired,
  onBulkResendClick: PropTypes.func.isRequired,
  onDeselectedAllClick: PropTypes.func.isRequired,
};
