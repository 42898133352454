import { setLoading, addAlert } from "src/modules/notification/redux";
import {
  fetchLineChartScanCount,
  fetchListingCountryTable,
  fetchPieChartScanValidity,
  fetchMapScanCount,
  fetchListingCompanyTable,
  fetchCompanyGenerateTable,
  fetchCompanyAssignedTable,
  fetchGeneratedAssignedGraph,
} from '../action/dashboard.action';

export const filterDashboardMiddleware = ({ dispatch }) => (next) => (action) => {
  next(action);

  const { type, payload } = action;

  if (type === 'UPDATE_DATE_RANGE_FILTER') {
    const { startDate, endDate } = payload;

    dispatch(fetchLineChartScanCount({ startDate, endDate }));
    dispatch(fetchListingCountryTable({ startDate, endDate }));
    dispatch(fetchPieChartScanValidity({ startDate, endDate }));
    dispatch(fetchMapScanCount({ startDate, endDate }));
    dispatch(fetchListingCompanyTable({ startDate, endDate }));
  }
};

export const fetchMapScanCountMiddleware = () => (next) => (action) => {
  next(action);

  const { type } = action;

  switch (type) {
    case fetchMapScanCount.pending.type:
      break;
    case fetchMapScanCount.fulfilled.type:
      break;
    case fetchMapScanCount.rejected.type:
      break;
    default:
      break;
  }
};

export const fetchLineChartScanCountMiddleware = () => (next) => (action) => {
  next(action);

  const { type } = action;

  switch (type) {
    case fetchLineChartScanCount.pending.type:
      break;
    case fetchLineChartScanCount.fulfilled.type:
      break;
    case fetchLineChartScanCount.rejected.type:
      break;
    default:
      break;
  }
};

export const fetchListingCountryTableMiddleware = () => (next) => (action) => {
  next(action);

  const { type } = action;

  switch (type) {
    case fetchListingCountryTable.pending.type:
      break;
    case fetchListingCountryTable.fulfilled.type:
      break;
    case fetchListingCountryTable.rejected.type:
      break;
    default:
      break;
  }
};

export const fetchPieChartScanValidityMiddleware = () => (next) => (action) => {
  next(action);

  const { type } = action;

  switch (type) {
    case fetchPieChartScanValidity.pending.type:
      break;
    case fetchPieChartScanValidity.fulfilled.type:
      break;
    case fetchPieChartScanValidity.rejected.type:
      break;
    default:
      break;
  }
};

export const fetchListingCompanyTableMiddleware = () => (next) => (action) => {
  next(action);

  const { type } = action;

  switch (type) {
    case fetchListingCompanyTable.pending.type:
      break;
    case fetchListingCompanyTable.fulfilled.type:
      break;
    case fetchListingCompanyTable.rejected.type:
      break;
    default:
      break;
  }
};

export const fetchCompanyGenerateTableMiddleWare =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

  const { payload, type } = action;

  switch (type) {
    case fetchCompanyGenerateTable.pending.type:
      dispatch(setLoading({ id: fetchCompanyGenerateTable.typePrefix, state: true }));
      break;
    case fetchCompanyGenerateTable.fulfilled.type:
      dispatch(setLoading({ id: fetchCompanyGenerateTable.typePrefix, state: false }));
      break;
    case fetchCompanyGenerateTable.rejected.type:
      dispatch(setLoading({ id: fetchCompanyGenerateTable.typePrefix, state: false }));
      dispatch(
        addAlert({
          severity: "error",
          message: payload.code,
        })
      );
      break;
    default:
      break;
  }
};

export const fetchCompanyAssignedTableMiddleWare =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);

        const { payload, type } = action;

        switch (type) {
          case fetchCompanyAssignedTable.pending.type:
            dispatch(setLoading({ id: fetchCompanyAssignedTable.typePrefix, state: true }));
            break;
          case fetchCompanyAssignedTable.fulfilled.type:
            dispatch(setLoading({ id: fetchCompanyAssignedTable.typePrefix, state: false }));
            break;
          case fetchCompanyAssignedTable.rejected.type:
            dispatch(setLoading({ id: fetchCompanyAssignedTable.typePrefix, state: false }));
            dispatch(
              addAlert({
                severity: "error",
                message: payload.code,
              })
            );
            break;
          default:
            break;
        }
      };

export const fetchGeneratedAssignedGraphMiddleware = () => (next) => (action) => {
  next(action);

  const { type } = action;

  switch (type) {
    case fetchGeneratedAssignedGraph.pending.type:
      break;
    case fetchGeneratedAssignedGraph.fulfilled.type:
      break;
    case fetchGeneratedAssignedGraph.rejected.type:
      break;
    default:
      break;
  }
};

export default [
  filterDashboardMiddleware,
  fetchMapScanCountMiddleware,
  fetchLineChartScanCountMiddleware,
  fetchListingCountryTableMiddleware,
  fetchPieChartScanValidityMiddleware,
  fetchListingCompanyTableMiddleware,
  fetchCompanyGenerateTableMiddleWare,
  fetchCompanyAssignedTableMiddleWare,
  fetchGeneratedAssignedGraphMiddleware
];
