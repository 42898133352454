import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { styled } from '@material-ui/styles';
import MainNavbar from '../MainNavbar';
import Alerts from '../Alerts';

const MainLayoutRoot = styled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  }),
);

const MainLayoutWrapper = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 64,
});

const MainLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

const MainLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
});

const MainLayoutComponent = ({
  alerts,
}) => (
  <MainLayoutRoot>
    <MainNavbar />
    <MainLayoutWrapper>
      <MainLayoutContainer>
        <MainLayoutContent>
          <Outlet />
        </MainLayoutContent>
      </MainLayoutContainer>
    </MainLayoutWrapper>
    <Alerts
      alerts={alerts}
    />
  </MainLayoutRoot>
);

MainLayoutComponent.propTypes = {
  alerts: PropTypes.array.isRequired,
};

export default MainLayoutComponent;
