import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import AccessRightAccordionComponent from "./accessRightAccordion.component";

function AccessRightAccordionContainer({
  label,
  subgroup,
  selectedIds,
  formik,
  setAccess,
  accessRole,
  disabledCheckbox,
  disableSummaryAccordion,
  selectAccessRight,
  selectGroup,
  ...props
}) {
  return (
    <>
      <AccessRightAccordionComponent
        label={label}
        subgroup={subgroup}
        selectedIds={selectedIds}
        formik={formik}
        setAccess={setAccess}
        accessRole={accessRole}
        disabledCheckbox={disabledCheckbox}
        disableSummaryAccordion={disableSummaryAccordion}
        selectAccessRight={selectAccessRight}
        selectGroup={selectGroup}
        {...props}
      />
    </>
  );
}

AccessRightAccordionContainer.defaultProps = {
  label: "Label",
};

AccessRightAccordionContainer.propTypes = {
  label: PropTypes.string,
};

export default AccessRightAccordionContainer;
