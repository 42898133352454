import { useState } from "react";
import GeneralDialog from "src/components/GeneralDialog";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "src/modules/notification";
import { unwrapResult } from "@reduxjs/toolkit";
import UpdateCreditBalanceDialogComponent from "./update-credit-balance-dialog.component";
import { updateDisbursementInfo } from "../../../redux";

export default function UpdateCreditBalanceDialogContainer({
  selectedCompany,
  isOpen,
  handleClose,
  handleReload,
}) {
  const dispatch = useDispatch();
  const isSubmitting = useSelector((state) =>
    selectLoading(state, updateDisbursementInfo.typePrefix)
  );
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const [disbursementInfo, setDisbursementInfo] = useState({
    value: "",
    remark: "",
    action: "credit",
  });

  const handleSubmit = (values) => {
    setDisbursementInfo({
      value: values.amount,
      remark: values.remark,
      action: values.creditTransferAction,
    });
    setShowConfirmDialog(true);
  };

  const handleValidateBalance = (value, name, formik) => {
    if (value) {
      const regex = /^\d+$/;
      const isValid = regex.test(value);
      if (isValid) {
        formik.setFieldValue(name, value);
      }
    } else {
      formik.setFieldValue(name, value);
    }
  };

  const handleUpdateBalance = () => {
    setShowConfirmDialog(false);
    dispatch(
      updateDisbursementInfo({
        id: selectedCompany.id,
        balance:
          disbursementInfo.action === "credit"
            ? Number(selectedCompany.balance) - Number(disbursementInfo.value)
            : Number(selectedCompany.balance) + Number(disbursementInfo.value),
        credit:
          disbursementInfo.action === "credit"
            ? Number(disbursementInfo.value)
            : 0,
        debit:
          disbursementInfo.action === "debit"
            ? Number(disbursementInfo.value)
            : 0,
        remark: disbursementInfo.remark,
      })
    )
      .then(unwrapResult)
      .then(() => {
        handleReload();
        handleClose();
      });
  };

  return (
    <>
      <UpdateCreditBalanceDialogComponent
        selectedCompany={selectedCompany}
        isOpen={isOpen}
        handleClose={handleClose}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        handleValidateBalance={handleValidateBalance}
      />
      <GeneralDialog
        isOpen={showConfirmDialog}
        handleClose={() => {
          setShowConfirmDialog(false);
        }}
        handleProceed={handleUpdateBalance}
        title="Confirm Update Credit Balance"
        description="Are you sure want to update this company's credit balance?"
        type="danger"
      />
    </>
  );
}
