import { useState } from "react";
import CompanyAssignDialogComponent from "./company-assign-sn-dialog.component";

export default function CompanySerialNumberAssignDialogContainer({
  selectedCompany,
  onSerialNumberAssignDialogCloseClick,
}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const tabLabels = ['Assign', 'Unassign'];

  const handleTabChange = (event, newIndex) => {
    setActiveIndex(newIndex);
  };

  return (
    <CompanyAssignDialogComponent
      selectedCompany={selectedCompany}
      onSerialNumberAssignDialogCloseClick={onSerialNumberAssignDialogCloseClick}
      activeIndex={activeIndex}
      tabLabels={tabLabels}
      handleTabChange={handleTabChange}
    />
  );
}
