import {
  Box,
  Button,
  Input,
} from '@material-ui/core';
import { DateRangePicker } from 'rsuite';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Formik } from 'formik';
import SearchableSelect from 'src/components/SearchableSelect';

import 'rsuite/dist/rsuite.min.css';

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: 'grid',
    gridTemplateColumns: '5fr 1fr 1fr 1fr',
    columnGap: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  filterMainContainer: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingRight: '20px',
    paddingLeft: '20px',
  },
  filterFieldContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1),
  },
  inputRootProduct: {
    paddingTop: '8px !important',
    paddingBottom: '7px !important',
    paddingLeft: '12px !important',
    paddingRight: '12px !important',
  },
  paperRoot: {
    width: '217px',
  },
  typePaperRoot: {
    maxWidth: '370px',
    width: '370px',
  },
}));

export default function EmailLogFilterComponent({
  typeList,
  statusList,
  isFetching,
  filterState,
  handleFilter,
  resetFilter,
}) {
  const classes = useStyle();

  const typeSearchable = false;
  const statusSearchable = false;

  return (
    <Box className={classes.filterMainContainer}>
      <Formik
        initialValues={{
          status: filterState.status,
          type: filterState.type,
          dateRange: filterState.dateRange,
        }}
        enableReinitialize
        onSubmit={handleFilter}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <Box className={classes.filterFieldContainer} style={{ display: 'flex', marginBottom: '12px' }}>
                <Box style={{ width: '100%', backgroundColor: '#eceff0', borderRadius: '4px' }}>
                  <DateRangePicker
                    block
                    appearance="subtle"
                    placeholder="Date Range"
                    value={formik.values.dateRange}
                    onChange={(value) => {
                      formik.setFieldValue('dateRange', value);
                    }}
                  />
                </Box>
                <SearchableSelect
                  value={formik.values.type}
                  handleChange={(value) => {
                    formik.setFieldValue('type', value);
                  }}
                  placeholder="Email Type"
                  disabled={formik.isSubmitting}
                  searchable={typeSearchable}
                  style={{ height: '34px' }}
                  panelStyle={{ width: '249px' }}
                  PaperProps={{
                    classes: { root: classes.typePaperRoot },
                  }}
                  dropdownItem={typeList}
                  emptyMessage="Email Type not found"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  isEmpty={formik.values.type === ''}
                  returnType="object"
                />
                <SearchableSelect
                  value={formik.values.status}
                  handleChange={(value) => {
                    formik.setFieldValue('status', value);
                  }}
                  placeholder="Status"
                  disabled={formik.isSubmitting}
                  searchable={statusSearchable}
                  style={{ height: '34px' }}
                  panelStyle={{ width: '249px' }}
                  PaperProps={{
                    classes: { root: classes.paperRoot },
                  }}
                  dropdownItem={statusList}
                  emptyMessage="Status not found"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  isEmpty={formik.values.status === ''}
                />
              </Box>
              <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="text"
                  disableElevation
                  color="secondary"
                  size="small"
                  onClick={resetFilter}
                  style={{
                    marginRight: '8px',
                  }}
                >
                  Clear
                </Button>
                <Button
                  disabled={isFetching}
                  variant="contained"
                  disableElevation
                  color="secondary"
                  size="small"
                  type="submit"
                >
                  Apply Filter
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
}
