import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyDropdown,
  resetCompanyDropdown,
} from "src/modules/consumer-engagement/redux";
import { selectLoading } from "src/modules/notification";
import CompanySelectComponent from "./companySelect.component";
import CompanySelectPanelComponent from "./companySelectPanel.component";

function CompanySelectContainer({
  placeholder,
  handleChange,
  value,
  disabled,
  style,
  textStyles,
}) {
  const dispatch = useDispatch();
  const companies = useSelector((state) => state.disbursement.companies.list);
  const isFetching = useSelector((state) =>
    selectLoading(state, getCompanyDropdown.typePrefix)
  );
  const totalRecords = useSelector(
    (state) => state.disbursement.companies.totalRecords
  );
  const totalFiltered = useSelector(
    (state) => state.disbursement.companies.totalFiltered
  );
  const hasNextPage = useSelector(
    (state) => state.disbursement.companies.hasNextPage
  );
  const isError = useSelector((state) => state.disbursement.companies.isError);

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const [filter, setFilter] = useState({
    page: 0,
    rowsPerPage: 30,
    search: "",
  });

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const getData = () => {
    const param = {
      length: filter.rowsPerPage,
      start: filter.rowsPerPage * filter.page,
      search: filter.search,
    };

    dispatch(getCompanyDropdown(param));
  };

  useEffect(() => {
    if (hasNextPage && !isFetching) {
      getData();
    }
  }, [filter, dispatch]);

  const handleSearch = (search) => {
    dispatch(resetCompanyDropdown());
    setFilter({
      ...filter,
      page: 0,
      search,
    });
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(resetCompanyDropdown());
    setFilter({
      ...filter,
      page: 0,
      search: "",
    });
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleFetchMoreItems = () => {
    if (hasNextPage) {
      const newFilter = { ...filter, page: filter.page + 1 };
      setFilter(newFilter);
    }
  };

  const handleReload = () => {
    dispatch(resetCompanyDropdown());
    setFilter({
      ...filter,
      page: 0,
      search: "",
    });
  };

  const handleSelectCompany = (company) => {
    handleChange(company);
    handleClose();
  };

  return (
    <>
      <CompanySelectComponent
        anchorRef={anchorRef}
        handleClick={handleClick}
        value={value}
        style={style}
        placeholder={placeholder}
        disabled={disabled}
        textStyles={textStyles}
      />
      <CompanySelectPanelComponent
        value={value}
        anchorRef={anchorRef}
        open={open}
        handleClose={handleClose}
        isFetching={isFetching}
        companies={companies}
        handleSelectCompany={handleSelectCompany}
        handleSearch={handleSearch}
        handleFetchMoreItems={handleFetchMoreItems}
        hasNextPage={hasNextPage}
        isError={isError}
        handleReload={handleReload}
        totalRecords={totalFiltered}
      />
    </>
  );
}

export default CompanySelectContainer;
