import axios from 'axios';

export default function getCompanyAssigned({ payload }) {
  return new Promise((resolve, reject) => axios
    .get('/serialnumber/system-admin/api/v1/get-qr-assigned-count',
      {
        params: {
          start_date: payload.startDate,
          end_date: payload.endDate,
          limit: payload.isTop20Companies ? 20 : null,
          company_ids: payload.companyIds ? payload.companyIds.toString() : ''
        }
      })
    .then((response) => resolve(response.data))
    .catch((error) => reject(error.message)));
}
