import React from 'react';
import { useDispatch } from 'react-redux';
import SearchComponent from '../../../../components/Search/Search.component';
import { setPage, setSearchValue } from '../../redux/email-log.slice';

export default function EmailLogSearchContainer({
  isFilterOpen,
  toggleFilterOpen,
}) {
  const dispatch = useDispatch();

  const handleSearch = (values) => {
    dispatch(setPage(0));
    dispatch(setSearchValue(values.searchValue.trim()));
  };

  const handleClear = () => {
    dispatch(setPage(0));
    dispatch(setSearchValue(''));
  };

  const isFetching = false;
  const hasFilter = true;

  return (
    <SearchComponent
      searchPlaceholder="Enter Recipient Email"
      handleSearch={handleSearch}
      clearSearch={handleClear}
      isFetching={isFetching}
      hasFilter={hasFilter}
      isFilterOpen={isFilterOpen}
      toggleFilterOpen={toggleFilterOpen}
    />
  );
}
