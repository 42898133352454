import axios from "axios";

export default function createDisbursementInfo({
  companyId,
  providerId,
  currency,
  balance,
  remark,
}) {
  const promise = new Promise((resolve, reject) =>
    axios
      .post(`/campaignV2/adminDisbursement/addCompany`, {
        company_id: companyId,
        disbursement_provider_id: providerId,
        currency,
        balance,
        remark,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.message))
  );

  return promise;
}
