import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPartnersAndResellers, getPartnersAndResellers } from '../../redux/partners-and-resellers.action';
import { clearPartnersAndResellers } from '../../redux/partners-and-resellers.slice';
import PartnersAndResellersPage from './partners-and-resellers.page';

export default function PartnersAndResellersContainer() {
  const dispatch = useDispatch();
  const isPartnersAndResellersLoading = useSelector((state) => state.loading.inProcess.filter((p) => p.id === getPartnersAndResellers.typePrefix).length > 0);
  const partnersAndResellers = useSelector((state) => state.partnersAndResellers.partnersAndResellers);
  const partnersAndResellersPage = useSelector((state) => state.partnersAndResellers.partnersAndResellersPage);
  const totalPartnersAndResellers = useSelector((state) => state.partnersAndResellers.totalPartnersAndResellers);
  const [isNewPartnerAndResellerDialogOpen, setIsNewPartnerAndResellerDialogOpen] = useState(false);
  useEffect(() => {
    dispatch(getPartnersAndResellers({ page: 0 }));
    return () => {
      dispatch(clearPartnersAndResellers());
    };
  }, [dispatch]);
  const onPartnerAndResellerAddClick = () => {
    setIsNewPartnerAndResellerDialogOpen(true);
  };
  const onNewPartnerAndResellerDialogSubmitClick = (values, formik) => {
    dispatch(createPartnersAndResellers(values));
    formik.resetForm();
    setIsNewPartnerAndResellerDialogOpen(false);
    dispatch(getPartnersAndResellers({ page: partnersAndResellersPage }));
  };
  const onNewPartnerAndResellerDialogCloseClick = () => {
    setIsNewPartnerAndResellerDialogOpen(false);
  };
  const onPartnersAndResellersPageClick = (page) => {
    if (partnersAndResellersPage !== page) {
      dispatch(getPartnersAndResellers({ page }));
    }
  };
  const onRegistrationLinkCopyClick = (code) => {
    navigator.clipboard.writeText(code);
  };
  return (
    <PartnersAndResellersPage
      isPartnersAndResellersLoading={isPartnersAndResellersLoading}
      partnersAndResellers={partnersAndResellers}
      partnersAndResellersPage={partnersAndResellersPage}
      totalPartnersAndResellers={totalPartnersAndResellers}
      onRegistrationLinkCopyClick={onRegistrationLinkCopyClick}
      onPartnersAndResellersPageClick={onPartnersAndResellersPageClick}
      isNewPartnerAndResellerDialogOpen={isNewPartnerAndResellerDialogOpen}
      onPartnerAndResellerAddClick={onPartnerAndResellerAddClick}
      onNewPartnerAndResellerDialogSubmitClick={onNewPartnerAndResellerDialogSubmitClick}
      onNewPartnerAndResellerDialogCloseClick={onNewPartnerAndResellerDialogCloseClick}
    />
  );
}
