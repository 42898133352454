import React from 'react';
import {
  ButtonBase,
  Typography,
  Button,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Clear from '@material-ui/icons/Clear';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '7px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#ECEFF0',
    borderRadius: '4px',
    width: '100%',
    minWidth: '180px',
    margin: '10px 20px',
  },
  inputText: {
    color: '#a4a6a8',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 500,
  },
  selectedField: {
    display: 'flex',
    alignItems: 'center',
  },
  disabledField: {
    backgroundColor: '#eceff0',
  },
  filled: {
    minWidth: 'auto',
    fontWeight: 500,
    color: theme.palette.primary.main,
    display: 'inline-block',
    flexWrap: 'wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    alignSelf: 'center',
  },
  filledWithNum: {
    minWidth: 'auto',
    fontWeight: 500,
    color: theme.palette.primary.main,
    display: 'inline-block',
    flexWrap: 'wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '70%',
    alignSelf: 'center',
  },
  textNum: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    display: 'inline-block',
  },
  clearButton: {
    padding: 0,
    minWidth: 'auto',
    marginRight: theme.spacing(1),
  },
}));

export default function CompanySelectFieldComponent({
  handleClick,
  handleClear,
  value = [],
  placeholder,
  disabled,
  style,
  isEmpty,
  id,
  open,
  textStyle,
  isClearable,
}) {
  const classes = useStyles();

  const getDisplay = () => {
    let display = placeholder;
    if (value.length > 0) {
      display = value[0].name;
    } else {
      display = 'Top 20';
    }
    return display;
  };

  const displayValue = () => (
    <Box
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        flex: '2',
      }}
    >
      <Typography
        variant="body2"
        noWrap
        style={textStyle}
        className={clsx(
          classes.inputText,
          value.length > 1 && classes.filledWithNum,
          (getDisplay() !== placeholder) && classes.filled,
        )}
      >
        {getDisplay()}
      </Typography>
      {value.length > 1 && (
        <Typography className={classes.textNum}>
          {' '}
          +
          {value.length - 1}
        </Typography>
      )}
    </Box>
  );

  return (
    <ButtonBase
      aria-describedby={id}
      className={clsx(classes.root, {
        [classes.disabledField]: disabled,
      })}
      disableRipple
      onClick={handleClick}
      disabled={disabled}
      style={{ padding: 12, ...style }}
    >
      {displayValue()}
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {isClearable && !isEmpty && (
          <Button
            className={classes.clearButton}
            onClick={(e) => {
              e.stopPropagation();
              handleClear();
            }}
          >
            <Clear style={{ fontSize: 16 }} />
          </Button>
        )}
        {open ? (
          <ArrowDropUp fontSize="small" />
        ) : (
          <ArrowDropDown fontSize="small" />
        )}
      </Box>
    </ButtonBase>
  );
}
