import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  MenuItem,
  Button,
  Select,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

const NewPartnerAndResellerDialogComponent = ({
  isNewPartnerAndResellerDialogOpen,
  onNewPartnerAndResellerDialogSubmitClick,
  onNewPartnerAndResellerDialogCloseClick,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      email: '',
      password: '',
      type: 1,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Name is required'),
      email: Yup.string().required('Email is required').email('Invalid email'),
      password: Yup.string().required('Password is required'),
      type: Yup.string().oneOf(['1', '2', '3']).required('Type is required'),
    }),
    onSubmit: onNewPartnerAndResellerDialogSubmitClick,
  });

  return (
    <Dialog
      open={isNewPartnerAndResellerDialogOpen}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
      onClose={() => {
        onNewPartnerAndResellerDialogCloseClick();
        formik.resetForm();
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle
          id="scroll-dialog-title"
        >
          New
        </DialogTitle>
        <DialogContent
          dividers
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                variant="outlined"
                error={!!formik.errors.name}
                helperText={formik.errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                variant="outlined"
                error={!!formik.errors.email}
                helperText={formik.errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                variant="outlined"
                type="password"
                error={!!formik.errors.password}
                helperText={formik.errors.password}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Select
                fullWidth
                label="Type"
                name="type"
                variant="outlined"
                defaultValue={1}
                value={formik.values.type}
                onChange={formik.handleChange}
                error={!!formik.errors.type}
                helperText={formik.errors.type}
              >
                <MenuItem value={1}>Super Partner</MenuItem>
                <MenuItem value={2}>Partner</MenuItem>
                <MenuItem value={3}>Reseller</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button
            variant="contained"
            type="submit"
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

NewPartnerAndResellerDialogComponent.propTypes = {
  isNewPartnerAndResellerDialogOpen: PropTypes.bool.isRequired,
  onNewPartnerAndResellerDialogSubmitClick: PropTypes.func.isRequired,
  onNewPartnerAndResellerDialogCloseClick: PropTypes.func.isRequired,
};

export default NewPartnerAndResellerDialogComponent;
