import DataTableComponent from './DataTable.component';

export default function DataTableContainer({
  isLoading,
  rows,
  rowsPerPage,
  rowsPerPageOption,
  page,
  total,
  renderHeader,
  renderRow,
  onPageClick,
  enableItemPerPageOption,
  handleItemPerPageChange,
}) {
  return (
    <DataTableComponent
      isLoading={isLoading}
      rows={rows}
      rowsPerPage={rowsPerPage}
      rowsPerPageOption={rowsPerPageOption}
      page={page}
      total={total}
      renderHeader={renderHeader}
      renderRow={renderRow}
      onPageClick={onPageClick}
      enableItemPerPageOption={enableItemPerPageOption}
      handleItemPerPageChange={handleItemPerPageChange}
    />
  );
}
