/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getApprovals,
  getAvailablePlans,
  putApproval,
} from "../../redux/action";
import { setPage, setTypeFilter, setStatusFilter, clearCompanies } from "../../redux/slice";
import ApprovalsPage from "./approvals.page";

export default function ApprovalsContainer() {
  const dispatch = useDispatch();
  const industries = useSelector((state) => state.constants.industries);
  const isCompaniesLoading = useSelector(
    (state) =>
      state.loading.inProcess.filter((p) => p.id === getApprovals.typePrefix)
        .length > 0
  );
  const plans = useSelector((state) => state.approvals.plans);
  const companies = useSelector((state) => state.approvals.companies);
  const companiesPage = useSelector((state) => state.approvals.companiesPage);
  const totalCompanies = useSelector((state) => state.approvals.totalCompanies);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [itemPerPage, setItemPerPage] = useState(25);
  const filterType = useSelector((state) => state.approvals.filterType);
  const filterStatus = useSelector((state) => state.approvals.filterStatus);
  const [searchValue, setSearchValue] = useState("");

  const typeList = [
    { label: 'Company Name', value: 'company_name' },
    { label: 'Registration Number', value: 'reg_number' },
  ];

  const statusList = [
    { label: 'Pending', value: 'pending' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Suspended', value: 'suspended' },
  ];

  useEffect(() => {
    dispatch(getApprovals({ page: 0, length: itemPerPage }));
    dispatch(getAvailablePlans());
    return () => {
      dispatch(clearCompanies());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getApprovals({ page: 0, length: itemPerPage }));
  }, [itemPerPage]);

  const handleFilter = (values) => {
    const selectedType = typeList.find((type) => type.label === values.type);
    const selectedStatus = statusList.find((status) => status.label === values.status);
    setSearchValue(values.searchValue);
    dispatch(setPage(0));
    dispatch(setTypeFilter(selectedType ? selectedType.label : ''));
    dispatch(setStatusFilter(selectedStatus ? selectedStatus.label : ''));
    dispatch(getApprovals({
      page: 0,
      length: itemPerPage,
      search: values.searchValue,
      searchColumn: selectedType ? selectedType.value : '',
      statusFilter: selectedStatus ? selectedStatus.value : '',
    }));
  };

  const resetFilter = () => {
    setSearchValue("");
    dispatch(setPage(0));
    dispatch(setTypeFilter(''));
    dispatch(setStatusFilter(''));
    dispatch(getApprovals({ page: 0, length: itemPerPage }));
  };

  const onCompanyClick = (company) => {
    setSelectedCompany(company);
  };

  const onCompanyApprovalDialogSubmitClick = (values, actions) => {
    actions.setSubmitting(true);
    const approval = {
      id: selectedCompany.company.id,
      status: values.approval,
    };

    if (
      values.approval === "approve" &&
      (selectedCompany.registrationType === 2 ||
        selectedCompany.registrationType === 3)
    ) {
      approval.planUuid = values.plan.uuid;
      approval.selectedAccessRights = values.selectedIds;
    }

    if (values.approval === "reject") {
      approval.remarks = values.remarks;
      approval.rejectModules = values.rejectModules;
    }

    if (values.approval === "suspend") {
      approval.remarks = values.remarks;
    }
    dispatch(putApproval(approval)).then(() => {
      actions.setSubmitting(false);
      setSelectedCompany(null);
      dispatch(getApprovals({ page: companiesPage, length: itemPerPage }));
    });
  };

  const onCompanyApprovalDialogCloseClick = () => {
    setSelectedCompany(null);
  };

  const onCompaniesPageClick = (page) => {
    if (companiesPage !== page) {
      dispatch(getApprovals({ page, length: itemPerPage }));
    }
  };

  const handleItemPerPageChange = (value) => {
    setItemPerPage(value);
  };

  const isFetching = false;

  return (
    <ApprovalsPage
      industries={industries}
      isCompaniesLoading={isCompaniesLoading}
      companies={companies}
      totalCompanies={totalCompanies}
      companiesPage={companiesPage}
      selectedCompany={selectedCompany}
      onCompanyClick={onCompanyClick}
      onCompanyApprovalDialogSubmitClick={onCompanyApprovalDialogSubmitClick}
      onCompanyApprovalDialogCloseClick={onCompanyApprovalDialogCloseClick}
      onCompaniesPageClick={onCompaniesPageClick}
      handleItemPerPageChange={handleItemPerPageChange}
      itemPerPage={itemPerPage}
      plans={plans}
      isFetching={isFetching}
      typeList={typeList}
      statusList={statusList}
      filterState={{ type: filterType || "Company Name", status: filterStatus, searchValue }}
      handleFilter={handleFilter}
      resetFilter={resetFilter}
    />
  );
}
