import { useState } from "react";
import TransactionReportsPage from "./transactionReports.page";
import NotificationConfigurationDialog from "../../components/dialog/notification-configuration-dialog";

export default function TransactionReportsContainer() {
  const providers = [
    { value: "tng", label: "Touch 'n Go" },
  ];

  const [provider, setProvider] = useState(providers[0].value);
  const [isNotificationDialogOpen, setNotificationDialogOpen] = useState(false);

  const handleProviderChange = (event) => {
    setProvider(event.target.value);
  };

  const onConfigureNotificationButtonClick = () => {
    setNotificationDialogOpen(true);
  };

  const handleClose = () => {
    setNotificationDialogOpen(false);
  };

  return (
    <>
      <TransactionReportsPage
        provider={provider}
        providers={providers}
        handleProviderChange={handleProviderChange}
        onConfigureNotificationButtonClick={onConfigureNotificationButtonClick}
      />
      {isNotificationDialogOpen && (
        <NotificationConfigurationDialog
          handleClose={handleClose}
        />
      )}
    </>
  );
}
