import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Box,
  TableCell,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/CloseRounded";
import DataTable from "../../../../../components/DataTable";

const CompanyManageBranchDialogComponent = ({
  selectedCompany,
  onCompanyManageBranchDialogCloseClick,
  isCompanyManageBranchLoading,
  companyBranch,
  totalCompanyManageBranch,
  companyManageBranchPage,
  rowsPerPage,
  rowsPerPageOption,
  handleItemPerPageChange,
  onCompanyManageBranchPageClick,
  onBranchWhiteLabelClick,
}) => {
  if (!selectedCompany) {
    return null;
  }
  return (
    <Dialog
      open={!!selectedCompany}
      onClose={() => {
        onCompanyManageBranchDialogCloseClick();
        handleItemPerPageChange(10);
      }}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="scroll-dialog-title">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body2">
            <b>
              {selectedCompany.company
                ? selectedCompany.company.name
                : ""}
              &nbsp;-&nbsp;Manage Branch
            </b>
          </Typography>
            <IconButton
              size="small"
              color="inherit"
              onClick={() => {
                onCompanyManageBranchDialogCloseClick();
                handleItemPerPageChange(10);
              }}
              style={{ padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers style={{ backgroundColor: "#f3f3f3" }}>
        <DataTable
          isLoading={isCompanyManageBranchLoading}
          rows={companyBranch}
          rowsPerPage={rowsPerPage}
          rowsPerPageOption={rowsPerPageOption}
          total={totalCompanyManageBranch}
          page={companyManageBranchPage}
          renderHeader={() => (
            <>
              <TableCell>Branch Name</TableCell>
              <TableCell>Parent Branch</TableCell>
              <TableCell>White Label</TableCell>
              <TableCell>Action</TableCell>
            </>
          )}
          renderRow={(row) => (
            <>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.parent}</TableCell>
              <TableCell>{row.white_label}</TableCell>
              <TableCell>
                &nbsp;
                <Button
                  variant="contained"
                  onClick={() => onBranchWhiteLabelClick(row)}
                  style={{ margin: 3, fontSize: 12, padding: "6px 11px" }}
                >
                  White Label
                </Button>
                &nbsp;
              </TableCell>
            </>
          )}
          onPageClick={onCompanyManageBranchPageClick}
          handleItemPerPageChange={handleItemPerPageChange}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CompanyManageBranchDialogComponent;
