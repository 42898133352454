import React, { useRef } from "react";
import {
  Typography,
  Paper,
  ClickAwayListener,
  Box,
  Button,
  Divider,
  MenuItem,
  Popover,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingComponent from "../../../../../components/Loading";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 425,
  },
  button: {
    padding: 0,
    minWidth: 0,
  },
  buttonAddBranch: {
    display: "flex",
    padding: 0,
    minWidth: 0,
  },
  list: {
    maxHeight: "25vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "16px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E4E7EC",
      borderRadius: "16px",
    },
  },
  selected: {
    backgroundColor: "#f1f1f1",
  },
}));

export default function BranchSelectPanelComponent({
  anchorEl,
  open,
  handleClose,
  isFetching,
  isError,
  branchDropdown,
  totalFiltered,
  handleFetchMoreItems,
  handleSelectField,
  handleReload,
}) {
  const classes = useStyle();

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      elevation={2}
      PaperProps={{
        style: { maxWidth: "63vw", width: 400 },
      }}
    >
      <Paper className={classes.paper}>
        <ClickAwayListener onClickAway={handleClose}>
          <Box>
            <Typography
              variant="body2"
              color="primary"
              style={{ padding: "8px 16px", fontWeight: "bold" }}
            >
              Branch
            </Typography>
            <Divider />
            <Box>
              {isError ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                  pt={2}
                  pb={3}
                >
                  <Typography
                    variant="body2"
                    color="primary"
                    style={{ fontWeight: "bold", paddingBottom: "8px" }}
                  >
                    NO DATA RECEIVED
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ color: "#9CA6A9", paddingBottom: "16px" }}
                  >
                    SOMETHING WENT WRONG
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleReload}
                    disableElevation
                  >
                    REFRESH
                  </Button>
                </Box>
              ) : (
                <InfiniteScroll
                  height="100%"
                  className={classes.list}
                  dataLength={branchDropdown.length}
                  hasMore={totalFiltered > branchDropdown.length}
                  next={handleFetchMoreItems}
                  loader={<LoadingComponent height={100} />}
                  endMessage={
                    isFetching ? (
                      <LoadingComponent height={100} />
                    ) : null
                  }
                >
                  {branchDropdown.map((field, index) => (
                    <React.Fragment key={index}>
                      <MenuItem
                        style={{ padding: 0 }}
                        onClick={() => handleSelectField(field)}
                      >
                        <Box display="flex" alignItems="center" p={1}>
                          {!branchDropdown.length ? (
                            <Box p={2} style={{ textAlign: "center" }}>
                              <Typography variant="body1">
                                No Record Exist
                              </Typography>
                            </Box>
                          ) : (
                            <Typography variant="inherit">
                              {field.name}
                            </Typography>
                          )}
                        </Box>
                      </MenuItem>
                    </React.Fragment>
                  ))}
                </InfiniteScroll>
              )}
            </Box>
          </Box>
        </ClickAwayListener>
      </Paper>
    </Popover>
  );
}
