import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencies } from "src/modules/consumer-engagement/redux";
import { selectLoading } from "src/modules/notification";
import CurrencySelectComponent from "./currencySelect.component";

function CurrencySelectContainer({ textFieldProps, formProps, type }) {
  const dispatch = useDispatch();
  const currencies = useSelector((state) => state.disbursement.currencies);
  const isLoading = useSelector((state) =>
    selectLoading(state, getCurrencies.typePrefix)
  );

  useEffect(() => {
    dispatch(getCurrencies());
  }, []);

  return (
    <CurrencySelectComponent
      textFieldProps={textFieldProps}
      formProps={formProps}
      currencyDropdownData={currencies}
      type={type}
      isLoading={isLoading}
    />
  );
}

export default CurrencySelectContainer;
