import axios from 'axios';

export default function getTableDailyGeneratedQRCount({ payload }) {
    return new Promise((resolve, reject) => axios
        .get(`/analytic/admin/api/v1/managementportal/company/${payload.companyUuid}/branches/table-daily-generated-qr-count`, {
            params: {
                branches: payload.branches.join(','),
                start: payload.start,
                end: payload.end,
            },
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.message))
    );
}
