import AlertComponent from './alerts.component';

export default function AlertContainer({
  alerts,
}) {
  return (
    <AlertComponent
      alerts={alerts}
    />
  );
}
