import React from 'react';
import { DateRangePicker } from 'rsuite';
import moment from 'moment';
import './dateRangePicker.css'; // Import the CSS file
import 'rsuite/dist/rsuite.min.css';

const DateRangePickerComponent = ({ handleDateRangeChange }) => {
  const defaultStartDate = moment().subtract(13, 'days').toDate();
  const defaultEndDate = moment().toDate();

  return (
    <>
      <DateRangePicker
        defaultValue={[defaultStartDate, defaultEndDate]}
        character=" - "
        cleanable={false}
        format="dd MMM yyyy"
        placement="auto"
        editable={false}
        onChange={(value) => {
          handleDateRangeChange(value[0], value[1]);
        }}
      />
    </>
  );
};

export default DateRangePickerComponent;
