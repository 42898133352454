import CompanyChangeStatusDialogComponent from "./companyChangeStatusDialog.component";

export default function CompanyChangeStatusDialogContainer({
  isOpen,
  action,
  handleConfirm,
  handleClose,
}) {
  return (
    <CompanyChangeStatusDialogComponent
      isOpen={isOpen}
      action={action}
      handleConfirm={handleConfirm}
      handleClose={handleClose}
    />
  );
}
