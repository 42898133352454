import { useEffect, useState } from "react";
import GeneralDialog from "src/components/GeneralDialog";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "src/modules/notification";
import { unwrapResult } from "@reduxjs/toolkit";
import CreateDisbursementInfoDialogComponent from "./create-disbursement-info.component";
import {
  createDisbursementInfo,
  resetCompanyDropdown,
  resetProvider,
} from "../../../redux";

export default function CreateDisbursementInfoDialogContainer({
  isOpen,
  handleClose,
  handleReload,
}) {
  const dispatch = useDispatch();
  const isSubmitting = useSelector((state) =>
    selectLoading(state, createDisbursementInfo.typePrefix)
  );
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const [tempVal, setTempVal] = useState({
    company: null,
    provider: null,
    currency: null,
    balance: "",
    remark: "",
  });

  useEffect(
    () => () => {
      dispatch(resetProvider());
      dispatch(resetCompanyDropdown());
    },
    []
  );

  const handleSubmit = (values) => {
    setTempVal({
      company: values.company,
      provider: values.provider,
      currency: values.currency,
      balance: values.balance,
      remark: values.remark,
    });
    setShowConfirmDialog(true);
  };

  const handleValidateBalance = (value, name, formik) => {
    if (value) {
      const regex = /^\d+$/;
      const isValid = regex.test(value);
      if (isValid) {
        formik.setFieldValue(name, value);
      }
    } else {
      formik.setFieldValue(name, value);
    }
  };

  const handleCreateDisbursement = () => {
    setShowConfirmDialog(false);
    dispatch(
      createDisbursementInfo({
        companyId: tempVal.company ? tempVal.company.id : null,
        providerId: tempVal.provider ? tempVal.provider.id : null,
        currency: tempVal.currency ? tempVal.currency.alpha3 : null,
        balance: tempVal.balance,
        remark: tempVal.remark,
      })
    )
      .then(unwrapResult)
      .then(() => {
        handleReload();
        handleClose();
      });
  };

  return (
    <>
      <CreateDisbursementInfoDialogComponent
        isOpen={isOpen}
        handleClose={handleClose}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        handleValidateBalance={handleValidateBalance}
      />
      <GeneralDialog
        isOpen={showConfirmDialog}
        handleClose={() => {
          setShowConfirmDialog(false);
        }}
        handleProceed={handleCreateDisbursement}
        title="Confirm Create Company Disbursement Info"
        description="Are you sure want to create this info?"
        type="danger"
      />
    </>
  );
}
