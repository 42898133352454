import React from 'react';
import {
  Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
} from '@material-ui/core';
import Loading from 'src/components/Loading';

const CompanyScansTableComponent = ({
  data,
  isLoading,
  error
}) => {
  const tableData = data ? data.map((item, index) => ({
    no: index + 1,
    company: item.company,
    scanCount: item.totalCount,
    suspendedCount: item.totalSuspended,
  })) : [];
  tableData?.sort((a, b) => b.scanCount - a.scanCount);

  const renderContent = () => {
    if (isLoading) return <div style={{ height: '300px', display: 'grid', placeItems: 'center' }}><Loading /></div>;
    if (error) return <div style={{ height: '300px', display: 'grid', placeItems: 'center' }}>Error: {error}</div>;
    if (!data.length) return <div style={{ height: '300px', display: 'grid', placeItems: 'center' }}>No data found</div>;
    return (
      <TableContainer component={Box} border={1} borderColor="grey.500" borderRadius={1} p={1} mt={2}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Company</TableCell>
              <TableCell align="center">Scan Count</TableCell>
              <TableCell align="center">Suspended Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((item, i) => (
              <TableRow key={item.no}>
                <TableCell>{i + 1}</TableCell>
                <TableCell>{item.company}</TableCell>
                <TableCell align="center">{item.scanCount}</TableCell>
                <TableCell align="center">{item.suspendedCount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: '#ffffff',
        maxHeight: '500px',
        overflow: 'auto',
        p: 2,
        borderRadius: '12px',
      }}
    >
      <Typography variant="h6" style={{ fontSize: '24px', fontWeight: 'bolder' }}>Company Total Scan Count vs Suspended QR</Typography>
      {renderContent()}
    </Box>
  );
};

export default CompanyScansTableComponent;
