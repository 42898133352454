import axios from 'axios';

const url = '/analytic/admin/api/v1/managementportal/get-Top20-Companies';

const getTop20Companies = async ({ startDate, endDate }) => {
  try {
    const response = await axios.get(url, {
      params: {
        start_Date: startDate,
        end_Date: endDate,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw error.response.data;
    } else {
      throw error;
    }
  }
};

export default getTop20Companies;
