import { createSlice } from "@reduxjs/toolkit";
import {
  getCompanyDropdown,
  getCurrencies,
  getDisbursementList,
  getProviderList,
} from "../action";

const DISBURSEMENT = "disbursement";

const initialState = {
  disbursements: {
    list: [],
    totalRecords: 0,
    totalFiltered: 0,
    isError: false,
  },
  providers: [],
  currencies: [],
  companies: {
    list: [],
    totalRecords: 0,
    totalFiltered: 0,
    isError: false,
    hasNextPage: true,
  },
};
const disbursementSlice = createSlice({
  name: DISBURSEMENT,
  initialState,
  reducers: {
    resetDisbursementList: (state) => {
      state.disbursements = initialState.disbursements;
    },
    resetProvider: (state) => {
      state.providers = initialState.providers;
    },
    resetCompanyDropdown: (state) => {
      state.companies = initialState.companies;
    },
  },
  extraReducers: {
    [getDisbursementList.pending]: (state) => {
      state.disbursements = initialState.disbursements;
    },
    [getDisbursementList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.disbursements.list = payload.data;
      state.disbursements.totalRecords = payload.recordsTotal;
      state.disbursements.totalFiltered = payload.recordsFiltered;
    },
    [getDisbursementList.rejected]: (state) => {
      state.disbursements.isError = true;
    },
    [getCurrencies.pending]: (state) => {
      state.currencies = initialState.currencies;
    },
    [getCurrencies.fulfilled]: (state, action) => {
      const { payload } = action;
      state.currencies = payload.data;
    },
    [getProviderList.pending]: (state) => {
      state.providers = initialState.providers;
    },
    [getProviderList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.providers = payload;
    },
    [getCompanyDropdown.pending]: (state) => {
      state.companies.isError = false;
    },
    [getCompanyDropdown.fulfilled]: (state, action) => {
      const { payload } = action;
      if (state.companies.hasNextPage) {
        state.companies.list = [...state.companies.list, ...payload.data];
      }
      state.companies.totalRecords = payload.recordsTotal;
      state.companies.totalFiltered = payload.recordsFiltered;
      state.companies.hasNextPage =
        state.companies.list.length < state.companies.totalFiltered;
    },
    [getCompanyDropdown.rejected]: (state) => {
      state.companies.isError = true;
    },
  },
});

export const { resetDisbursementList, resetProvider, resetCompanyDropdown } =
  disbursementSlice.actions;
export const disbursementReducer = disbursementSlice.reducer;
