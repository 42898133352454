import React from 'react';
import { DateRangePicker } from 'rsuite';
import './dateRangePicker.css'; // Import the CSS file
import 'rsuite/dist/rsuite.min.css';

const DateRangePickerComponent = ({ defaultValue, handleDateRangeChange }) => {
  const { start, end } = defaultValue;

  return (
    <DateRangePicker
      defaultValue={[start, end]}
      character=" - "
      cleanable={false}
      format="dd MMM yyyy"
      placement="auto"
      editable={false}
      onChange={(value) => {
        handleDateRangeChange(value[0], value[1]);
      }}
    />
  );
};

export default DateRangePickerComponent;
